import React from "react";
import FlexContainer from "../../../../connectui/component/layout/FlexContainer";
import FieldComponentProvider from "../../field/FieldComponentProvider";

export default function FilterItemComponent({
  field,
  selectedFilters,
  setFilters,
  customFields,
  sx = {},
}) {
  return (
    <FlexContainer gap={"5px"} sx={{flexFlow: "wrap", ...sx}}>
      <FieldComponentProvider
        customFieldData={customFields}
        fieldInfo={{field: field}}
      >
        {({optionData, operatorType, componentMap, component}) =>
          component({
            type: "input",
            handleValueChange: (type, newValue) =>
              setFilters([
                ...selectedFilters.filter((f) => f.path !== field),
                ...newValue.map((value) => ({path: field, value})),
              ]),
            fieldData: {
              field,
              type: operatorType,
              val: selectedFilters
                .filter((f) => f.path === field)
                .map((f) => f.value),
            },
            customFieldData: customFields,
            optionData: optionData,
            componentMap: componentMap,
            operatorType: operatorType,
          })
        }
      </FieldComponentProvider>
    </FlexContainer>
  );
}
