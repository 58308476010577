import React from "react";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import ListItemContainer from "../../../../connectui/component/list/ListItemContainer";
import Container from "../../../../connectui/component/layout/Container";
import Text from "../../../../connectui/component/display/Text";
import ChipText from "../../../../connectui/component/display/chip/ChipText";
import FlexContainer from "connectui/component/layout/FlexContainer";

const useStyles = (theme) =>
  buildStyles({
    itemRow: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
      width: "100%",
    },
    hoverActionsContainer: {
      width: 0,
      overflow: "hidden",
      opacity: 0,
      transition: "opacity 0.15s",
    },
  });

const FiltersSetItem = ({
  icon = null,
  label,
  dropdownContent = null,
  totalCount = null,
  hoverActions = null,
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <ListItemContainer
      defaultCollapsed
      nestedList={dropdownContent}
      indentSpacing={theme.spacing(8)}
      {...props}
    >
      <Container className={classes.itemRow}>
        {icon}
        <Text variant="textSM" weight="medium" color="gray700" sx={{flex: 1}}>
          {label}
        </Text>
        {totalCount !== null && (
          <ChipText
            text={totalCount}
            sx={{
              "&:hover": {backgroundColor: theme.colors.primary100},
            }}
          />
        )}
        {hoverActions && (
          <FlexContainer
            id="hover-actions"
            className={classes.hoverActionsContainer}
            alignItems="center"
            justifyContent="flex-end"
            gap={theme.spacing(2)}
          >
            {hoverActions}
          </FlexContainer>
        )}
      </Container>
    </ListItemContainer>
  );
};

export default FiltersSetItem;
