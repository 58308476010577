import React from "react";
import {useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ListingConnection from "../../MultiOption/ListingConnection";
import ContentDetails from "./ExperienceContent/ContentDetails";
import ExperienceStep from "./ExperienceContent/ExperienceStep";
import PrimaryButton from "core/buttons/PrimaryButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  listingConnection: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  grow: {flexGrow: 1},
}));

export default function ExperienceBuilder({
  type = "default",
  experience,
  setData,
  hideConnection,
  hideTriggers,
  disableEdit,
  hideContent = false,
  groupId,
  setSaveDisabled,
  listing,
  connectedIds,
}) {
  const classes = useStyles();
  const isNew = !experience;
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const [showHouseList, setShowHouseList] = React.useState(false);
  const [defaultExperienceGroup, setDefaultExperienceGroup] =
    React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [info, setInfo] = React.useState({
    name: "",
    type: "msg",
    enabled: false,
    listings: [],
    connection_type: "all",
    groupId: null,
  });
  const [automationSteps, setAutomationSteps] = React.useState([
    {
      step: 0,
      trigger: null,
      actions: null,
    },
  ]);

  React.useEffect(() => {
    if (!isEditing) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [isEditing]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const newData = {
        name: info.name,
        enabled: info.enabled,
        description: experience?.description || "",
        actions: automationSteps.map((a) => {
          const newStep = {...a};
          delete newStep.step;
          return newStep;
        }),
        experience_type: info.experience_type || "msg",
        experience_id: experience?.experience_id,
      };
      if (!hideConnection) {
        if (info.connection_type === "all") {
          newData.connected_to = "ALL";
        } else if (info.connection_type === "group") {
          newData.connected_to = info.groupId;
        } else if (info.connection_type === "ids") {
          newData.connected_to = {
            object: "listing",
            ids: info.listings,
          };
        }
      }
      setData(newData);
    }, 150);

    return () => clearTimeout(timer);
  }, [automationSteps, info]);

  React.useEffect(() => {
    if (!experience) {
      setInfo((prev) => ({
        name: "",
        type: "msg",
        enabled: false,
        listings: [],
        connection_type: "all",
        groupId: null,
      }));
      setAutomationSteps((prev) => [
        {
          step: 0,
          trigger: null,
          actions: null,
        },
      ]);
      return;
    }
    let listingObj = "all";
    if (!!experience.connected_to && experience.connected_to !== "ALL") {
      if (typeof experience.connected_to === "object") {
        listingObj = "ids";
      } else {
        listingObj = "group";
      }
    }

    if (experience.connected_to?.ids?.length === 1) {
      const listing = houses[experience.connected_to.ids[0]];
      if (!!listing?.group_id) {
        setDefaultExperienceGroup((prev) => listing.group_id);
      }
    }

    const newAutomationSteps =
      experience.actions?.map((autStep, ind) => ({...autStep, step: ind})) ||
      [];
    setInfo((prev) => ({
      name: experience.name || "",
      experience_type: experience.experience_type,
      enabled: !!experience.enabled,
      connection_type: listingObj,
      listings: experience.connected_to?.ids ?? [],
      groupId: listingObj === "group" ? experience.connected_to : null,
    }));
    setAutomationSteps((prev) => newAutomationSteps);
  }, [experience]);

  const handleDataChange = (newData) => {
    if (
      newData.connection_type === "group" &&
      !newData.groupId &&
      !!defaultExperienceGroup
    ) {
      newData = {...newData, groupId: defaultExperienceGroup};
    }
    setInfo((prev) => ({...prev, ...newData}));
  };

  const addStep = () => {
    setAutomationSteps((prev) => [
      ...prev,
      {actions: null, trigger: "cin", step: prev.length},
    ]);
  };

  const removeStep = (index) => () => {
    setAutomationSteps((prev) => prev.filter((item, ind) => ind !== index));
  };

  const handleStepDataChange = (step, field, value) => {
    const newSteps = [...automationSteps];
    if (field === "trigger") {
      newSteps[step] = {
        ...newSteps[step],
        ...value,
      };
    } else {
      newSteps[step] = {
        ...newSteps[step],
        [field]: value,
      };
    }
    setAutomationSteps((prev) => newSteps);
  };

  return (
    <div className={classes.root}>
      {!hideContent && (
        <ContentDetails
          experience={experience}
          setData={handleDataChange}
          disableEdit={disableEdit}
          groupId={groupId}
          isNew={isNew}
        />
      )}
      {!hideConnection && (
        <div
          className={clsx(classes.listingConnection, {
            [classes.grow]: !!showHouseList,
          })}
        >
          <ListingConnection
            data={info}
            disableGroupSelector
            disabledButtons={!!experience?.experience_id}
            showHouseList={showHouseList}
            setShowHouseList={setShowHouseList}
            setData={handleDataChange}
          />
        </div>
      )}
      {!showHouseList && (
        <>
          {automationSteps.map((autStep, index) => (
            <ExperienceStep
              expType={type}
              experience={experience}
              data={autStep}
              key={autStep.step}
              viewOnly={disableEdit}
              hideTriggers={hideTriggers}
              setIsEditing={setIsEditing}
              setAction={(newActions) =>
                handleStepDataChange(autStep.step, "actions", newActions)
              }
              setCondition={(newCondition) =>
                handleStepDataChange(autStep.step, "conditions", newCondition)
              }
              setTrigger={(newTrigger) =>
                handleStepDataChange(autStep.step, "trigger", newTrigger)
              }
              removeStep={automationSteps.length > 1 && removeStep(index)}
              listing={listing}
              connectedIds={connectedIds}
            />
          ))}
          {!disableEdit && (
            <PrimaryButton
              label="+ Add another workflow"
              size="small"
              color="secondary"
              variant="text"
              onClick={addStep}
            />
          )}
        </>
      )}
    </div>
  );
}
