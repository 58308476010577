import React, {useEffect, useRef} from "react";
import {Box, makeStyles} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import CloseIconButton from "core/buttons/CloseIconButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
  },
  tagContainer: {
    border: (props) =>
      `1px solid ${!!props.error ? theme.palette.error.main : theme.palette.primary.main}`,
    color: (props) =>
      !!props.error ? theme.palette.error.main : theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    height: 24,
    borderRadius: 24,
    width: "fit-content",
  },
  input: {
    width: "100% !important",
    fontWeight: theme.typography.body1.fontWeight + "!important",
    fontSize: theme.typography.body1.fontSize + "px !important",
    fontFamily: theme.typography.body1.fontFamily + "!important",
    lineHeight: theme.typography.body1.lineHeight + "!important",
    color: theme.palette.text.primary + "!important",
    backgroundColor: "transparent !important",
    padding: "0px 0px 0px 58px !important",
    height: "100%",
    border: "none !important",
    boxShadow: "none !important",
    "&::placeholder": {opacity: (props) => (!!props.edit ? 0.5 : 1)},
    "&.-neutral": {
      cursor: (props) =>
        !!props.disableEdit ? "default !important" : "pointer !important",
      padding: "0px 0px 0px 53px !important",
    },
    "&.-tag": {
      paddingLeft: "50px !important",
      width: 190 + "px !important",
      color: `${theme.palette.primary.main} !important`,
      "&.-neutral": {
        paddingLeft: `${theme.spacing(2)}px !important`,
        width: "115px !important",
        fontSize: "12px !important",
      },
      "&.-error": {color: `${theme.palette.error.main} !important`},
    },
  },
  button: {
    height: 24,
    border: "1px solid #6D7175 !important",
    borderRadius: `${theme.spacing(4)}px !important`,
    "& div.selected-flag": {
      padding: theme.spacing(0, 3) + "!important",
      "& > div.flag": {
        position: "relative !important",
        zoom: 0.8,
        backgroundAttachment: (props) => (!!props.noFlag ? "fixed" : "local"),
      },
    },
    "& > -disabled": {display: "none"},
    "&.-neutral > div.selected-flag": {
      width: 48 + "px !important",
      cursor: (props) => (!!props.disableEdit ? "default" : "pointer"),
    },
    "&.-neutral > div.selected-flag > div.flag > div.arrow": {display: "none"},
    "&.tag": {
      border: "none !important",
      borderRight: `1px solid ${theme.palette.primary.main} !important`,
      borderRadius: "0 !important",
      height: 24,
      width: 45,
      "&.error": {
        borderRight: `1px solid ${theme.palette.error.main} !important`,
      },
      "&.-neutral": {display: "none !important"},
    },
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 24,
    height: "100%",
  },
  tagInputContainer: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    minHeight: 24,
    width: "fit-content",
  },
  dropdown: {
    width: "210px !important",
    borderRadius: "10px !important",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.160784) !important",
    overflowY: "auto !important",
    "&::-webkit-scrollbar": {width: "0px !important"},
    padding: theme.spacing(0, 0, 3) + "!important",
    "& > li.country": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: theme.spacing(2, 4) + "!important",
      justifyContent: "flex-start",
      "& > span.country-name": {flex: "1 !important"},
      "& > .flag": {
        position: "relative!important",
        top: "0!important",
        left: "0!important",
        margin: "0!important",
        marginRight: theme.spacing(2) + "px!important",
        marginTop: -theme.spacing(1) + "px!important",
      },
    },
  },
  search: {
    width: "100%",
    padding: theme.spacing(3, 4, 1) + "!important",
    "& > input": {
      marginLeft: "0 !important",
      width: "100%",
    },
  },
  emptyFlag: {
    position: "absolute",
    top: 5,
    left: 15,
    width: 19,
    height: 13,
    backgroundColor: "#DBDBDB",
  },
  closeIconContainer: {
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    borderLeft: `1px solid ${theme.palette.primary.main}4d`,
    "&.error": {borderLeft: `1px solid ${theme.palette.error.main}4d`},
  },
}));

export default function PhoneField({
  phone,
  edit,
  tag,
  setCountry = null,
  allowEmpty = false,
  disableEdit,
  onChange = () => null,
  onKeyDown = () => null,
  setValidPhone = () => null,
  onDelete = null,
  onFocus,
  onBlur,
  error = false,
}) {
  const classes = useStyles({edit, noFlag: !phone, disableEdit, error});
  const phoneRef = useRef(null);

  useEffect(() => {
    if (phoneRef.current && phone) {
      const timer = setTimeout(() => {
        const {selectedCountry} = phoneRef.current.state || {};
        if (selectedCountry) {
          setCountry &&
            setCountry({
              name: selectedCountry.name,
              dialCode: selectedCountry.dialCode,
              countryCode: selectedCountry.iso2,
            });
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [phone, setCountry]);

  const validate = (newPhone, country) => {
    if (!!allowEmpty && !newPhone?.length) {
      return true;
    } else {
      return (
        newPhone.length > 1 &&
        country.hasOwnProperty("dialCode") &&
        newPhone.substring(country.dialCode.length).length >= 5
      );
    }
  };

  const handlePhoneChange = (newPhone, newCountry) => {
    if (!!setCountry) {
      setCountry(newCountry);
    }
    onChange(newPhone);
    const isPhoneValid = validate(newPhone, newCountry);
    setValidPhone(isPhoneValid);
  };

  return (
    <div
      className={clsx(classes.container, {
        "-edit": !!edit,
        [classes.tagContainer]: !!tag,
      })}
    >
      {!phone && !edit && <div className={classes.emptyFlag} />}
      <PhoneInput
        ref={phoneRef}
        value={phone}
        enableSearch
        specialLabel=""
        disableSearchIcon
        enableLongNumbers
        placeholder={!edit ? "No phone" : undefined}
        priority={{ca: 0, us: 1}}
        onChange={handlePhoneChange}
        disabled={!edit}
        preferredCountries={["ca", "us", "fr"]}
        inputClass={clsx(classes.input, {
          "-neutral": !edit,
          "-tag": !!tag,
          "-error": !!error,
        })}
        buttonClass={clsx(classes.button, {
          "-neutral": !edit,
          tag: !!tag,
          error: !!error,
        })}
        searchClass={classes.search}
        dropdownClass={classes.dropdown}
        containerClass={clsx({
          [classes.inputContainer]: !tag,
          [classes.tagInputContainer]: !!tag,
        })}
        inputProps={{
          autoFocus: true,
          onKeyDown: onKeyDown,
          onFocus: onFocus,
          onBlur: onBlur,
        }}
      />
      {!!onDelete && (
        <Box className={clsx(classes.closeIconContainer, {error: !!error})}>
          <CloseIconButton
            xs
            primary={!error}
            error={!!error}
            disablePadding
            onClick={onDelete}
          />
        </Box>
      )}
    </div>
  );
}
