import {ReactComponent as InboxIcon} from "connectui/icons/inbox.svg";
import {ReactComponent as ClockIcon} from "connectui/icons/clock.svg";
import {ReactComponent as CoinStackIcon} from "connectui/icons/coin-stack.svg";
import {ReactComponent as UserIcon} from "connectui/icons/user.svg";
import {ReactComponent as ZapIcon} from "connectui/icons/zap.svg";
import {ReactComponent as EditIcon} from "connectui/icons/edit.svg";
import {ReactComponent as MoreVerticalIcon} from "connectui/icons/more-vertical.svg";
import {ReactComponent as SettingsTuneIcon} from "connectui/icons/settings-tune.svg";
import {ReactComponent as EyeIcon} from "connectui/icons/eye.svg";
import {ReactComponent as EyeOffIcon} from "connectui/icons/eye-off.svg";
import {ReactComponent as CopyIcon} from "connectui/icons/copy.svg";
import {ReactComponent as TrashIcon} from "connectui/icons/trash.svg";
import {ReactComponent as HomeIcon} from "connectui/icons/home.svg";
import {ReactComponent as CloudRainIcon} from "connectui/icons/cloud-rain.svg";
import {ReactComponent as UnlockIcon} from "connectui/icons/unlock.svg";
import {ReactComponent as AnchorIcon} from "connectui/icons/anchor.svg";
import {ReactComponent as BatteryChargingIcon} from "connectui/icons/battery-charging.svg";
import {ReactComponent as WindIcon} from "connectui/icons/wind.svg";
import {ReactComponent as VolumeXIcon} from "connectui/icons/volume-x.svg";
import {ReactComponent as ThermometerIcon} from "connectui/icons/thermometer.svg";
import {ReactComponent as TruckIcon} from "connectui/icons/truck.svg";
import {ReactComponent as AtSignIcon} from "connectui/icons/at-sign.svg";
import {ReactComponent as ToolIcon} from "connectui/icons/tool.svg";
import {ReactComponent as BellIcon} from "connectui/icons/bell.svg";
import {ReactComponent as ThumbsUpIcon} from "connectui/icons/thumbs-up.svg";
import {ReactComponent as KidIcon} from "connectui/icons/kid.svg";
import {ReactComponent as CheckCircleIcon} from "connectui/icons/check-circle.svg";
import {ReactComponent as AlertTriangleIcon} from "connectui/icons/alert-triangle.svg";
import {ReactComponent as LayoutSidebarRightIcon} from "connectui/icons/layout-sidebar-right.svg";
import {ReactComponent as CheckIcon} from "connectui/icons/check.svg";
import {ReactComponent as XIcon} from "connectui/icons/x.svg";
import {ReactComponent as SmallXIcon} from "connectui/icons/small-x.svg";

const ICONS = {
  inbox: InboxIcon,
  clock: ClockIcon,
  "coin-stack": CoinStackIcon,
  user: UserIcon,
  zap: ZapIcon,
  edit: EditIcon,
  "more-vertical": MoreVerticalIcon,
  "settings-tune": SettingsTuneIcon,
  eye: EyeIcon,
  "eye-off": EyeOffIcon,
  copy: CopyIcon,
  trash: TrashIcon,
  home: HomeIcon,
  "cloud-rain": CloudRainIcon,
  unlock: UnlockIcon,
  anchor: AnchorIcon,
  "battery-charging": BatteryChargingIcon,
  wind: WindIcon,
  "volume-x": VolumeXIcon,
  thermometer: ThermometerIcon,
  truck: TruckIcon,
  "at-sign": AtSignIcon,
  tool: ToolIcon,
  bell: BellIcon,
  "thumbs-up": ThumbsUpIcon,
  kid: KidIcon,
  "check-circle": CheckCircleIcon,
  "alert-triangle": AlertTriangleIcon,
  "layout-sidebar-right": LayoutSidebarRightIcon,
  check: CheckIcon,
  x: XIcon,
  "small-x": SmallXIcon,
};

export default ICONS;
