import React from "react";
import {useDispatch} from "react-redux";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
import {getCustomFields} from "redux/actions/settingsActions";
import {operators} from "configuration/constants";
import {recommendedConditions} from "configuration/enums";
import {isValid} from "date-fns";
import clsx from "clsx";
import FieldComponentProvider from "../../../../../ui/component/field/FieldComponentProvider";
import {Button, Collapse, MenuItem, Paper, Typography} from "@material-ui/core";
import CustomFieldDropdown from "../../../../MultiOption/CustomFieldDropdown";
import FilledSelect from "../../../../../core/selects/FilledSelect";
import PrimaryButton from "../../../../../core/buttons/PrimaryButton";
import DeleteIconButton from "../../../../../core/buttons/DeleteIconButton";
import EditIconButton from "../../../../../core/buttons/EditIconButton";

export default function CustomCondition({
  data,
  edit,
  disableEdit,
  viewOnly,
  saveBtnProps,
  onEditChange,
  onSave,
  onCancel,
  onDelete,
  index = 0,
  additionalObjectsVariables = [],
}) {
  const dispatch = useDispatch();
  const styleProps = {filled: true};
  const classes = useExperienceActionsStyles(styleProps);
  const [info, setInfo] = React.useState({field: "", operator: "", val: null});
  const [customFields, setCustomFields] = React.useState({});

  const saveDisabled = React.useMemo(() => {
    const isOperatorEmpty = !info.operator;
    const isValueEmpty = ["exists", "not_exists"].includes(info.operator)
      ? false
      : typeof info.val === "string"
        ? !info.val.trim()
        : false;
    const isDateInvalid =
      recommendedConditions[info.field] === "time"
        ? !info.val || !isValid(info.val)
        : false;
    return isValueEmpty || isOperatorEmpty || isDateInvalid;
  }, [info]);

  React.useEffect(() => {
    resetData();
  }, [data]);

  const updateCustomFields = () => {
    let isMounted = true;
    dispatch(
      getCustomFields({
        objects: additionalObjectsVariables,
        onSuccess: (response) => {
          return !isMounted ? null : setCustomFields((prev) => response ?? {});
        },
      }),
    );

    return () => {
      isMounted = false;
    };
  };
  React.useEffect(updateCustomFields, []);

  const resetData = () => {
    const operatorKey = Object.entries(operators).find(
      (op) => op[1].symbol === data.operator,
    )?.[0];
    setInfo((prev) => ({
      field: !!data.field ? data.field : "",
      operator: operatorKey ?? "",
      val: data.val,
    }));
  };

  const handleOperatorChange = (e) => {
    const val = e.target.value;
    setInfo((prev) => ({...prev, operator: val, val: ""}));
  };

  const handleValueChange = (opType, newVal) => {
    setInfo((prev) => ({
      ...prev,
      val: opType === "number" ? Number(newVal) : newVal,
    }));
  };

  const handleSave = () => {
    const newSymbol = operators[info.operator].symbol;
    const newCondition = {
      c_type: "bool",
      field: info.field,
      operator: newSymbol,
      val: newSymbol === "?" ? "" : info.val,
    };
    onSave(newCondition);
    onEditChange(false);
  };

  const handleCancel = () => {
    resetData();
    onCancel();
  };

  const onEdit = () => onEditChange(true);

  return (
    /**
     * @name FieldComponentProvider
     * @description Generic provider component that provides the custom field data, operator type and component map to the children.
     * @note This can be used anywhere in the application for rendering standalone fields, but is primarily used for conditions.
     */
    <FieldComponentProvider customFieldData={customFields} fieldInfo={info}>
      {({component, optionData, customFieldData}) => (
        <>
          <Collapse
            in={edit}
            timeout={{appear: 0, enter: 150, exit: 150}}
            onClick={(e) => updateCustomFields()}
          >
            <Paper elevation={0} className={classes.paper}>
              <div className={classes.card}>
                <div className={clsx(classes.row, classes.content)}>
                  <div className="selector">
                    <CustomFieldDropdown
                      isField
                      selected={info.field}
                      customFields={customFields}
                      onSelect={(val) => {
                        let defaultValue =
                          recommendedConditions[val] === "time"
                            ? new Date()
                            : "";

                        setInfo((prev) => ({
                          ...prev,
                          field: val,
                          operator: "",
                          val: defaultValue,
                        }));
                      }}
                    />
                  </div>
                  <div className="selector">
                    <FilledSelect
                      fullHeight
                      fullWidth
                      displayEmpty
                      disabled={!info.field}
                      label="Comparison"
                      className={classes.select}
                      value={info.operator}
                      onChange={handleOperatorChange}
                    >
                      <MenuItem value="" disabled>
                        Select an operator
                      </MenuItem>

                      {!!info.field &&
                        component({type: "operators"})
                          ?.filter(
                            (operator) =>
                              operator !== "contains" ||
                              !component({type: "operators"})?.includes(
                                "is_one_of",
                              ),
                          )
                          .map((operator) => (
                            <MenuItem key={operator} value={operator}>
                              {operators[operator].label}
                            </MenuItem>
                          ))}
                    </FilledSelect>
                  </div>
                  {!!info.field &&
                    info.operator &&
                    !["?", "!"].includes(operators[info.operator]?.symbol) && (
                      <div className="field">
                        {component({type: "input", handleValueChange})}
                      </div>
                    )}
                </div>
                <div className={classes.actions}>
                  <Button
                    className="mr-2"
                    classes={{label: classes.cancelBtn}}
                    size="small"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <PrimaryButton
                    size="small"
                    color={saveBtnProps.color}
                    label={saveBtnProps.label}
                    disabled={saveDisabled || disableEdit}
                    className="mr-2"
                    onClick={handleSave}
                  />
                </div>
              </div>
            </Paper>
          </Collapse>
          <Collapse in={!edit} timeout={{appear: 0, enter: 150, exit: 50}}>
            <Paper elevation={0} className={classes.paper}>
              <div
                className={classes.selectedOption}
                onClick={onEdit}
                style={{cursor: "pointer"}}
              >
                <Typography
                  className={clsx(
                    classes.title,
                    classes.flexGrow,
                    classes.ellipsis,
                  )}
                  component="span"
                >
                  <span className={clsx({"ml-1": false}, classes.medium)}>
                    {index > 0 ? "and if " : "If "}
                  </span>
                  {info.field.split("_").join(" ")}
                  <span className={clsx({"ml-1": true}, classes.medium)}>
                    {`${operators[info.operator]?.label} `}
                  </span>
                  {component({type: "label", value: info.val})}
                </Typography>
                {!viewOnly && (
                  <>
                    <EditIconButton disablePadding onClick={onEdit} />
                    <DeleteIconButton
                      disabled={disableEdit}
                      disablePadding
                      marginLeft={2}
                      onClick={onDelete}
                    />
                  </>
                )}
              </div>
            </Paper>
          </Collapse>
        </>
      )}
    </FieldComponentProvider>
  );
}
