import React from "react";
import BaseFieldComponent from "../BaseFieldComponent";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import {SvgIcon, Typography} from "@material-ui/core";
import {ReactComponent as FaceGrinHearts} from "assets/icons/face-grin-hearts.svg";
import {ReactComponent as FaceSmile} from "assets/icons/face-smile.svg";
import {ReactComponent as FaceMeh} from "assets/icons/face-meh.svg";
import {ReactComponent as FaceFrown} from "assets/icons/face-frown.svg";
import {ReactComponent as FaceAngry} from "assets/icons/face-angry.svg";
import {satisfaction} from "configuration/constants";
import PropTypes from "prop-types";
import {buildStyles} from "../../../../connectui/component/buildStyles";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(2),
  },
  chipContent: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
});

const icons = {
  highly_satisfied: FaceGrinHearts,
  satisfied: FaceSmile,
  neutral: FaceMeh,
  somewhat_satisfied: FaceFrown,
  unsatisfied: FaceAngry,
};

/*
 * THIS WILL CRASH WHEN CLICKED, IT NEEDS MORE WORK TO FUNCTION (SUPPORT RANGES)
 *
 * */
const SentimentField = ({fieldData, handleValueChange, options, ...props}) => {
  const renderInput = () => {
    const labelToValue = Object.values(satisfaction).reduce(
      (map, {label, value}) => {
        map[label] = value;
        return map;
      },
      {},
    );

    const options = Object.values(satisfaction).map(({label}) => label);

    const classes = buildStyles(styles);
    return (
      <SelectChipContainer
        options={options}
        selectedOptions={
          fieldData.val
            ? [
                Object.values(satisfaction).find(
                  (s) => s.value === fieldData.val,
                )?.label,
              ]
            : []
        }
        onChange={(newSelection) => {
          const newLabel = newSelection[0] || null;
          const newValue = labelToValue[newLabel];
          handleValueChange(fieldData.type, newValue);
        }}
        multiSelect={true}
        customRender={(option, isSelected) => {
          const key = Object.keys(satisfaction).find(
            (k) => satisfaction[k].label === option,
          );
          return (
            <div className={classes.chipContent}>
              <SvgIcon
                component={icons[key] || FaceMeh}
                style={{width: 24, height: 24}}
              />
              <Typography
                variant="body2"
                color={isSelected ? "primary" : "textSecondary"}
              >
                {option}
              </Typography>
            </div>
          );
        }}
      />
    );
  };

  const getOperators = () => [
    "contains",
    "not_contains",
    "exists",
    "not_exists",
    "is_one_of",
  ];

  const getLabel = () => {
    let value = fieldData?.val;
    if (!value) {
      return satisfaction.unknown.label?.toLowerCase();
    }
    return (
      Object.values(satisfaction).find((s) => s.value === value)?.label || value
    ).toLowerCase();
  };

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

SentimentField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default SentimentField;
