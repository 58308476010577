import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {generateStyles} from "connectui/theme/styleUtilities";
import ButtonBase from "./ButtonBase";
import PropTypes from "prop-types";

/**
 * CircleButtonBase is a variant of the ButtonBase component that renders a circular button with an outlined style.
 * It allows customization through the `sx` prop.
 *
 * @param {Object} props - The props for the CircleButtonBase component.
 * @param {React.ReactNode} props.children - The content of the button, which can be any valid React node.
 * @param {Function} [props.onClick] - The click handler for the button.
 * @param {Object} [props.sx={}] - The style object that can be used to override default outlined button styles.
 * @param {Object} props.rest - Any other props that should be passed to the ButtonBase component.
 *
 * @returns {JSX.Element} The rendered CircleButtonBase component.
 */
const CircleButtonBase = ({children, onClick, sx = {}, ...props}) => {
  const {theme} = useTheme();
  const defaultStyles = {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    padding: theme.spacing(2.5),
    backgroundColor: theme.colors.gray100,
    "&:hover": {backgroundColor: theme.colors.gray200},
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return (
    <ButtonBase onClick={onClick} sx={styles} {...props}>
      {children}
    </ButtonBase>
  );
};

CircleButtonBase.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.any,
};
export default CircleButtonBase;
