import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
// UI
import {
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  form: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  root: {
    padding: theme.spacing(5, 4),
    overflow: "auto",
    flex: 1,
  },
  cardHeader: {padding: theme.spacing(0, 0, 4)},
  wrap: {overflowWrap: "anywhere"},
}));

export default function ConnectedEmailsCard({
  emailState,
  setEmailState,
  handlePanel3Open,
}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  function EmailItem({email}) {
    return (
      <div>
        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{flex: 1}}>
            <Typography variant="h2">{email.managed_email}</Typography>
          </div>
          <IconButton
            onClick={() => {
              setEmailState(email);
              handlePanel3Open(true);
            }}
            style={{padding: "0 .5rem"}}
          >
            <EditIcon />
          </IconButton>
        </div>
        <div style={{display: "flex", alignItems: "center"}} className="mt-1">
          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontSize: "1rem", lineHeight: "1.3rem"}}>
              Send & Receive
            </span>
            {!email.capabilities?.includes("send") ||
            !email.capabilities?.includes("receive") ? (
              <Button
                variant="contained"
                className="ml-2"
                style={{
                  borderRadius: "5px",
                  background: "rgba(248, 236, 215, .5)",
                  color: "rgba(250, 184, 61, 1)",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "1rem",
                  maxHeight: "1.5rem",
                }}
                onClick={() => window.Intercom("showArticle", 9893967)}
              >
                <div
                  style={{
                    height: ".5rem",
                    width: ".5rem",
                    borderRadius: "22.5rem",
                    background: "rgba(250, 184, 61, 1)",
                  }}
                ></div>
                <span className="ml-1">Setup Required</span>
              </Button>
            ) : (
              <Button
                variant="contained"
                className="ml-2"
                style={{
                  borderRadius: "5px",
                  background: "rgba(221, 240, 227, 1)",
                  color: "rgba(74, 164, 77, 1)",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "1rem",
                  maxHeight: "1.5rem",
                }}
              >
                <div
                  style={{
                    height: ".5rem",
                    width: ".5rem",
                    borderRadius: "22.5rem",
                    background: "rgba(74, 164, 77, 1)",
                  }}
                ></div>
                <span className="ml-1">Active</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  const receiveEmails =
    user_profile?.managed_emails?.filter((e) =>
      e.capabilities?.includes("receive"),
    ) || [];

  return (
    <Card>
      <CardContent>
        <form className={classes.form}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <Typography variant="h2">Emails</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEmailState(null);
                handlePanel3Open(true);
              }}
            >
              Add Email
            </Button>
          </div>
          <Typography className="mt-3">Add emails to your account.</Typography>
          {user_profile?.reply_email && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: receiveEmails.length
                    ? "rgba(0, 0, 0, 0.38)"
                    : "inherit",
                  textDecoration: "strike",
                }}
              >
                <Typography variant="h2" className={classes.wrap}>
                  {user_profile?.reply_email}
                </Typography>
                <Button
                  variant="contained"
                  className="ml-3"
                  style={{
                    borderRadius: "5px",
                    background: "#DCE5EC",
                    color: "#275588",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "1rem",
                    maxHeight: "1.5rem",
                  }}
                >
                  Enso Email
                </Button>
              </div>
              <Typography>
                {receiveEmails.length
                  ? t("managed-email-default-email-disabled")
                  : t("managed-email-default-email-enabled")}
              </Typography>
            </>
          )}
          {user_profile?.managed_emails?.map((e) => (
            <EmailItem key={e.managed_email} email={e} />
          ))}
        </form>
      </CardContent>
    </Card>
  );
}
