import React from "react";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import {gradientRgb} from "utilities/helperFunctions";
import PropTypes from "prop-types";

/**
 * Skeleton is a component that renders a loading skeleton with a wave animation.
 * It can be customized using the `width`, `height`, and `style` props.
 *
 * @param {Object} props - The props for the Skeleton component.
 * @param {string} [props.width="100%"] - The width of the skeleton.
 * @param {string} [props.height="20px"] - The height of the skeleton.
 * @param {Object} [props.style={}] - Additional inline styles to apply to the skeleton.
 * @param {Object} props.rest - Any other props that should be passed to the div element.
 *
 * @returns {JSX.Element} The rendered Skeleton component.
 */
const Skeleton = ({width = "100%", height = "20px", style = {}, ...props}) => {
  const {theme} = useTheme();

  const keyframesName = buildStyles({
    "@keyframes wave": {
      "0%": {backgroundPosition: "-50px"},
      "100%": {backgroundPosition: "100vw"},
    },
  })["@keyframes wave"];

  const styles = buildStyles({
    skeleton: {
      display: "block",
      backgroundColor: theme.colors.gray200,
      borderRadius: `${theme.radius.lg}px`,
      position: "relative",
      overflow: "hidden",
    },
    skeletonContent: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `linear-gradient(90deg, ${theme.colors.gray200} 0px, ${gradientRgb(theme.colors.gray100, 0.5)} 50px, ${theme.colors.gray200} 100px)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: width,
      animation: `${keyframesName} 1.4s infinite linear`,
    },
  });

  return (
    <div
      style={{
        width,
        height,
        ...style,
      }}
      className={styles.skeleton}
      {...props}
    >
      <div className={styles.skeletonContent} />
    </div>
  );
};

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
};

export default Skeleton;
