import React from "react";
import {generateStyles} from "connectui/theme/styleUtilities";
import {useTheme} from "connectui/theme/ThemeProvider";
import Container from "../layout/Container";
import PropTypes from "prop-types";

/**
 * SvgIcon is a component that renders an SVG icon wrapped in a Container.
 * It accepts a custom SVG component and allows styling through the `sx` prop.
 *
 * @param {Object} props - The props for the SvgIcon component.
 * @param {React.ElementType} [props.component="svg"] - The custom SVG component to render.
 * @param {Object} [props.sx={}] - The style object that can be used to override default styles.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered SvgIcon component.
 */
const SvgIcon = ({component: SvgComponent = "svg", sx = {}, ...props}) => {
  const {theme} = useTheme();
  const defaultStyles = {display: "flex"};
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return (
    <Container sx={styles} {...props}>
      <SvgComponent />
    </Container>
  );
};

SvgIcon.propTypes = {
  component: PropTypes.any,
  sx: PropTypes.any,
};

export default SvgIcon;
