import React, {useState} from "react";
import {Box, Divider, makeStyles, SvgIcon, Typography} from "@material-ui/core";
import CustomSwitch from "../../../../core/switches/CustomSwitch";
import InputField from "../../../../core/inputs/InputField";
import SyncChip from "../../../../core/chips/SyncChip";
import {useTranslation} from "react-i18next";
import DeviceAccessCodeItem from "../../../../core/listItems/DeviceAccessCodeItem";
import {ReactComponent as KeyIcon} from "assets/icons/key.svg";
import {THEME} from "../../../../configuration/settings";
import {EmptyListPanel} from "../../../../components/Helpers/EmptyPanels";
import WarningAlert from "core/alerts/WarningAlert";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  inputRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  keyIcon: {
    fontSize: 16,
    marginRight: theme.spacing(2),
  },
  inputField: {
    flex: 1,
  },
  expandedSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export default function UserAccessCodesComponent({userInfo, setUserInfo}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [visibilities, setVisibilities] = useState(
    userInfo?.access_codes?.reduce((acc, rc) => {
      acc[rc.access_code] = false;
      return acc;
    }, {}),
  );

  const toggleVisibility = (accessCode) => {
    setVisibilities((prev) => ({
      ...prev,
      [accessCode]: !prev[accessCode],
    }));
  };

  function getWarningAlert() {
    // Initialize counters
    let setCount = 0;
    let totalCount = userInfo.access_codes?.length;
    let settingOnDeviceCount = 0;
    let errorCount = 0;

    // Iterate through the array
    userInfo.access_codes.forEach((code) => {
      if (code.status === "set") {
        setCount++;
      } else if (code.status === "pending") {
        settingOnDeviceCount++;
      }
      if (code.error_message) {
        errorCount++;
      }
    });

    // Build the summary parts
    let summary = `${setCount}/${totalCount} codes set successfully`;

    if (settingOnDeviceCount > 0) {
      summary += ` (${settingOnDeviceCount} codes setting on device`;
      if (errorCount > 0) {
        summary += `, ${errorCount} access code errors)`;
      } else {
        summary += `)`;
      }
    } else if (errorCount > 0) {
      summary += ` (${errorCount} access code errors)`;
    }

    return (
      <WarningAlert
        small
        disableMargin
        title={summary}
        /*actionButtonLabel="Re-generate failed codes"
      action={() => {
        alert("syncinc codes")
      }}*/
      />
    );
  }

  return (
    <>
      <Typography className={classes.heading} variant="h1">
        {t("user-device-codes-tab-heading")}
      </Typography>
      <Box className={classes.switchContainer}>
        <Typography>{t("user-device-codes-tab-description")}</Typography>
        <CustomSwitch
          alignStart
          checked={userInfo.access_code_enabled}
          onChange={() => {
            setUserInfo({
              ...userInfo,
              access_code_enabled: !userInfo.access_code_enabled,
            });
          }}
        />
      </Box>
      {userInfo.access_code_enabled && (
        <Box>
          <Typography variant="body2" color="textSecondary">
            {t("user-device-codes-tab-input-header")}
          </Typography>
          <Box className={classes.inputRow}>
            <InputField
              id="access-code"
              editOnly
              required={true}
              numeric
              placeholder={"Specify a code"}
              disableEnterSave
              value={userInfo.access_code}
              className={classes.inputField}
              onChange={(e) => {
                setUserInfo({...userInfo, access_code: e.target.value});
              }}
            />
          </Box>
          <Divider color="#F0F0F0" />

          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{paddingTop: THEME.spacing.lg, paddingBottom: THEME.spacing.md}}
          >
            <SvgIcon
              component={KeyIcon}
              viewBox="0 0 16 16"
              className={classes.keyIcon}
            />
            <Typography variant="h1">{"User access codes"}</Typography>
          </Box>
          <div className={classes.expandedSection}>
            {getWarningAlert()}
            {!userInfo?.access_codes || userInfo?.access_codes?.length === 0 ? (
              <EmptyListPanel loading noTitle noSearchbar itemsNumber={5} />
            ) : (
              userInfo?.access_codes.map((rc) => (
                <DeviceAccessCodeItem
                  editable
                  key={rc.access_code}
                  deviceCode={rc}
                  disabled={true}
                  visible={visibilities[rc.access_code]}
                  toggleVisibility={toggleVisibility}
                />
              ))
            )}
          </div>
        </Box>
      )}
    </>
  );
}
