import React from "react";
import {Box, styled, Typography} from "@material-ui/core";

const Tag = styled(Box)(({theme, variant, size}) => ({
  backgroundColor:
    variant === "contrast"
      ? theme.palette.primary.main
      : variant === "dark"
        ? "#000000"
        : theme.palette.primary.contrast,
  borderRadius: "5px",
  padding: size === "sm" ? theme.spacing(0.5, 1) : theme.spacing(1, 1.5),
  width: "fit-content",
  height: "fit-content",
}));

const PriceTag = ({
  size = "md",
  fontSize = 14,
  variant = "main",
  price = "",
  bold = false,
}) => {
  return (
    <Tag variant={variant} size={size}>
      <Typography
        fontSize={fontSize}
        fontWeight={!!bold ? 700 : 400}
        // Material-UI v4 (core), Typography's color prop expects a semantic value like "primary", "secondary", "textPrimary", etc., not a hex code.
        // Need to use the style prop to apply a hex code.
        style={{
          color:
            variant === "contrast"
              ? (theme) => theme.palette.primary.contrast
              : variant === "dark"
                ? "#FFFFFF"
                : (theme) => theme.palette.primary.main,
        }}
      >
        {price}
      </Typography>
    </Tag>
  );
};

export default PriceTag;
