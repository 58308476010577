import React from "react";
import {buildStyles} from "../../buildStyles";
import {useTheme} from "connectui/theme/ThemeProvider";
import {generateStyles} from "connectui/theme/styleUtilities";
import FlexContainer from "connectui/component/layout/FlexContainer";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    input: {
      width: "100%",
      border: "none",
      outline: "none",
      padding: 0,
    },
  });

/**
 * InputBase is a component that renders an input field with optional icons and custom styles.
 * It can display an optional icon before the input field and allows customization through the `sx` prop.
 *
 * @param {Object} props - The props for the InputBase component.
 * @param {React.ElementType} [props.as="input"] - The component to render (defaults to "input").
 * @param {Object} [props.inputProps] - The properties to pass to the `<input>` element.
 * @param {React.ReactNode} [props.startIcon=null] - The icon to display before the input field.
 * @param {React.ReactNode} [props.actions=null] - The content to display at the right side of the input field.
 * @param {Object} [props.sx={}] - The style object that can be used to override default input styles.
 * @param {Object} props.rest - Any other props that should be passed to the FlexContainer component.
 *
 * @returns {JSX.Element} The rendered InputBase component.
 */
const InputBase = ({
  as: Component = "input",
  inputProps,
  startIcon = null,
  actions = null,
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const defaultStyles = {
    width: "100%",
    borderRadius: theme.spacing(2),
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return (
    <FlexContainer alignItems="center" sx={styles} {...props}>
      {startIcon && startIcon}
      <Component className={classes.input} {...inputProps} />
      {actions && actions}
    </FlexContainer>
  );
};

InputBase.propTypes = {
  as: PropTypes.any,
  inputProps: PropTypes.object,
  startIcon: PropTypes.node,
  actions: PropTypes.node,
  sx: PropTypes.any,
};

export default InputBase;
