/**
 * Maps shorthand spacing props to CSS styles.
 * @param {object} props - Component props
 * @returns {object} - CSS styles for spacing
 */
export const applySpacing = ({p, m, px, py, mx, my, ...rest}) => {
  const styles = {};

  if (p !== undefined) styles.padding = addPx(p);
  if (m !== undefined) styles.margin = addPx(m);
  if (px !== undefined) {
    styles.paddingLeft = addPx(px);
    styles.paddingRight = addPx(px);
  }
  if (py !== undefined) {
    styles.paddingTop = addPx(py);
    styles.paddingBottom = addPx(py);
  }
  if (mx !== undefined) {
    styles.marginLeft = addPx(mx);
    styles.marginRight = addPx(mx);
  }
  if (my !== undefined) {
    styles.marginTop = addPx(my);
    styles.marginBottom = addPx(my);
  }

  return {...styles, ...rest};
};

/**
 * Adds a default unit to the end of the value if it does not have one.
 * @param {object} value - The spacing value
 * @returns {object} - The spacing value with the unit.
 */
const addPx = (value) => {
  return typeof value === "string" && value.endsWith("px")
    ? value
    : `${value}px`;
};

/**
 * Merges shorthand styles, theme styles, and sx styles.
 * @param {object} props - Component props
 * @param {object} theme - Theme object from ThemeProvider
 * @returns {object} - Final merged styles
 */
export const generateStyles = (props, theme) => {
  const {sx = {}, ...restProps} = props;
  const shorthandStyles = applySpacing(restProps);

  return {...shorthandStyles, ...sx};
};
