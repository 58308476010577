import React, {useState} from "react";
import PropTypes from "prop-types";
import Container from "../layout/Container";
import {buildStyles} from "../buildStyles";
import {useTheme} from "../../theme/ThemeProvider";

const useStyles = (theme) =>
  buildStyles({
    tooltipWrapper: {
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
    },
    tooltipContent: {
      position: "absolute",
      bottom: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: theme.colors.white,
      color: theme.colors.textPrimary,
      boxShadow: theme.shadows.md,
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
      whiteSpace: "nowrap",
      zIndex: 999,
    },
    tooltipArrow: {
      position: "absolute",
      bottom: "-6px",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderTop: `6px solid ${theme.colors.white}`,
    },
  });

const Tooltip = ({children, tooltipContent}) => {
  const [visible, setVisible] = useState(false);
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <Container
      className={classes.tooltipWrapper}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <Container as="div" className={classes.tooltipContent}>
          {tooltipContent}
          <div className={classes.tooltipArrow} />
        </Container>
      )}
    </Container>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node.isRequired,
};

export default Tooltip;
