import React, {useRef, useState} from "react";
// UI
import {Typography, MenuItem, Box} from "@material-ui/core";
// Custom
import {makeStyles} from "@material-ui/core/styles";
import CustomSwitch from "core/switches/CustomSwitch";
import FilledTextField from "core/inputs/FilledTextField";
import FilledSelect from "core/selects/FilledSelect";
import PrimaryButton from "core/buttons/PrimaryButton";
import EditIconButton from "core/buttons/EditIconButton";
// Utils
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  toggleBtnContainer: {marginLeft: -10},
  card: {
    display: "flex",
    backgroundColor: "#FBFBFC",
    border: "1px solid #E6E6E6",
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: 10,
    margin: theme.spacing(1, 0, 3),
  },
  previewCardContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  editableCardContainer: {flexDirection: "column"},
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  btnsRow: {justifyContent: "flex-end"},
}));

function PreviewCard({value, unit, onEdit, text = "before check-in time"}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.card, classes.previewCardContainer)}
      onClick={onEdit}
    >
      <Typography component="div">
        <strong>{`${value} ${unit} `}</strong> {text}
      </Typography>
      <EditIconButton disablePadding onClick={onEdit} />
    </div>
  );
}

function EditableCard({
  numberValue,
  onNumberChange,
  onSelectChange,
  selectorValue,
  onCancel,
  onSave,
  disableEdit,
  isMS,
  text = "before check-in time",
}) {
  const classes = useStyles();
  let disableSave = typeof numberValue === "string" && !numberValue.length;

  return (
    <div className={clsx(classes.card, classes.editableCardContainer)}>
      <Box className={classes.row}>
        <FilledTextField
          value={numberValue}
          type="number"
          style={{maxWidth: 130}}
          inputProps={{min: 0}}
          onChange={(e) => {
            const newVal = e.target.value;
            onNumberChange(newVal);
          }}
        />
        <FilledSelect
          fixedPR={THEME.spacing.xs}
          value={selectorValue}
          style={{borderRadius: 0}}
          onChange={(e) => {
            const newVal = e.target.value;
            onSelectChange(newVal);
          }}
        >
          <MenuItem value={isMS ? 60000 : 1}>minutes</MenuItem>
          <MenuItem value={isMS ? 3600000 : 60}>hours</MenuItem>
          <MenuItem value={isMS ? 86400000 : 1440}>days</MenuItem>
        </FilledSelect>
        <Box flex={1}>
          <Typography>{text}</Typography>
        </Box>
      </Box>
      <Box className={clsx(classes.row, classes.btnsRow)}>
        <PrimaryButton
          subdued
          size="small"
          variant="text"
          label="Cancel"
          onClick={onCancel}
        />
        <PrimaryButton
          size="small"
          label="Save"
          disabled={disableEdit || disableSave}
          color="secondary"
          onClick={onSave}
        />
      </Box>
    </div>
  );
}

export default function CheckinSettingsPanel({
  manual_release_checkin,
  editData,
  disableEdit,
  verificationType,
  show_info_before_verification,
  hideAddress,
  hideCodes,
  hideVerification,
  hideCheckinDirections,
  hideCheckoutDirections,
  setHideAddress,
  setHideVerification,
  setHideCheckinDirections,
  setHideCheckoutDirections,
  setHideCodes,
  restrict_verification = null,
  restrict_address = null,
  restrict_checkin = null,
  restrict_checkout = null,
  restrict_codes = null,
  onSave,
}) {
  const prevValues = useRef({
    verification: null,
    address: null,
    checkin_directions: null,
  });
  const [timeMultiplier, setTimeMultiplier] = useState({
    verification: 1,
    address: 1,
    restrict_codes: 1,
    checkin_directions: 1,
    checkout_directions: 1,
  });
  const [edit, setEdit] = useState({
    verification: false,
    checkin_directions: false,
    checkout_directions: false,
    address: false,
    restrict_codes: false,
  });
  const classes = useStyles();
  console.log(
    "RESTRICT CHECKOUT",
    restrict_checkout,
    timeMultiplier.checkout_directions,
  );

  React.useEffect(() => {
    const newRestrictCheckinTime =
      restrict_checkin === null || restrict_checkin === -1
        ? 30
        : restrict_checkin;
    if (newRestrictCheckinTime === 0 || !newRestrictCheckinTime) {
      return;
    } else if (newRestrictCheckinTime % 1440 === 0) {
      setTimeMultiplier((prev) => ({...prev, checkin_directions: 1440}));
    } // Days
    else if (newRestrictCheckinTime % 60 === 0) {
      setTimeMultiplier((prev) => ({...prev, checkin_directions: 60}));
    } // Hours
    else {
      setTimeMultiplier((prev) => ({...prev, checkin_directions: 1}));
    } // Minutes
  }, [restrict_checkin]);

  React.useEffect(() => {
    const newRestrictCheckoutTime =
      restrict_checkout === null || restrict_checkout === -1
        ? 1800000
        : restrict_checkout;
    if (newRestrictCheckoutTime === 0 || !newRestrictCheckoutTime) {
      return;
    } else if (newRestrictCheckoutTime % 86400000 === 0) {
      setTimeMultiplier((prev) => ({...prev, checkout_directions: 86400000}));
    } // Days
    else if (newRestrictCheckoutTime % 3600000 === 0) {
      setTimeMultiplier((prev) => ({...prev, checkout_directions: 3600000}));
    } // Hours
    else {
      setTimeMultiplier((prev) => ({...prev, checkout_directions: 60000}));
    } // Minutes
  }, [restrict_checkout]);

  React.useEffect(() => {
    const newRestrictVerificationTime =
      restrict_verification === null || restrict_verification === -1
        ? 1800000
        : restrict_verification;
    if (newRestrictVerificationTime === 0 || !newRestrictVerificationTime) {
      return;
    }
    if (newRestrictVerificationTime % 86400000 === 0) {
      setTimeMultiplier((prev) => ({...prev, verification: 86400000}));
    } // Days
    else if (newRestrictVerificationTime % 3600000 === 0) {
      setTimeMultiplier((prev) => ({...prev, verification: 3600000}));
    } // Hours
    else {
      setTimeMultiplier((prev) => ({...prev, verification: 60000}));
    } // Minutes
  }, [restrict_verification]);

  React.useEffect(() => {
    const newRestrictAddressTime =
      restrict_address === null || restrict_address === -1
        ? 1800000
        : restrict_address;
    if (newRestrictAddressTime === 0 || !newRestrictAddressTime) {
      return;
    } else if (newRestrictAddressTime % 86400000 === 0) {
      setTimeMultiplier((prev) => ({...prev, address: 86400000}));
    } // Days
    else if (newRestrictAddressTime % 3600000 === 0) {
      setTimeMultiplier((prev) => ({...prev, address: 3600000}));
    } // Hours
    else {
      setTimeMultiplier((prev) => ({...prev, address: 60000}));
    } // Minutes
  }, [restrict_address]);

  React.useEffect(() => {
    const newRestrictCodesTime =
      restrict_codes === null || restrict_codes === -1
        ? 1800000
        : restrict_codes;
    if (newRestrictCodesTime === 0 || !newRestrictCodesTime) {
      return;
    } else if (newRestrictCodesTime % 86400000 === 0) {
      setTimeMultiplier((prev) => ({...prev, restrict_codes: 86400000}));
    } // Days
    else if (newRestrictCodesTime % 3600000 === 0) {
      setTimeMultiplier((prev) => ({...prev, restrict_codes: 3600000}));
    } // Hours
    else {
      setTimeMultiplier((prev) => ({...prev, restrict_codes: 60000}));
    } // Minutes
  }, [restrict_codes]);

  function handleSetShowInfo() {
    const triggerSaving = true;
    if (show_info_before_verification) {
      editData({show_info_before_verification: false}, triggerSaving);
      setHideVerification(false);
    } else {
      editData(
        {
          show_info_before_verification: true,
          restrict_verification:
            restrict_verification === null || restrict_verification === -1
              ? 1800000
              : restrict_verification, // default: 30 min
        },
        triggerSaving,
      );
    }
  }

  const toggleSwitchBtn = (type, val) => () => {
    const triggerSaving = true;
    switch (type) {
      case "codes":
        setHideCodes(val);
        editData(
          {
            restrict_codes:
              restrict_codes === null || restrict_codes === -1
                ? 1800000
                : restrict_codes,
          },
          triggerSaving,
        ); // default: 30 min
        break;
      case "verification":
        setHideVerification(val);
        editData(
          {
            restrict_verification:
              restrict_verification === null || restrict_verification === -1
                ? 1800000
                : restrict_verification,
          },
          triggerSaving,
        ); // default: 30 min
        break;
      case "checkin":
        setHideCheckinDirections(val);
        editData(
          {
            restrict_checkin:
              restrict_checkin === null || restrict_checkin === -1
                ? 30
                : restrict_checkin,
          },
          triggerSaving,
        ); // default: 30 min
        break;
      case "checkout":
        setHideCheckoutDirections(val);
        editData(
          {
            restrict_checkout:
              restrict_checkout === null || restrict_checkout === -1
                ? 1800000
                : restrict_checkout,
          },
          triggerSaving,
        ); // default: 30 min
        break;
      case "address":
        setHideAddress(val);
        editData(
          {
            restrict_address:
              restrict_address === null || restrict_address === -1
                ? 1800000
                : restrict_address,
          },
          triggerSaving,
        ); // default: 30 min
        break;
      case "manual_release":
        editData({manual_release_checkin: val}, triggerSaving);
        break;
    }
  };

  function getUnit(val) {
    switch (val) {
      case 60000:
      case 1:
        return "minutes";
      case 3600000:
      case 60:
        return "hours";
      case 86400000:
        return "days";
      case 1440:
        return "days";

      default:
        return "";
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      {/* VIEW CHECK-IN DIRECTIONS */}
      <div>
        <div style={{marginLeft: -10}}>
          <CustomSwitch
            disabled={disableEdit || verificationType === "automated"}
            checked={
              verificationType === "automated"
                ? false
                : manual_release_checkin || false
            }
            onClick={toggleSwitchBtn("manual_release", !manual_release_checkin)}
          />
          <Typography style={{display: "inline"}}>
            Manually approve guests to view check-in directions
          </Typography>
        </div>
      </div>
      {/* SHOW GUIDEBOOKS & UPSELLS */}
      <div>
        <div className={classes.toggleBtnContainer}>
          <CustomSwitch
            disabled={disableEdit}
            checked={!!show_info_before_verification}
            onClick={() => handleSetShowInfo()}
          />
          <Typography style={{display: "inline"}}>
            Show guidebooks & upsells before verification
          </Typography>
        </div>
      </div>
      {/* HIDE VERIFICATION UNTIL... */}
      {!!show_info_before_verification && (
        <div>
          <div className={classes.toggleBtnContainer}>
            <CustomSwitch
              disabled={disableEdit}
              checked={hideVerification}
              onClick={toggleSwitchBtn("verification", !hideVerification)}
            />
            <Typography style={{display: "inline"}}>
              Hide verification until specific time
            </Typography>
          </div>
          {!!hideVerification &&
            (edit.verification ? (
              <EditableCard
                isMS
                disableEdit={disableEdit}
                numberValue={
                  !restrict_verification
                    ? restrict_verification
                    : (restrict_verification || 0) / timeMultiplier.verification
                }
                selectorValue={timeMultiplier.verification}
                onNumberChange={(newVal) =>
                  editData({
                    restrict_verification: !newVal
                      ? newVal
                      : newVal * timeMultiplier.verification,
                  })
                }
                onSelectChange={(newVal) => {
                  setTimeMultiplier((prev) => ({
                    ...prev,
                    verification: newVal,
                  }));
                  editData({
                    restrict_verification:
                      (restrict_verification / timeMultiplier.verification) *
                      newVal,
                  });
                }}
                onCancel={() => {
                  setEdit((prev) => ({...prev, verification: false}));
                  editData({
                    restrict_verification:
                      prevValues.current.verification.restrict_verification,
                  });
                  setTimeMultiplier((prev) => ({
                    ...prev,
                    verification:
                      prevValues.current.verification.timeMultiplier,
                  }));
                }}
                onSave={() => {
                  setEdit((prev) => ({...prev, verification: false}));
                  onSave();
                }}
              />
            ) : (
              <PreviewCard
                value={
                  (restrict_verification || 0) / timeMultiplier.verification
                }
                unit={getUnit(timeMultiplier.verification)}
                onEdit={() => {
                  prevValues.current.verification = {
                    restrict_verification,
                    timeMultiplier: timeMultiplier.verification,
                  };
                  setEdit((prev) => ({...prev, verification: true}));
                }}
              />
            ))}
        </div>
      )}
      {/* HIDE CHECK-IN DIRECTIONS UNTIL... */}
      <div>
        <div style={{marginLeft: -10}}>
          <CustomSwitch
            disabled={disableEdit}
            checked={hideCheckinDirections}
            onClick={toggleSwitchBtn("checkin", !hideCheckinDirections)}
          />
          <Typography style={{display: "inline"}}>
            Hide check-in directions until specific time
          </Typography>
        </div>
        {!!hideCheckinDirections &&
          (edit.checkin_directions ? (
            <EditableCard
              disableEdit={disableEdit}
              numberValue={
                !restrict_checkin
                  ? restrict_checkin
                  : (restrict_checkin || 0) / timeMultiplier.checkin_directions
              }
              selectorValue={timeMultiplier.checkin_directions}
              onNumberChange={(newVal) =>
                editData({
                  restrict_checkin: !newVal
                    ? newVal
                    : newVal * timeMultiplier.checkin_directions,
                })
              }
              onSelectChange={(newVal) => {
                setTimeMultiplier((prev) => ({
                  ...prev,
                  checkin_directions: newVal,
                }));
                editData({
                  restrict_checkin:
                    (restrict_checkin / timeMultiplier.checkin_directions) *
                    newVal,
                });
              }}
              onCancel={() => {
                setEdit((prev) => ({...prev, checkin_directions: false}));
                editData({
                  restrict_checkin:
                    prevValues.current.checkin_directions.restrict_checkin,
                });
                setTimeMultiplier((prev) => ({
                  ...prev,
                  checkin_directions:
                    prevValues.current.checkin_directions.timeMultiplier,
                }));
              }}
              onSave={() => {
                setEdit((prev) => ({...prev, checkin_directions: false}));
                onSave();
              }}
            />
          ) : (
            <PreviewCard
              value={
                (restrict_checkin || 0) / timeMultiplier.checkin_directions
              }
              unit={getUnit(timeMultiplier.checkin_directions)}
              onEdit={() => {
                prevValues.current.checkin_directions = {
                  restrict_checkin,
                  timeMultiplier: timeMultiplier.checkin_directions,
                };
                setEdit((prev) => ({...prev, checkin_directions: true}));
              }}
            />
          ))}
      </div>
      {/* HIDE CHECK-OUT DIRECTIONS UNTIL... */}
      <div>
        <div style={{marginLeft: -10}}>
          <CustomSwitch
            disabled={disableEdit}
            checked={hideCheckoutDirections}
            onClick={toggleSwitchBtn("checkout", !hideCheckoutDirections)}
          />
          <Typography style={{display: "inline"}}>
            Hide check-out directions until specific time
          </Typography>
        </div>
        {!!hideCheckoutDirections &&
          (edit.checkout_directions ? (
            <EditableCard
              text="before check-out time"
              isMS
              disableEdit={disableEdit}
              numberValue={
                !restrict_checkout
                  ? restrict_checkout
                  : (restrict_checkout || 0) /
                    timeMultiplier.checkout_directions
              }
              selectorValue={timeMultiplier.checkout_directions}
              onNumberChange={(newVal) =>
                editData({
                  restrict_checkout: !newVal
                    ? newVal
                    : newVal * timeMultiplier.checkout_directions,
                })
              }
              onSelectChange={(newVal) => {
                setTimeMultiplier((prev) => ({
                  ...prev,
                  checkout_directions: newVal,
                }));
                editData({
                  restrict_checkout:
                    (restrict_checkout / timeMultiplier.checkout_directions) *
                    newVal,
                });
              }}
              onCancel={() => {
                setEdit((prev) => ({...prev, checkout_directions: false}));
                editData({
                  restrict_checkout:
                    prevValues.current.checkout_directions.restrict_checkout,
                });
                setTimeMultiplier((prev) => ({
                  ...prev,
                  checkout_directions:
                    prevValues.current.checkout_directions.timeMultiplier,
                }));
              }}
              onSave={() => {
                setEdit((prev) => ({...prev, checkout_directions: false}));
                onSave();
              }}
            />
          ) : (
            <PreviewCard
              text="before check-out time"
              value={
                (restrict_checkout || 0) / timeMultiplier.checkout_directions
              }
              unit={getUnit(timeMultiplier.checkout_directions)}
              onEdit={() => {
                prevValues.current.checkout_directions = {
                  restrict_checkout,
                  timeMultiplier: timeMultiplier.checkout_directions,
                };
                setEdit((prev) => ({...prev, checkout_directions: true}));
              }}
            />
          ))}
      </div>
      {/* HIDE ADDRESS UNTIL... */}
      <div>
        <div style={{marginLeft: -10}}>
          <CustomSwitch
            disabled={disableEdit}
            checked={hideAddress}
            onClick={toggleSwitchBtn("address", !hideAddress)}
          />
          <Typography style={{display: "inline"}}>
            Hide address until specific time
          </Typography>
        </div>
        {!!hideAddress &&
          (edit.address ? (
            <EditableCard
              isMS
              disableEdit={disableEdit}
              numberValue={
                !restrict_address
                  ? restrict_address
                  : (restrict_address || 0) / timeMultiplier.address
              }
              selectorValue={timeMultiplier.address}
              onNumberChange={(newVal) =>
                editData({
                  restrict_address: !newVal
                    ? newVal
                    : newVal * timeMultiplier.address,
                })
              }
              onSelectChange={(newVal) => {
                setTimeMultiplier((prev) => ({...prev, address: newVal}));
                editData({
                  restrict_address:
                    (restrict_address / timeMultiplier.address) * newVal,
                });
              }}
              onCancel={() => {
                setEdit((prev) => ({...prev, address: false}));
                editData({
                  restrict_address: prevValues.current.address.restrict_address,
                });
                setTimeMultiplier((prev) => ({
                  ...prev,
                  address: prevValues.current.address.timeMultiplier,
                }));
              }}
              onSave={() => {
                setEdit((prev) => ({...prev, address: false}));
                onSave();
              }}
            />
          ) : (
            <PreviewCard
              value={(restrict_address || 0) / timeMultiplier.address}
              unit={getUnit(timeMultiplier.address)}
              onEdit={() => {
                prevValues.current.address = {
                  restrict_address,
                  timeMultiplier: timeMultiplier.address,
                };
                setEdit((prev) => ({...prev, address: true}));
              }}
            />
          ))}
      </div>
      {/* HIDE CODES UNTIL... */}
      <div>
        <div style={{marginLeft: -10}}>
          <CustomSwitch
            disabled={disableEdit}
            checked={hideCodes}
            onClick={toggleSwitchBtn("codes", !hideCodes)}
          />
          <Typography style={{display: "inline"}}>
            Hide smart lock codes until specific time
          </Typography>
        </div>
        {!!hideCodes &&
          (edit.restrict_codes ? (
            <EditableCard
              isMS
              disableEdit={disableEdit}
              numberValue={
                !restrict_address
                  ? restrict_codes
                  : (restrict_codes || 0) / timeMultiplier.restrict_codes
              }
              selectorValue={timeMultiplier.restrict_codes}
              onNumberChange={(newVal) =>
                editData({
                  restrict_codes: !newVal
                    ? newVal
                    : newVal * timeMultiplier.restrict_codes,
                })
              }
              onSelectChange={(newVal) => {
                setTimeMultiplier((prev) => ({...prev, codes: newVal}));
                editData({
                  restrict_codes:
                    (restrict_codes / timeMultiplier.restrict_codes) * newVal,
                });
              }}
              onCancel={() => {
                setEdit((prev) => ({...prev, restrict_codes: false}));
                editData({restrict_codes: prevValues.current.restrict_codes});
                setTimeMultiplier((prev) => ({
                  ...prev,
                  restrict_codes:
                    prevValues.current.restrict_codes.timeMultiplier,
                }));
              }}
              onSave={() => {
                setEdit((prev) => ({...prev, restrict_codes: false}));
                onSave();
              }}
            />
          ) : (
            <PreviewCard
              value={(restrict_codes || 0) / timeMultiplier.restrict_codes}
              unit={getUnit(timeMultiplier.restrict_codes)}
              onEdit={() => {
                prevValues.current.restrict_codes = {
                  restrict_codes,
                  timeMultiplier: timeMultiplier.restrict_codes,
                };
                setEdit((prev) => ({...prev, restrict_codes: true}));
              }}
            />
          ))}
      </div>
    </Box>
  );
}
