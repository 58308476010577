import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {generateStyles} from "connectui/theme/styleUtilities";
import SvgIcon from "./SvgIcon";
import PropTypes from "prop-types";
import ICONS from "connectui/icons/iconMap";

/**
 * RoundIcon is a SvgIcon component that renders a rounded icon with variants of a base color applied to the border, background and icon color.
 * It allows customization through the `sx` prop.
 *
 * @param {Object} props - The props for the RoundIcon component.
 * @param {React.ReactNode} props.baseColor - The base color name to apply to the icon color, background and border.
 * @param {Function} [props.iconComponent] - The name of the icon to use. This name must be listed in iconMap.js file.
 * @param {Object} [props.sx={}] - The style object that can be used to override default outlined button styles.
 * @param {Object} props.rest - Any other props that should be passed to the SvgIcon component.
 *
 * @returns {JSX.Element} The rendered RoundIcon component.
 */
const RoundIcon = ({
  baseColor = "primary",
  iconComponent = "",
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const defaultStyles = {
    width: "56px",
    height: "56px",
    borderRadius: "28px",
    padding: theme.spacing(2),
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    border: `8px solid ${theme.colors[`${baseColor}50`]}`,
    background: theme.colors[`${baseColor}100`],
    color: theme.colors[`${baseColor}`],
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return <SvgIcon sx={styles} component={ICONS[iconComponent]} />;
};

RoundIcon.propTypes = {
  baseColor: PropTypes.string,
  iconComponent: PropTypes.string.isRequired,
  sx: PropTypes.any,
};

export default RoundIcon;
