const priceByIsoMap = {
  ad: 0.1317,
  ae: 0.1092,
  af: 0.3756,
  ag: 0.1291,
  ai: 0.1387,
  al: 0.1101,
  am: 0.1384,
  ao: 0.0887,
  ar: 0.0935,
  as: 0.1881,
  at: 0.0979,
  au: 0.0515,
  aw: 0.1298,
  az: 0.3877,
  ba: 0.084,
  bb: 0.1573,
  bd: 0.3222,
  be: 0.105,
  bf: 0.1818,
  bg: 0.1466,
  bh: 0.0364,
  bi: 0.3757,
  bj: 0.2419,
  bm: 0.1661,
  bn: 0.02,
  bo: 0.1452,
  br: 0.0599,
  bs: 0.0525,
  bt: 0.4007,
  bw: 0.0998,
  by: 0.2521,
  bz: 0.2738,
  ca: 0.0079,
  cd: 0.2291,
  cf: 0.4518,
  cg: 0.282,
  ch: 0.0725,
  ci: 0.2454,
  ck: 0.1396,
  cl: 0.0742,
  cm: 0.1959,
  cn: 0.0386,
  co: 0.0525,
  cr: 0.042,
  cu: 0.084,
  cv: 0.2239,
  cy: 0.0864,
  cz: 0.0601,
  de: 0.094,
  dj: 0.1624,
  dk: 0.0524,
  dm: 0.2078,
  do: 0.0815,
  dz: 0.2575,
  ec: 0.181,
  ee: 0.0958,
  eg: 0.2777,
  er: 0.1166,
  es: 0.0875,
  et: 0.3425,
  fi: 0.0861,
  fj: 0.1651,
  fk: 0.136,
  fm: 0.0735,
  fo: 0.0676,
  fr: 0.0798,
  ga: 0.2634,
  gb: 0.0463,
  gd: 0.193,
  ge: 0.1589,
  gf: 0.1842,
  gh: 0.3065,
  gi: 0.1091,
  gl: 0.041,
  gm: 0.166,
  gn: 0.1517,
  gp: 0.135,
  gq: 0.1729,
  gr: 0.062,
  gt: 0.2498,
  gu: 0.0687,
  gw: 0.1976,
  gy: 0.1787,
  hk: 0.0682,
  hn: 0.246,
  hr: 0.1096,
  ht: 0.2356,
  hu: 0.091,
  id: 0.4414,
  ie: 0.0735,
  il: 0.1739,
  in: 0.0832,
  iq: 0.3401,
  ir: 0.3534,
  is: 0.0661,
  it: 0.0927,
  jm: 0.2031,
  jo: 0.2726,
  jp: 0.084,
  ke: 0.1732,
  kg: 0.3178,
  kh: 0.2055,
  ki: 0.1455,
  km: 0.4175,
  kn: 0.1509,
  kr: 0.0494,
  kw: 0.2581,
  ky: 0.1652,
  kz: 0.2852,
  la: 0.2389,
  lb: 0.3619,
  lc: 0.1509,
  li: 0.0334,
  lk: 0.3962,
  lr: 0.166,
  ls: 0.204,
  lt: 0.0545,
  lu: 0.074,
  lv: 0.0715,
  ly: 0.3984,
  ma: 0.2244,
  mc: 0.1856,
  md: 0.0776,
  me: 0.1137,
  mg: 0.3728,
  mh: 0.1589,
  mk: 0.0744,
  ml: 0.3288,
  mm: 0.2829,
  mn: 0.279,
  mo: 0.059,
  mq: 0.1401,
  mr: 0.2583,
  ms: 0.1509,
  mt: 0.065,
  mu: 0.1597,
  mv: 0.1771,
  mw: 0.3307,
  mx: 0.1079,
  my: 0.2594,
  mz: 0.3005,
  na: 0.0626,
  nc: 0.1761,
  ne: 0.2971,
  nf: 0.02817,
  ng: 0.3414,
  ni: 0.0841,
  nl: 0.0966,
  no: 0.0651,
  np: 0.2192,
  nu: 0.1928,
  nz: 0.105,
  om: 0.1707,
  pa: 0.1732,
  pe: 0.1325,
  pf: 0.1797,
  pg: 0.7544,
  ph: 0.2001,
  pk: 0.331,
  pl: 0.0431,
  pm: 0.1507,
  pr: 0.042,
  ps: 0.3869,
  pt: 0.0473,
  pw: 0.0998,
  py: 0.0934,
  qa: 0.2218,
  qk: 0.2114, // Kosovo (non-standard code from CSV)
  re: 0.1911,
  ro: 0.0737,
  rs: 0.2416,
  ru: 0.7096,
  rw: 0.2627,
  sa: 0.1422,
  sb: 0.083,
  sc: 0.2214,
  sd: 0.368,
  se: 0.0609,
  sg: 0.0435,
  si: 0.1935,
  sk: 0.0883,
  sl: 0.1877,
  sm: 0.1096,
  sn: 0.2594,
  so: 0.2286,
  sr: 0.1971,
  ss: 0.2399,
  st: 0.1307,
  sv: 0.0972,
  sy: 0.3118,
  sz: 0.2235,
  tc: 0.1665,
  td: 0.2897,
  tg: 0.3485,
  th: 0.0305,
  tj: 0.4505,
  tl: 0.1135,
  tm: 0.3233,
  tn: 0.2803,
  to: 0.1307,
  tr: 0.0305,
  tt: 0.2399,
  tv: 0.1332,
  tw: 0.0595,
  tz: 0.3219,
  ua: 0.1747,
  ug: 0.2631,
  us: 0.0079,
  uy: 0.0852,
  uz: 0.4571,
  vc: 0.1954,
  ve: 0.1091,
  vg: 0.1875,
  vi: 0.02,
  vn: 0.1552,
  vu: 0.2067,
  wf: 0.11,
  ws: 0.2369,
  ye: 0.2391,
  za: 0.0757,
  zm: 0.3278,
  zw: 0.2481,
};

/**
 * Get price by ISO code
 * @param {string} countryCode - The iso2 country code
 * @returns {number|undefined} The price if found, otherwise undefined.
 */
export function getPriceByIso(countryCode) {
  if (!countryCode) return undefined;
  return priceByIsoMap[countryCode.toLowerCase()];
}
