import React from "react";
// UI
import {Button, Chip, MenuItem, MenuList, Tooltip} from "@material-ui/core";
import {lighten, makeStyles} from "@material-ui/core/styles";
import DotIcon from "@material-ui/icons/FiberManualRecord";
// Custom
import FilledSelect from "core/selects/FilledSelect";
// Utilities
import {
  BOOKING_STATUS_COLORS,
  STAGE_COLORS,
  THEME,
} from "configuration/settings.js";
import {bookingStatusLabels, guestStageLabels} from "configuration/constants";
import {capitalize} from "utilities/helperFunctions";
import CustomMenu from "core/menus/CustomMenu";
import clsx from "clsx";

const guestOptions = Object.entries(guestStageLabels);
const bookingOptions = Object.entries(bookingStatusLabels);
const listingOptions = Object.entries({active: "Active", inactive: "Inactive"});

const colors = {
  active: {color: "#4A4AFF", bg: "#E6E6F2"},
  inactive: {color: "#425460", bg: "#F0F6F9"},
  paid: {color: "#007F00", bg: "#CDFFCD"},
  overdue: {color: "#D30000", bg: "#FFE0E0"},
  unpaid: {color: "#CE8500", bg: "#FFECCC"},
  default: {color: THEME.primary, bg: lighten(THEME.primary, 0.85)},
};

const CUSTOM_COLORS = {...STAGE_COLORS, ...BOOKING_STATUS_COLORS};
for (const cstmColor in CUSTOM_COLORS) {
  colors[cstmColor] = {
    color: CUSTOM_COLORS[cstmColor],
    bg: lighten(CUSTOM_COLORS[cstmColor], 0.85),
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    height: (props) => (!!props.square ? 24 : "fit-content"),
    padding: (props) => (!!props.square ? theme.spacing(1, 2) : "2px 0"),
    borderRadius: (props) => (!!props.square ? 5 : 16),
    "&.xPadd": {padding: theme.spacing(1, 2)},
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  labelSmall: {
    ...theme.typography.caption,
    textTransform: "capitalize",
    padding: theme.spacing(0, 0, 0, 1),
  },
  icon: {
    width: 10,
    height: 10,
    margin: 0,
  },
  selectedStage: {fontWeight: 700},
  btnStatus: {
    width: "fit-content",
    padding: "3px 16px!important",
    backgroundColor: "#01AAE426!important",
    border: "none!important",
  },
  btnFilledLabel: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.subtitle1.fontWeight,
    textTransform: theme.typography.subtitle1.textTransform,
    lineHeight: "14px",
    color: "#01AAE4",
  },
}));

export default function Status({
  status,
  type,
  xPadd,
  square,
  edit,
  value,
  disableTooltip,
  onValueChange,
}) {
  const classes = useStyles({square});
  const [selectedStatus, setSelectedStatus] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const chipLabel = React.useMemo(() => capitalize(status, "_"), [status]);
  const selectorOptions = React.useMemo(() => {
    switch (type) {
      case "guest":
        return guestOptions;
      case "booking":
        return bookingOptions;
      case "listing":
        return listingOptions;
      default:
        return [];
    }
  }, [type]);
  let tooltipProps = disableTooltip ? {open: false} : {};

  React.useEffect(() => {
    if (edit && value !== selectedStatus) {
      setSelectedStatus((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selectedStatus);
    }
  }, [selectedStatus]);

  const closeMenu = () => setAnchorEl((prev) => null);

  const handleStageChange = (newVal) => () => {
    setSelectedStatus((prev) => newVal);
    closeMenu();
  };

  const handleStatusChange = (e) => {
    const val = e.target.value;
    setSelectedStatus((prev) => val);
  };

  function getMenuContent() {
    return (
      <MenuList id="guest-stage-menu">
        <MenuItem value="" disabled>
          {"Select a stage"}
        </MenuItem>
        {selectorOptions.map((opt) => (
          <MenuItem
            key={opt[0]}
            value={opt[0]}
            selected={selectedStatus === opt[0]}
            classes={{selected: classes.selectedStage}}
            onClick={handleStageChange(opt[0])}
          >
            {opt[1]}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  if (edit) {
    return (
      <div className={classes.container}>
        {type === "guest" ? (
          <>
            <CustomMenu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={closeMenu}
              content={getMenuContent()}
            />
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              disableElevation
              className={classes.btnStatus}
              classes={{label: classes.btnFilledLabel}}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {guestStageLabels[selectedStatus]}
            </Button>
          </>
        ) : (
          <FilledSelect
            fullWidth
            label="Status"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <MenuItem value="" disabled>
              {"Select a status"}
            </MenuItem>
            {selectorOptions.map((opt) => (
              <MenuItem value={opt[0]} key={opt[0]}>
                {opt[1]}
              </MenuItem>
            ))}
          </FilledSelect>
        )}
      </div>
    );
  } else {
    if (!status) {
      return "-";
    }
    return (
      <Tooltip
        title={chipLabel}
        enterDelay={500}
        enterNextDelay={500}
        {...tooltipProps}
      >
        <Chip
          icon={
            <DotIcon
              className={classes.icon}
              style={{color: colors[status]?.color ?? colors.default.color}}
            />
          }
          label={chipLabel}
          size="small"
          classes={{
            root: clsx(classes.root, {xPadd: !!xPadd}),
            labelSmall: classes.labelSmall,
          }}
          style={{
            color: colors[status]?.color ?? colors.default.color,
            backgroundColor: colors[status]?.bg ?? colors.default.bg,
          }}
        />
      </Tooltip>
    );
  }
}
