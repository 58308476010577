import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import FilledTextField from "../../../../core/inputs/FilledTextField";

const DigitField = ({fieldData, handleValueChange, options, ...props}) => {
  const renderInput = () => (
    <div style={{width: "100%", height: "100%"}}>
      <FilledTextField
        fullWidth
        label="Value"
        type="number"
        inputProps={{min: 0, max: 100000}}
        value={fieldData.val}
        onChange={(e) => handleValueChange(fieldData.type, e.target.value)}
      />
    </div>
  );

  const getOperators = () => [
    "is",
    "is_not",
    "is_greater_than",
    "is_less_than",
    "exists",
    "not_exists",
  ];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

DigitField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default DigitField;
