import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {editAccount, setUserProfile} from "redux/actions/accountsActions";
import {InboxContext} from "ui/page/Inbox";
import {newUuid} from "utilities/helperFunctions";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../connectui/component/buildStyles";
// Components
import FlexContainer from "connectui/component/layout/FlexContainer";
import Container from "connectui/component/layout/Container";
import Divider from "connectui/component/display/Divider";
import LightButtonBase from "connectui/component/input/button/base/LightButtonBase";
import ErrorButtonBase from "connectui/component/input/button/base/ErrorButtonBase";
import ButtonBase from "connectui/component/input/button/base/ButtonBase";
import ButtonText from "connectui/component/input/button/ButtonText";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import Text from "connectui/component/display/Text";
import ViewDetailsPanel from "./ViewDetailsPanel";
import ConfirmModal from "connectui/component/modal/ConfirmModal";
import RoundIcon from "connectui/component/display/RoundIcon";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
    actionsRow: {
      width: "100%",
      marginTop: theme.spacing(-4),
      padding: theme.spacing(0, 4, 5),
    },
  });

const SaveViewPanel = ({onClose = () => null}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {appliedFilters} = useContext(InboxContext);
  const [content, setContent] = useState({
    name: "",
    icon: "",
    color: "",
    description: "",
    queries: appliedFilters,
  });
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    type: "",
  });
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  const onSave = () => {
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    let currentViews = user_profile.views ?? [];
    let viewID = newUuid();
    let newView = {...content, id: viewID};

    // Update root object in redux store
    dispatch(
      setUserProfile({...user_profile, views: [...currentViews, newView]}),
    );

    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {...user, added_view: content},
    };
    dispatch(
      editAccount({
        body: {body},
        disableUpdate: true,
        successMsg: "View created correctly",
        errorMsg: "Failed to create view",
        onSuccess: (response) => {
          // TODO: return new view in the response and update ID in redux
          window.history.pushState(
            {},
            "",
            `${window.location.pathname}?view_id=${viewID}`,
          );
        },
        onError: () => {
          dispatch(setUserProfile({...user_profile, views: currentViews}));
        },
      }),
    );
    onClose(true);
  };

  return (
    <>
      <ConfirmModal
        isOpen={confirmModal.open}
        title={confirmModal.type === "save" ? "Save View?" : "Cancel Saving?"}
        description={
          confirmModal.type === "save"
            ? "Are you sure you want to save this view? It will be added to your saved views list."
            : "Are you sure you want to cancel? Your changes will not be saved."
        }
        actions={
          <FlexContainer gap={theme.spacing(3)} sx={{flex: 1}}>
            <ButtonText
              buttonBase={OutlinedButtonBase}
              weight="semibold"
              color="gray700"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={confirmModal.type === "save" ? "Cancel" : "Go Back"}
              onClick={() => setConfirmModal({open: false, type: ""})}
            />
            <ButtonText
              buttonBase={
                confirmModal.type === "save" ? ButtonBase : ErrorButtonBase
              }
              weight="semibold"
              color="white"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={confirmModal.type === "save" ? "Save" : "Discard Changes"}
              onClick={confirmModal.type === "save" ? onSave : onClose}
            />
          </FlexContainer>
        }
        icon={
          <RoundIcon
            iconComponent={
              confirmModal.type === "save" ? "check-circle" : "alert-triangle"
            }
            baseColor={confirmModal.type === "save" ? "success" : "warning"}
          />
        }
      />
      <FlexContainer
        className={classes.container}
        flexDirection="column"
        gap={theme.spacing(4)}
      >
        <FlexContainer
          className={classes.header}
          gap={theme.spacing(3)}
          alignItems="center"
        >
          <Text variant="displayXS" weight="semibold">
            {t("filter-set-settings-panel-save-header")}
          </Text>
        </FlexContainer>

        <ViewDetailsPanel
          content={content}
          setContent={(field, value) =>
            setContent((prev) => ({...prev, [field]: value}))
          }
        />

        <Container className={classes.actionsRow}>
          <Divider sx={{marginTop: "0px"}} />
          <FlexContainer gap={theme.spacing(3)}>
            <ButtonText
              sx={{flex: 1}}
              typographyVariant="textSM"
              weight="semibold"
              color="white"
              label="Save"
              disabled={!content.name || !content.color || !content.icon}
              onClick={() => setConfirmModal({open: true, type: "save"})}
            />
            <ButtonText
              buttonBase={LightButtonBase}
              sx={{flex: 1}}
              typographyVariant="textSM"
              weight="semibold"
              color="primary"
              label="Close"
              onClick={() => setConfirmModal({open: true, type: "cancel"})}
            />
          </FlexContainer>
        </Container>
      </FlexContainer>
    </>
  );
};

export default SaveViewPanel;
