import React from "react";
import { Button } from "@material-ui/core";
import _ from "lodash";
import { userTypes } from "configuration/constants";
import { THEME } from "configuration/settings";

const UserScopeTag = ({ user }) => {
  // 1) Determine the userType label.
  let userType = "Custom";
  for (const key in userTypes) {
    if (Object.hasOwn(userTypes, key)) {
      const item = userTypes[key];
      if (_.isEqual(user.scopes, item.scopes)) {
        userType = item.displayName;
        break;
      }
    }
  }

  // 2) Collect code data.
  const totalCodes = user?.access_codes?.length || 0;
  const unsetCodes = (user?.access_codes || []).filter(c => c.status !== "set");
  const totalUnset = unsetCodes.length;

  // 3) Determine if all unset codes are "Device Offline".
  const allUnsetOffline =
    totalUnset > 0 && unsetCodes.every(c => c?.error_message?.startsWith("Device Offline"));

  // 4) Decide the color and label text based on conditions.
  let color = "rgb(18, 183, 106, 100%)";          // default green
  let backgroundColor = "rgb(18, 183, 106, 10%)";
  let labelText = `Access to ${totalCodes} Devices`;

  if (totalUnset > 0) {
    if (allUnsetOffline) {
      // Yellow state: All unset codes are offline.
      color = "rgb(224, 220, 0, 100%)";          // yellow-ish
      backgroundColor = "rgb(224, 220, 0, 10%)";
      labelText += ` (${totalUnset} Offline)`;
    } else {
      // Red state: Some unset codes are actual errors (not purely offline).
      color = "rgb(240, 68, 56, 100%)";           // red
      backgroundColor = "rgb(240, 68, 56, 10%)";
      labelText += ` (${totalUnset} errors)`;
    }
  }

  return (
    <>
      {/* Primary tag for user type */}
      <Button
        variant="outlined"
        color="primary"
        style={{
          border: "none",
          color: "rgb(13, 86, 140, 100%)",
          backgroundColor: "rgb(13, 86, 140, 10%)",
          borderRadius: "5px",
          padding: `${THEME.spacing.xs}px ${THEME.spacing.sm}px`,
          margin: `0 ${THEME.spacing.sm}px`,
          fontSize: "12px",
        }}
      >
        {userType}
      </Button>

      {/* Secondary tag for access code status */}
      {totalCodes > 0 && (
        <Button
          variant="outlined"
          style={{
            border: "none",
            color,
            backgroundColor,
            borderRadius: "5px",
            padding: `${THEME.spacing.xs}px ${THEME.spacing.sm}px`,
            marginLeft: THEME.spacing.sm,
            fontSize: "12px",
          }}
        >
          {labelText}
        </Button>
      )}
    </>
  );
};

export default UserScopeTag;
