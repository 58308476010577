import React from "react";
import ScrollablePage from "./ScrollablePage";
import {Box, Typography, makeStyles} from "@material-ui/core";
import HTMLContent from "./HTMLContent";
import SectionHeaderCard from "./SectionHeaderCard";
import defaultBGImg from "assets/img/article_placeholder_image.webp";
import GuidebookCard from "./GuidebookComponents";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
    "& .ql-editor": {padding: 0},
    "& div.ql-editor img": {maxWidth: "100%"},
    "& img": {maxWidth: "100%"},
  },
  simpleHeadercontainer: {
    width: "100%",
    maxWidth: 800,
    paddingTop: theme.spacing(7.5),
  },
  emptyText: {opacity: 0.5},
}));

export default function GuidebookPage({guidebookId, components, closePage}) {
  const classes = useStyles();
  let gb = components?.guidebooks?.find((g) => g.id === guidebookId) ?? null;

  const header = !!gb?.header_image ? (
    <SectionHeaderCard
      disableGrayscale
      placeholder="Name"
      title={gb?.name ?? ""}
      img={gb?.header_image ?? defaultBGImg}
    />
  ) : (
    <div className={classes.simpleHeadercontainer}>
      <Box px={2.5}>
        {!!gb?.name ? (
          <Typography variant="h3">{gb?.name}</Typography>
        ) : (
          <Typography variant="h3" className={classes.emptyText}>
            {"Name"}
          </Typography>
        )}
      </Box>
    </div>
  );

  const guidebookContent =
    gb?.components?.length > 0 ? (
      <div className={classes.content}>
        <GuidebookCard components={gb?.components || []} />
      </div>
    ) : (
      <div className={classes.content}>
        {!!gb?.text ? (
          <HTMLContent v={gb.text} />
        ) : (
          <Typography className={classes.emptyText}>{"Content"}</Typography>
        )}
      </div>
    );

  return (
    <ScrollablePage
      disableActions
      onClose={closePage}
      header={header}
      pageContent={guidebookContent}
    />
  );
}
