import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import FilledTextField from "../../../../core/inputs/FilledTextField";

const TextField = ({fieldData, handleValueChange, options, ...props}) => {
  const renderInput = () => (
    <FilledTextField
      fullWidth
      label="Value"
      value={fieldData.val}
      type={"text"}
      placeholder={"Enter value"}
      onChange={(newValue) => {
        handleValueChange(fieldData.type, newValue.target.value);
      }}
    />
  );

  const getOperators = () => ["is", "is_not", "exists", "not_exists"];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

TextField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default TextField;
