import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {generateStyles} from "connectui/theme/styleUtilities";
import InputBase from "./InputBase";
import PropTypes from "prop-types";

/**
 * OutlinedInputBase is a variant of the InputBase component that renders an input field with an outlined style.
 * It supports additional customization through the `sx` prop.
 *
 * @param {Object} props - The props for the OutlinedInputBase component.
 * @param {Object} [props.inputProps] - The properties to pass to the `<input>` element.
 * @param {Object} [props.sx={}] - The style object that can be used to override default outlined input styles.
 * @param {Object} props.rest - Any other props that should be passed to the InputBase component.
 *
 * @returns {JSX.Element} The rendered OutlinedInputBase component.
 */
const OutlinedInputBase = ({inputProps, sx = {}, ...props}) => {
  const {theme} = useTheme();
  const defaultStyles = {
    border: `1px solid ${theme.colors.gray300}`,
    borderRadius: `${theme.radius.lg}px`,
    boxShadow: theme.shadows.default,
    padding: theme.spacing(2.5, 3.5),
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return <InputBase sx={styles} inputProps={inputProps} {...props} />;
};

OutlinedInputBase.propTypes = {
  inputProps: PropTypes.object,
  sx: PropTypes.any,
};

export default OutlinedInputBase;
