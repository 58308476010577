import React, {useEffect, useRef} from "react";
import ListComponent from "../../list/ListComponent";
import Container from "../../layout/Container";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import ContextMenuItem from "./ContextMenuItem";

const useStyles = (theme) =>
  buildStyles({
    contextMenu: {
      position: "absolute",
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows.md,
      borderRadius: `${theme.radius.lg}px`,
      padding: theme.spacing(1, 0),
      zIndex: theme.zIndex.dropdown || 1000,
      border: `1px solid ${theme.colors.gray100}`,
    },
  });

/**
 * ContextMenu is a component that renders a context menu at a specific position with a list of menu items.
 * It listens for clicks outside the menu to close it and supports custom components for each menu item.
 *
 * @param {Object} props - The props for the ContextMenu component.
 * @param {Array} [props.menuItems=[]] - The list of menu items to display. Each item can be a custom component or a default ContextMenuItem.
 * @param {Function} props.onClose - The function to call when the context menu is closed.
 * @param {Object} [props.anchorPosition={top: 0, left: 0}] - The position at which to display the context menu (top and left).
 *
 * @returns {JSX.Element} The rendered ContextMenu component.
 */
const ContextMenu = ({
  menuItems = [],
  onClose,
  anchorPosition = {top: 0, left: 0},
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  return (
    <Container
      ref={menuRef}
      className={classes.contextMenu}
      sx={{top: anchorPosition.top, left: anchorPosition.left}}
    >
      <ListComponent>
        {menuItems.map((item, index) => {
          const {Component = null, ...itemProps} = item;
          return Component ? (
            <Component key={index} {...itemProps} />
          ) : (
            <ContextMenuItem key={index} {...itemProps} />
          );
        })}
      </ListComponent>
    </Container>
  );
};

export default ContextMenu;
