import React, {useState} from "react";
import {Redirect} from "react-router";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"; // Custom
import ListingContentDetailsPanel from "components/Panels/ListingContent/ListingContentDetailsPanel";
import GJEDetailsPanel from "components/Panels/ListingContent/GJEDetailsPanel";
import ListingGroupList from "components/Lists/ListingGroupList";
import ThreePanel from "core/panels/ThreePanel";
import ListingDetailsPanel from "components/Panels/Listing/ListingDetailsPanel";
import GJEPanel from "components/Panels/GJE/GJEPanel";
import FullLoader from "components/Dialogs/FullLoader";
import ListingGroupModal from "components/Dialogs/ListingGroupModal";
import CTAPanel from "components/Cards/CTAPanel"; // Actions
import {setSelectedViewItem} from "redux/actions/accountsActions";
import {getHouseContent} from "redux/actions/listingsActions"; // Helpers & Utilities
import {experienceDefaultTriggers} from "configuration/enums";
import usePrevious from "hooks/usePrevious";
import {format} from "date-fns";
import TestingPage from "./TestingPage";

const ctaPaths = ["/admin/listings", "/admin/listings/"];

export default function Houses() {
  const now = new Date();
  const didLoad = React.useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const matchGroupId = useRouteMatch("/admin/listings/groups/:group_id");
  const matchListingId = useRouteMatch("/admin/listings/:listing_id");
  const matchListingSection = useRouteMatch(
    "/admin/listings/:listing_id/:listing_section",
  );
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const matchCardSectionItem = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card/:item",
  );
  // Selectors
  const [redirect, setRedirect] = useState(null);
  const configuredExperiences = useSelector(
    (state) => state.experiencesReducer.configured_experiences,
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  // State
  const [tab, setTab] = React.useState(
    selectedViewItems.listings.props.tab ?? 0,
  );
  const [panel3, setPanel3] = React.useState(null);
  const [selectedHouseId, setSelectedHouseId] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedExperience, setSelectedExperience] = React.useState(null);
  const [groupOpen, setGroupOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [missingCardActive, setMissingCardActive] = React.useState(null);
  const [openNewGroupModal, setOpenNewGroupModal] = React.useState(false);
  const [calendarProps, setCalendarProps] = React.useState({
    dialog: {
      open: false,
      title: format(now, "MMM d, Y"),
      view: null,
      dateRange: [now, now],
      data: null,
    },
    startDate: null,
    endDate: null,
    formattedRanges: {
      bookings: [],
      availability: [],
      rates: [],
    },
    useEndDateFocus: false,
  });
  // General
  const prevLocation = usePrevious(location);
  const showCTAPanel = ctaPaths.includes(location.pathname);
  const step = React.useMemo(() => {
    if (matchCardSection?.isExact || matchCardSectionItem?.isExact) {
      if (!panel3) {
        setPanel3((prev) => matchCardSection.params.card);
      }
      return matchCardSection.params.step;
    } else {
      return null;
    }
  }, [location]);
  const {listing_id, listing_section} = React.useMemo(() => {
    if (!!matchGroupId?.isExact) {
      return {
        listing_id: matchListingId.params.listing_id,
        listing_section: null,
      };
    } else if (!!matchListingSection?.isExact) {
      return matchListingSection.params;
    } else if (!!matchListingId?.isExact) {
      return {
        listing_id: matchListingId.params.listing_id,
        listing_section: null,
      };
    } else {
      return {listing_id: null, listing_section: null};
    }
  }, [location]);
  const listingContentReadOnly = user_profile.scopes?.listings !== "write";

  React.useEffect(() => {
    const isDifferentLocation = prevLocation?.pathname !== location.pathname;
    if (isDifferentLocation) {
      if (
        !!matchGroupId?.isExact ||
        !!matchCardSection?.isExact ||
        !!matchCardSectionItem?.isExact
      ) {
        // GJE
        const groupId = matchGroupId.params.group_id;
        saveSelectedItems(groupId, {isGroup: true});
        setSelectedGroup((prev) => groupId);
        setGroupOpen((prev) => groupId);
      } else if (!!matchListingSection?.isExact) {
        // Listing view with listing section open
        if (!didLoad.current && !!listing_id && !!current_user) {
          didLoad.current = true;
          dispatch(getHouseContent(listing_id));
        }
        saveSelectedItems(listing_id, {listingSection: listing_section});
        setGroupOpen(
          (prev) =>
            prev ?? selectedViewItems.listings.props.selectedGroup ?? "ALL",
        );
        setSelectedHouseId((prev) => listing_id);
        setTab((prev) => selectedViewItems.listings.props.tab ?? 0);
        setPanel3((prev) => listing_section);
      } else if (!!matchListingId?.isExact) {
        // Listing view
        saveSelectedItems(listing_id);
        if (!didLoad.current && !!listing_id && !!current_user) {
          didLoad.current = true;
          dispatch(getHouseContent(listing_id));
        }
        setSelectedHouseId((prev) => matchListingId.params.listing_id);
        setGroupOpen(
          (prev) =>
            prev ?? selectedViewItems.listings.props.selectedGroup ?? "ALL",
        );
      }
    } else if (!matchGroupId) {
      // Default listings view on first render
      if (!didLoad.current && !!listing_id && !!current_user) {
        didLoad.current = true;
        dispatch(getHouseContent(listing_id));
      }
    }
  }, [location, listings]);

  React.useEffect(() => {
    if (!!selectedHouseId && !!selectedExperience?.experience_id) {
      const newExperience =
        configuredExperiences.listingId[selectedHouseId][
          selectedExperience.experience_id
        ];
      setSelectedExperience((prev) => newExperience);
    }
  }, [configuredExperiences]);

  const saveSelectedItems = (item = null, props = {}) => {
    let defaultProps = {
      isGroup: false,
      selectedGroup: groupOpen,
      listingSection: null,
      tab: tab,
    };
    dispatch(
      setSelectedViewItem("listings", item, {...defaultProps, ...props}),
    );
  };

  const selectHouse = (house) => {
    let newTab = tab;
    if (selectedViewItems.listings.props.tab !== null) {
      newTab = selectedViewItems.listings.props.tab;
      setTab(newTab);
    }
    closeGroupSettings();
    setSelectedHouseId(house.listing_id);
    saveSelectedItems(house.listing_id, {tab: newTab});
    history.replace(`/admin/listings/${house.listing_id}`);
  };

  const createGroup = () => {
    setOpenNewGroupModal((prev) => true);
  };

  const handleCloseNewGroupModal = (collapseListingGroups) => {
    if (collapseListingGroups) {
      setGroupOpen(null);
    }
    setOpenNewGroupModal((prev) => false);
  };

  const selectGroup = (group) => {
    if (selectedGroup === group.group_id) {
      return;
    }
    setPanel3(null);
    setSelectedHouseId(null);
    setSelectedGroup(group.group_id);
    setMissingCardActive((prev) => null);
    setSelectedExperience((prev) => null);
    saveSelectedItems(group.group_id, {isGroup: true});
    history.replace(`/admin/listings/groups/${group.group_id}`);
  };

  const closeGroupSettings = (collapseListingGroups) => {
    collapseListingGroups && setGroupOpen(null);
    saveSelectedItems(null, {selectedGroup: null});
    history.replace("/admin/listings");
    setMissingCardActive((prev) => null);
    setSelectedGroup(null);
    setPanel3(null);
  };

  const onClickBooking = (bkg) => {
    setPanel3("bk");
  };

  const closeDialog = () => {
    setCalendarProps((prev) => ({
      ...prev,
      dialog: {...prev.dialog, open: false},
    }));
  };

  const openDialog = (title, view, data) => {
    setCalendarProps((prev) => ({
      ...prev,
      dialog: {...prev.dialog, open: true, title, view, data},
    }));
  };

  const handleLoadingDialogClose = () => {
    setLoading((prev) => null);
  };

  const handleCalendarPropChange = (key, value) => {
    setCalendarProps((prev) => ({...prev, [key]: value}));
    if (["startDate", "endDate"].includes(key) && !!value) {
      setPanel3((prev) => "cd");
    }
  };

  const handleExperienceClose = (redirectionNeeded) => {
    setSelectedExperience((prev) => null);
    closeDetailsPanel(redirectionNeeded);
  };

  const closeDetailsPanel = (redirectionNeeded) => {
    setPanel3(null);
    if (!!redirectionNeeded) {
      saveSelectedItems(selectedGroup, {isGroup: true});
      history.replace(`/admin/listings/groups/${selectedGroup}`);
    } else if (isTabletView && !!listing_id) {
      saveSelectedItems(listing_id, {tab: 0});
      history.replace(`/admin/listings/${listing_id}`);
    }
  };

  const createExperience = (experience, isListingView) => {
    if (!experience) {
      const newExp = {
        connected_to: selectedGroup,
        actions: [{actions: null, trigger: "cin"}],
        enabled: false,
        name: "",
      };
      if (!isListingView) {
        newExp.experience_type = step;
        newExp.actions = [
          {
            actions: null,
            trigger: experienceDefaultTriggers[step].trigger,
            time_offset: !!experienceDefaultTriggers[step].time_offset
              ? experienceDefaultTriggers[step].time_offset / 1000
              : 0,
          },
        ];
        history.replace(
          `/admin/listings/groups/${selectedGroup}/${step}/experiences/config_exp`,
        );
      }
      setSelectedExperience(newExp);
    } else {
      const newExperience = {...experience};
      delete newExperience.id;
      delete newExperience.experience_id;
      setSelectedExperience(newExperience);
    }
    setPanel3((prev) => "config_exp");
  };

  const loadingModal = !!loading && (
    <FullLoader
      open
      disableDismiss
      onClose={handleLoadingDialogClose}
      loadingText={loading}
    />
  );

  const panel_1 = (
    <ListingGroupList
      useGroups
      groupId={selectedGroup}
      detailsPanelClosed={location.pathname === "/admin/listings"}
      groupOpen={groupOpen}
      disableContentEdit={listingContentReadOnly}
      setGroupOpen={setGroupOpen}
      selectHouse={selectHouse}
      selectGroup={selectGroup}
      selected={selectedHouseId}
      createListing={() => setRedirect(true)}
      createGroup={createGroup}
    />
  );

  function getPanel2() {
    if (location.pathname === "/admin/listings") {
      return null;
    }
    return (
      <Switch>
        <Route path="/admin/listings/groups/:group_id">
          <GJEPanel
            missingCardActive={missingCardActive}
            closeGroup={closeGroupSettings}
            selectExperience={setSelectedExperience}
            saveSelectedItems={saveSelectedItems}
          />
        </Route>
        <Route path="/admin/listings/:listing_id">
          <ListingDetailsPanel
            tab={tab}
            panel3={panel3}
            hideListingLink
            disableEdit={listingContentReadOnly}
            onClose={() => {
              saveSelectedItems();
              history.replace(`/admin/listings`);
              setSelectedHouseId(null);
            }}
            listingId={selectedHouseId}
            calendarProps={calendarProps}
            selectedExperience={selectedExperience}
            setTab={setTab}
            setView={setPanel3}
            onBkgSelect={onClickBooking}
            closeCalendarDialog={closeDialog}
            saveSelectedItems={saveSelectedItems}
            selectExperience={setSelectedExperience}
            onCalendarPropChange={handleCalendarPropChange}
          />
        </Route>
      </Switch>
    );
  }

  function getPanel3() {
    if (
      (!listing_section && !panel3) ||
      location.pathname === "/admin/listings"
    ) {
      return null;
    }
    return (
      <Switch>
        <Route
          path={[
            "/admin/listings/groups/:group_id/:step/:card",
            "/admin/listings/groups/:group_id/:step/:card/:item",
          ]}
        >
          <GJEDetailsPanel
            isLive={
              !!listingGroups[selectedGroup]?.guest_journey?.enabled ?? true
            }
            groupId={selectedGroup}
            selectedExperience={selectedExperience}
            setLoading={setLoading}
            createExperience={createExperience}
            closeDetailsPanel={closeDetailsPanel}
            selectExperience={setSelectedExperience}
            goBack={isTabletView ? closeDetailsPanel : null}
            onExperienceClose={() => handleExperienceClose(true)}
          />
        </Route>
        <Route path="/admin/listings/:listing_id/:listing_section">
          <ListingContentDetailsPanel
            contentId={listing_section}
            listingId={selectedHouseId}
            calendarProps={calendarProps}
            disableEdit={listingContentReadOnly}
            openDialog={openDialog}
            setContentId={setPanel3}
            goBack={isTabletView ? closeDetailsPanel : null}
            onCalendarPropChange={handleCalendarPropChange}
          />
        </Route>
        <Route path="/admin/listings/:listing_id">
          <ListingContentDetailsPanel
            contentId={panel3}
            listingId={selectedHouseId}
            selectedExperience={selectedExperience}
            disableEdit={listingContentReadOnly}
            setContentId={setPanel3}
            selectExperience={setSelectedExperience}
            onExperienceClose={handleExperienceClose}
          />
        </Route>
      </Switch>
    );
  }

  return redirect ? (
    <Redirect to="/admin/createhouse" />
  ) : (
    <>
      {loadingModal}
      <ListingGroupModal
        open={openNewGroupModal}
        loading={loading}
        onClose={handleCloseNewGroupModal}
        setLoading={setLoading}
      />
      <ThreePanel
        panel1={panel_1}
        panel2={getPanel2()}
        panel3={getPanel3()}
        forcePanel1FullWidth={showCTAPanel}
        usefitWidth={showCTAPanel}
        tabletPanel3Open={!!listing_section || !!panel3}
      />
      {showCTAPanel && <CTAPanel setCreateListingGroupOpen={createGroup} />}
    </>
  );
}
