import React from "react";
import Container from "../../layout/Container";
import {useTheme} from "../../../theme/ThemeProvider";
import {buildStyles} from "../../buildStyles";
import Text from "connectui/component/display/Text";
import SvgIcon from "connectui/component/display/SvgIcon";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    menuItem: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2.5, 3.5),
      cursor: "pointer",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: theme.colors.gray100,
      },
    },
    menuIcon: {
      marginRight: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      width: "20px",
      height: "20px",
    },
  });

/**
 * ContextMenuItem is a component that renders an item within a context menu.
 * It displays an optional icon next to the label and supports a click handler.
 *
 * @param {Object} props - The props for the ContextMenuItem component.
 * @param {React.object} [props.icon] - The icon to display next to the label.
 * @param {React.object} [props.iconProps] - The props for the icon component.
 * @param {string|React.ReactNode} props.label - The label text to display inside the menu item.
 * @param {Function} props.onClick - The function to call when the menu item is clicked.
 *
 * @returns {JSX.Element} The rendered ContextMenuItem component.
 */
const ContextMenuItem = ({icon = null, iconProps = {}, label, onClick}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <Container className={classes.menuItem} onClick={onClick}>
      {icon && (
        <SvgIcon component={icon} className={classes.menuIcon} {...iconProps} />
      )}
      <Text as="div" weight="medium">
        {label}
      </Text>
    </Container>
  );
};

ContextMenuItem.propTypes = {
  icon: PropTypes.object,
  iconProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContextMenuItem;
