import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {withLDConsumer} from "launchdarkly-react-client-sdk";
import {Redirect} from "react-router-dom";
// UI
import {Box, Divider, SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as CredentialIcon} from "assets/icons/credential.svg";
import {ReactComponent as PermissionsIcon} from "assets/icons/check-square.svg";
import {ReactComponent as HomeIcon} from "assets/icons/home.svg";
// Custom
import Conditions from "components/Panels/Experience/ExperienceContent/Conditions/Conditions";
import CustomCardHeader from "core/cards/CustomCardHeader";
import CollectDetailsPanel from "./CollectDetailsPanel";
import CardItem from "core/listItems/CardItem";
import CheckinSettingsPanel from "components/Panels/BP/CheckinSettingsPanel";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import UpdateSession from "components/Dialogs/UpdateSession";
import {LoadingVerificationSettings} from "components/Helpers/EmptyPanels";
import VerificationPanel from "./VerificationPanel";
import AgreementPanel from "./AgreementPanel";
import {icons} from "core/buttons/ConnectedToButton";
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import CloseIconButton from "core/buttons/CloseIconButton";
import TemplateCard from "components/Cards/TemplateCard";
import MultiButtonBar from "core/buttons/MultiButtonBar";
import GJEInfoCard from "components/Cards/GJEInfoCard";
import ServiceInfoDialog from "components/Dialogs/ServiceInfoContent";
import CreateFromExistingList from "components/Lists/CreateFromExistingList";
import CreationButton from "components/MultiOption/CreationButton";
import CustomDialog from "core/dialogs/CustomDialog";
// Actions
import {setServiceAccounts, updateService} from "redux/actions/servicesActions";
import {
  editListingGroup,
  getListingGroupDetails,
} from "redux/actions/listingsActions";
import {
  saveCollectDetails,
  saveExperience,
  setLoadingVerificationSettings,
  updateExperience,
  deleteExperience,
} from "redux/actions/experiencesActions";
// Utils
import {asyncLoop, isServiceEnabledFor, sleep} from "utilities/helperFunctions";
import {invalidPaymentPlans} from "configuration/constants";
import {defaultKeycard} from "configuration/specs";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
    gap: theme.spacing(3),
    "&.pt0": {paddingTop: 0},
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: "#F0F0F0",
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    "& > .icon": {
      width: 16,
      height: 16,
    },
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  icon: {
    height: 16,
    width: 16,
  },
}));

const defaultCollectionFields = [
  {
    field: "guest_first_name",
    required: true,
    field_type: "text",
    field_category: "text",
    field_options: [],
  },
  {
    field: "guest_last_name",
    required: true,
    field_type: "text",
    field_category: "text",
    field_options: [],
  },
  {
    field: "guest_email",
    required: true,
    field_type: "text",
    field_category: "email",
    field_options: [],
  },
  {
    field: "guest_phone",
    required: false,
    field_type: "text",
    field_category: "phone",
    field_options: [],
  },
];

const emptyIdUpload = {
  id_upload: {selfie: false, govt_id_back: false, govt_id_front: false},
};
const defaultVerificationSettings = {
  id_upload: {selfie: true, govt_id_back: true, govt_id_front: true},
  verification_service: null,
};

const defaultBP = {
  ...defaultKeycard,
  actions: [
    {
      actions: [
        {
          ...defaultKeycard.actions[0].actions[0],
          restrict_checkin: 30,
          id_upload: defaultVerificationSettings.id_upload,
        },
      ],
      trigger: "key",
      conditions: [],
    },
  ],
};

const BoardingPassPanel = ({
  onClose,
  disableEdit,
  setLoading,
  flags,
  updateGJ,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const detailsMounted = React.useRef(false);
  const agreementMounted = React.useRef(false);
  const verificationMounted = React.useRef(false);
  const templatesAdded = React.useRef([]);
  const save = React.useRef(false);
  const saveConnection = React.useRef(false);
  const localEnterDetails = React.useRef(null);
  const awaitingServiceActivation = React.useRef(false);
  const isSelectingfromExisting = React.useRef(false);
  const isUpdatingExperience = React.useRef(false);
  const isChangingConnectedTo = React.useRef(false);
  const itemToDelete = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const matchItemCard = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card/:item",
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const availableExperiences = useSelector(
    (state) => state.experiencesReducer.available_experiences,
  );
  const loadingState = useSelector((state) => state.defaultReducer.loading);
  const verificationSettings = useSelector(
    (state) => state.experiencesReducer.verification_settings,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  // General
  const loadingTemplates = loadingState.experience_presets;
  const loadingContent =
    loadingState.listing_groups || loadingState.listing_group_details;
  const {groupId, section, card, item} = React.useMemo(() => {
    if (!matchItemCard?.isExact && !matchCardSection?.isExact) {
      return {groupId: null, section: null, card: null, item: null};
    } else {
      return {
        groupId: matchCardSection.params.group_id,
        section: matchCardSection.params.step,
        card: matchCardSection.params.card,
        item: matchItemCard?.params?.item ?? "new",
      };
    }
  }, [location]);
  const isNew = item === "new";
  // State
  const [updateSession, setUpdateSession] = React.useState(null);
  const [keycardData, setKeycardData] = React.useState({});
  const [connectedTo, setConnectedTo] = React.useState(null);
  const [verificationType, setVerificationType] = React.useState("none");
  const [hideCheckinDirections, setHideCheckinDirections] =
    React.useState(true);
  const [hideCheckoutDirections, setHideCheckoutDirections] =
    React.useState(true);
  const [hideVerification, setHideVerification] = React.useState(true);
  const [hideCodes, setHideCodes] = React.useState(true);
  const [hideAddress, setHideAddress] = React.useState(true);
  const [showPreview, setShowPreview] = React.useState(
    isNew && !!flags.verificationSettings,
  );
  const [expanded, setExpanded] = React.useState(null);
  const [isDeletingKC, setIsDeletingKC] = React.useState(false);
  const [loadingBoardingPass, setLoadingBoardingPass] = React.useState(false);
  const [isSavingTemplates, setIsSavingTemplates] = React.useState(0);
  const [selectedService, setSelectedService] = React.useState(null);
  const [allowRedirect, setAllowRedirect] = React.useState(false);
  const [collectedInfo, setCollectedInfo] = React.useState(
    defaultCollectionFields,
  );
  const [openModal, setOpenModal] = React.useState(false);
  const [existingKC, setExistingKC] = React.useState(null);
  const [loadingSubscription, setLoadingSubscription] = React.useState(false);
  // General
  const selectedGroup = listingGroups[groupId];
  const guestJourney = selectedGroup?.guest_journey ?? {};
  let verificationSettingsData =
    guestJourney.sections?.[section]?.cards[card]?.data ?? [];
  let itemIndex = isNew
    ? -1
    : verificationSettingsData.findIndex((vsd) =>
        vsd.experience_id.includes(item),
      );
  const keycard = isNew
    ? (existingKC ?? defaultBP)
    : (verificationSettingsData[itemIndex] ?? defaultBP);
  const initialVerificationService =
    keycard.actions[0]?.actions[0]?.verification_service;
  const data = React.useMemo(
    () => keycardData?.actions?.[0],
    [keycardData, location],
  );

  React.useEffect(() => {
    if (loadingContent) {
      setLoadingBoardingPass(true);
      return;
    } else {
      setLoadingBoardingPass(false);
    }
    const newKeycardData = {...keycard.actions[0]};
    if (initialVerificationService === "enso") {
      setVerificationType((prev) => "manual");
    } else if (!!initialVerificationService) {
      newKeycardData.actions[0].id_upload =
        defaultVerificationSettings.id_upload;
      setVerificationType((prev) => "automated");
    } else {
      newKeycardData.actions[0].id_upload =
        defaultVerificationSettings.id_upload;
      setVerificationType((prev) => "none");
    }
    setKeycardData((prev) => newKeycardData);
    if (flags.verificationSettings && !isNew && !!showPreview) {
      setShowPreview((prev) => false);
    }
    setConnectedTo(isNew ? groupId : keycard.connected_to || groupId);
    setHideAddress(
      (prev) =>
        newKeycardData.actions[0].restrict_address != null &&
        newKeycardData.actions[0].restrict_address !== -1,
    );
    setHideVerification(
      (prev) =>
        newKeycardData.actions[0].restrict_verification != null &&
        newKeycardData.actions[0].restrict_verification !== -1,
    );
    setHideCheckinDirections(
      (prev) =>
        newKeycardData.actions[0].restrict_checkin != null &&
        newKeycardData.actions[0].restrict_checkin !== -1,
    );
    setHideCheckoutDirections(
      (prev) =>
        newKeycardData.actions[0].restrict_checkout != null &&
        newKeycardData.actions[0].restrict_checkout !== -1,
    );
    setHideCodes(
      (prev) =>
        newKeycardData.actions[0].restrict_codes != null &&
        newKeycardData.actions[0].restrict_codes !== -1,
    );
    detailsMounted.current = false;
    agreementMounted.current = false;
    verificationMounted.current = false;
  }, [location, loadingContent]);

  React.useEffect(() => {
    let newCollectedInfo =
      localEnterDetails.current ??
      keycard?.actions?.[0]?.actions?.[0]?.enter_details ??
      defaultCollectionFields;
    setCollectedInfo((prev) => newCollectedInfo);
    if (!!localEnterDetails.current) {
      localEnterDetails.current = null;
    }
  }, [location]);

  React.useEffect(() => {
    let totalExperiences = isSavingTemplates;
    dispatch(
      setLoadingVerificationSettings(!!isSavingTemplates, totalExperiences),
    );
  }, [isSavingTemplates]);

  React.useEffect(() => {
    if (!!save.current) {
      save.current = false;
      handleSave();
    }
  }, [keycardData]);

  React.useEffect(() => {
    if (!!saveConnection.current) {
      saveConnection.current = false;
      handleSave();
    }
  }, [connectedTo]);

  const addTemplates = (currTemplates) => {
    const newGroup = selectedGroup;
    const newVerifSettings =
      newGroup.guest_journey.sections[section].cards[card].data || [];
    newVerifSettings.push(...currTemplates);
    newGroup.guest_journey.sections[section].cards[card].data =
      newVerifSettings;
    newGroup.guest_journey.sections[section].cards[card].setup_label = null;
    dispatch(editListingGroup(newGroup));
  };

  const updateContentFromResponse = (response, disableClose) => {
    dispatch(
      getListingGroupDetails({
        groupId,
        section,
        card,
        onSuccess: (res) => {
          setIsSavingTemplates((prev) => 0);
          if (disableClose) {
            setLoadingBoardingPass((prev) => false);
          }
          dispatch(setLoadingVerificationSettings(false, 0));
          if (item === "new" && !!response.experience.experience_id) {
            let newExpID = response.experience.experience_id.split("#").at(-1);
            history.replace(
              `/admin/listings/groups/${groupId}/${section}/${card}/${newExpID}`,
            );
          }
        },
      }),
    );
  };

  const getUpdatedContent = (useActions) => {
    if (!keycardData.actions) {
      return null;
    }
    const newActionData = {...keycardData.actions[0]};
    let newCollectedInfo = localEnterDetails.current ?? collectedInfo;
    newActionData.enter_details = newCollectedInfo.map((cif) => ({
      field_type: cif.field_type,
      field: cif.field,
      required: !!cif.required,
      field_options: cif.field_options ?? [],
    }));

    if (verificationType === "none") {
      newActionData.verification_service =
        defaultVerificationSettings.verification_service;
      newActionData.id_upload = emptyIdUpload.id_upload;
    } else if (verificationType === "manual") {
      newActionData.verification_service = "enso";
    } else {
      newActionData.id_upload = emptyIdUpload.id_upload;
    }

    if (!hideCheckinDirections) {
      newActionData.restrict_checkin = -1;
    }
    if (!hideCheckoutDirections) {
      newActionData.restrict_checkout = -1;
    }
    if (!hideVerification) {
      newActionData.restrict_verification = -1;
    }
    if (!hideAddress) {
      newActionData.restrict_address = -1;
    }
    if (!hideCodes) {
      newActionData.restrict_codes = -1;
    }

    let newContent = {
      ...keycard,
      actions: [{...keycardData, actions: [newActionData]}],
    };
    return useActions ? newActionData : newContent;
  };

  const handleSave = (isCreateFromScratch = false) => {
    setLoadingBoardingPass(true);
    handleSaveKeycard({
      newData: getUpdatedContent(),
      isTemplate: isCreateFromScratch,
      templateProps: isCreateFromScratch ? {total: 1, current: 1} : null,
      disableClose: isCreateFromScratch,
    });
  };

  const deleteElement = (item) => {
    if (!!item.experience_id) {
      itemToDelete.current = item.experience_id;
    }
    setIsDeletingKC((prev) => true);
    dispatch(
      deleteExperience({
        experienceId: item.experience_id ?? itemToDelete.current,
        updateId: updateSession?.update_id,
        onUpdate: (response) => {
          setUpdateSession(response.update_session);
        },
        onSuccess: (response) => {
          setUpdateSession(null);
          updateGJ(() => {
            setLoadingBoardingPass((prev) => false);
          });
        },
        onError: () => {
          setIsDeletingKC((prev) => true);
        },
      }),
    );
  };

  const handleSaveKeycard = ({
    newData,
    isTemplate,
    templateProps,
    disableClose,
  }) => {
    let body = {};
    if (newData.saveUpdate) {
      body = {update_id: updateSession.update_id};
      setLoading("Saving changes...");
    } else {
      body = {
        experience: {
          ...newData,
          skip_update_session: !!isTemplate,
          connected_to:
            isTemplate || isSelectingfromExisting.current
              ? groupId
              : connectedTo,
        },
        experience_id: newData.experience_id,
      };
    }

    if (!isTemplate) {
      const newGroup = selectedGroup;
      newGroup.guest_journey.sections[section].cards[card].data[itemIndex] =
        body.experience;
      dispatch(editListingGroup(newGroup));
    }
    isSelectingfromExisting.current = false;

    let actionProps = {
      isBoardingPass: true,
      body: body,
      groupId: groupId,
      disableAlerts:
        isTemplate && templateProps.current !== templateProps.total,
      onUpdates: (response) => {
        setLoadingBoardingPass((prev) => false);
        setUpdateSession(response.update_session);
      },
      onSuccess: (response) => {
        if (isTemplate) {
          if (templateProps.total === templateProps.current) {
            addTemplates([...templatesAdded.current, response.experience]);
            setUpdateSession(null);
            setLoading(false);
            if (!disableClose) {
              onClose();
              setLoadingBoardingPass((prev) => false);
            } else if (!body.experience_id) {
              let newKcId = response.experience.experience_id
                .split("#")
                .slice(-1);
              history.replace(
                `/admin/listings/groups/${groupId}/${section}/${card}/${newKcId}`,
              );
            }
            templatesAdded.current = [];
            updateContentFromResponse(response, disableClose);
          } else {
            templatesAdded.current.push(response.experience);
          }
        } else {
          if (!body.experience_id) {
            updateContentFromResponse(response, true);
            setLoadingBoardingPass((prev) => false);
          } else {
            const newGroup = selectedGroup;
            newGroup.guest_journey.sections[section].cards[card].data[
              itemIndex
            ] = response.experience;
            dispatch(editListingGroup(newGroup));
            setIsSavingTemplates((prev) => 0);
            updateGJ(() => {
              if (isChangingConnectedTo.current) {
                isChangingConnectedTo.current = false;
                setLoadingBoardingPass((prev) => false);
                let newKcId = response.experience.experience_id
                  .split("#")
                  .slice(-1);
                history.replace(
                  `/admin/listings/groups/${groupId}/${section}/${card}/${newKcId}`,
                );
              } else {
                setLoadingBoardingPass((prev) => false);
              }
            });
          }
          setUpdateSession(null);
          setLoading(false);
        }
      },
      onError: () => {
        if (isTemplate) {
          addTemplates(templatesAdded.current);
          templatesAdded.current = [];
        }
        setIsSavingTemplates((prev) => 0);
        setLoading(false);
        handleCloseLoading();
        setLoadingBoardingPass((prev) => false);
        setKeycardData(keycard.actions[0]);
      },
    };

    if (isUpdatingExperience.current) {
      dispatch(updateExperience(actionProps));
    } else {
      dispatch(saveExperience(actionProps));
    }
  };

  const handleSaveTemplate = async (kc) => {
    if (invalidPaymentPlans.includes(user_profile?.plan)) {
      setSelectedService((prev) => true);
    } else {
      setIsSavingTemplates((prev) => kc.experiences.length || 0);
      isUpdatingExperience.current = false;
      await sleep(0).then(() => {});
      await asyncLoop(kc.experiences, async (exp, ind) => {
        let newActionData = {
          ...keycardData.actions[0],
          enter_details: exp.enter_details,
          id_upload: exp.id_upload,
          agreement: exp.agreement,
          show_info_before_verification: exp.show_info_before_verification,
          manual_release_checkin: exp.manual_release_checkin,
          restrict_checkin: exp.restrict_checkin,
          verification_service: exp.verification_service,
        };
        await sleep(0).then(() => {
          handleSaveKeycard({
            newData: {
              ...keycard,
              actions: [
                {
                  ...keycardData,
                  actions: [newActionData],
                  conditions: exp.conditions,
                },
              ],
            },
            isTemplate: true,
            templateProps: {total: kc.experiences.length, current: ind + 1},
          });
        });
      });
    }
  };

  const handleCreateFromScratch = () => {
    isUpdatingExperience.current = false;
    setIsSavingTemplates((prev) => 1);
    handleSave(true);
    setShowPreview((prev) => false);
  };

  const editConditions = (c) => {
    save.current = true;
    isUpdatingExperience.current = true;
    setKeycardData((prev) => ({...prev, conditions: c}));
  };

  const editData = (d, triggerSaving) => {
    save.current = !!triggerSaving;
    isUpdatingExperience.current = true;
    setKeycardData((prev) => ({...prev, actions: [{...data, ...d}]}));
  };

  const handleCollectedInfoChange = (newInfo) => {
    setLoadingBoardingPass((prev) => true);
    const newCollectDetails = {
      fields: newInfo.map((cif) => ({
        field_type: cif.field_type,
        field: cif.field,
        required: !!cif.required,
        field_options: cif.field_options ?? [],
      })),
    };
    setCollectedInfo((prev) => newInfo);
    dispatch(
      saveCollectDetails({
        body: newCollectDetails,
        groupId: groupId,
        onSuccess: () => {
          localEnterDetails.current = newCollectDetails.fields;
          editData({enter_details: newCollectDetails.fields}, true);
        },
        onError: () => {
          setLoadingBoardingPass((prev) => false);
        },
      }),
    );
  };

  const handleHideCheckinDirections = (newVal) => {
    setHideCheckinDirections((prev) => newVal);
  };

  const handleHideCheckoutDirections = (newVal) => {
    setHideCheckoutDirections((prev) => newVal);
  };

  const handleHideVerification = (newVal) => {
    setHideVerification((prev) => newVal);
  };

  const handleServiceEnabled = () => {
    if (awaitingServiceActivation.current) {
      awaitingServiceActivation.current = false;
      subscribe();
    } else {
      setAllowRedirect((prev) => true);
    }
  };

  const handleServiceCancel = () => {
    if (awaitingServiceActivation.current) {
      awaitingServiceActivation.current = false;
      setConnectedTo(connectedTo === "listing_group" ? "ALL" : groupId);
    }
    setSelectedService((prev) => null);
  };

  const handleConnectionChange = (newVal) => {
    isUpdatingExperience.current = true;
    isChangingConnectedTo.current = true;
    if (
      keycardData.actions[0].verification_service === null ||
      keycardData.actions[0].verification_service === "enso"
    ) {
      saveConnection.current = true;
      setConnectedTo(newVal === "listing_group" ? groupId : "ALL");
    } else {
      let verifierEnabled = isServiceEnabledFor({
        verifier: keycardData.actions[0].verification_service,
        targets: [newVal === "all" ? "ALL" : groupId],
        serviceAccounts,
      });
      if (verifierEnabled.enabled) {
        saveConnection.current = true;
      } else {
        awaitingServiceActivation.current = true;
        setSelectedService(verifierEnabled.service);
      }
      setConnectedTo(newVal === "listing_group" ? groupId : "ALL");
    }
  };

  const subscribe = () => {
    setLoadingSubscription((prev) => true);
    dispatch(
      updateService({
        service_id:
          serviceAccounts?.accounts?.[selectedService["Provider"]]?.[0]
            ?.service_id,
        provider: selectedService["Provider"],
        options: {
          verifier: selectedService["Verifier"],
          add_group_ids: [connectedTo],
        },
        onSuccess: (r) => {
          isUpdatingExperience.current = true;
          dispatch(setServiceAccounts(r));
          setLoadingSubscription((prev) => false);
          setSelectedService((prev) => null);
          handleSave();
        },
        onFailure: () => {
          setLoadingSubscription((prev) => false);
        },
      }),
    );
  };

  const handleCloseLoading = () => {
    setIsSavingTemplates((prev) => 0);
  };

  const deleteIDsFromKC = (newKC) => {
    delete newKC.created_at;
    delete newKC.experience_id;
    delete newKC.id;
    delete newKC.actions[0].trigger_id;
    delete newKC.actions[0].id;
    delete newKC.actions[0].actions[0].id;
    if (newKC.actions[0].actions[0].verification_service !== "enso") {
      newKC.actions[0].actions[0].verification_service = null;
    }
    return newKC;
  };

  const selectExistingKeycard = (kc) => {
    save.current = true;
    isSelectingfromExisting.current = true;
    isUpdatingExperience.current = false;
    setIsSavingTemplates((prev) => 1);
    let newKC = deleteIDsFromKC({...kc});
    localEnterDetails.current =
      newKC?.actions?.[0]?.actions?.[0]?.enter_details;
    setExistingKC((prev) => newKC);
    setKeycardData((prev) => ({...newKC.actions[0]}));
    setConnectedTo((prev) => groupId);
    setVerificationType((prev) =>
      !!newKC.actions[0].actions[0].verification_service ? "manual" : "none",
    );
    setShowPreview((prev) => false);
    setOpenModal((prev) => false);
  };

  function getContent() {
    if (showPreview && !!flags.verificationSettings) {
      return (
        <>
          <Box marginX={-4}>
            <GJEInfoCard type="boarding_pass" banner />
          </Box>
          {!!verificationSettings.isLoading && (
            <LoadingVerificationSettings
              total={verificationSettings.totalExp}
            />
          )}
          {verificationSettingsData?.length > 0 && (
            <>
              <Box>
                <Typography variant="h1">
                  {"Active Verification Settings"}
                </Typography>
              </Box>
              {verificationSettingsData.map((ver_item, index) => {
                let kcID = ver_item.experience_id.split("#").slice(-1);
                const actions = [];
                return (
                  <CardItem
                    type={"boarding_pass"}
                    key={ver_item?.experience_id}
                    selected={ver_item.experience_id.includes(item)}
                    disabled={!ver_item.enabled}
                    title={""}
                    descIndex={index}
                    description={
                      selectedGroup?.guest_journey?.sections?.cnf?.cards
                        ?.boarding_pass.description
                    }
                    editAction={() =>
                      history.replace(
                        `/admin/listings/groups/${selectedGroup.group_id}/cnf/boarding_pass/${kcID}`,
                      )
                    }
                    actions={actions}
                    deleteAction={() => deleteElement(ver_item)}
                  />
                );
              })}
              <Box marginBottom={2}></Box>
            </>
          )}
          <Box>
            <Typography variant="h1">
              {"Recommended Verification Packages"}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{gap: THEME.spacing.md}}
          >
            {loadingTemplates ? (
              <EmptyListPanel
                disableGutters
                itemsNumber={4}
                loading
                list="templates"
                noSearchbar
                noTitle
              />
            ) : (
              availableExperiences.key?.map((kc) => (
                <TemplateCard
                  expandable
                  key={kc.id}
                  template={kc}
                  subheader={kc.subtitle}
                  type="keycard"
                  title={kc.name}
                  loading={!!isSavingTemplates}
                  icon={
                    <SvgIcon
                      htmlColor="#111421"
                      component={CredentialIcon}
                      viewBox="0 0 28 17"
                    />
                  }
                  connectedTo={groupId}
                  bgImage={kc.picture_url}
                  expanded={expanded === kc.id}
                  expandItem={() =>
                    setExpanded((prev) => (prev === kc.id ? null : kc.id))
                  }
                  onClick={() => handleSaveTemplate(kc)}
                />
              ))
            )}
          </Box>
        </>
      );
    } else if (!!selectedGroup) {
      return (
        <>
          <Box>
            <div className={classes.sectionTitle}>
              <SvgIcon
                className="icon"
                viewBox="0 0 18 17"
                component={HomeIcon}
              />
              <Typography className="title" variant="h1">
                {"Connected to"}
              </Typography>
            </div>
            <Box mt={1} mb={2}>
              <Typography>
                {
                  "Choose whether to apply these settings to all your listings or your current listing group"
                }
              </Typography>
            </Box>
            <MultiButtonBar
              thin
              dividers
              disabled={disableEdit || loadingBoardingPass}
              value={
                loadingBoardingPass
                  ? null
                  : connectedTo === null
                    ? "all"
                    : connectedTo === "ALL"
                      ? "all"
                      : "listing_group"
              }
              onChange={handleConnectionChange}
              options={["all", "listing_group"]}
              icons={[icons.all, icons.listing_group]}
              labels={["All listings", "Current listing group"]}
            />
          </Box>
          <Divider className={classes.divider} />
          <Box>
            <Conditions
              standalone
              title="Conditions"
              disableBottomPadding={!(keycardData?.conditions || []).length}
              saveBtnProps={{label: "Save", color: "secondary"}}
              disableEdit={disableEdit || loadingBoardingPass}
              label="Define what conditions must be met to use these verification settings"
              data={keycardData?.conditions || []}
              setData={editConditions}
            />
          </Box>
          <Divider className={classes.divider} />
          <Box>
            <CollectDetailsPanel
              group={selectedGroup}
              enter_details={data?.enter_details}
              disableEdit={disableEdit || loadingBoardingPass}
              getUpdatedContent={getUpdatedContent}
              saveCollectedData={handleCollectedInfoChange}
            />
          </Box>
          <Divider className={classes.divider} />
          <Box>
            <VerificationPanel
              disableEdit={disableEdit || loadingBoardingPass}
              id_upload={data?.id_upload}
              group={selectedGroup}
              connectedTo={connectedTo}
              verificationType={verificationType}
              setVerificationType={setVerificationType}
              initialVerificationService={initialVerificationService}
              verification_service={data?.verification_service ?? "unset"}
              editData={editData}
              group_id={groupId}
              getUpdatedContent={getUpdatedContent}
            />
          </Box>
          <Divider className={classes.divider} />
          <AgreementPanel
            disableEdit={disableEdit || loadingBoardingPass}
            mounted={agreementMounted}
            selectedGroup={selectedGroup}
            agreement={data?.agreement}
            editData={editData}
            getUpdatedContent={getUpdatedContent}
            onSave={() => {
              isUpdatingExperience.current = true;
              handleSave();
            }}
          />
          <Divider className={classes.divider} />
          <Box>
            <Box py={1} className={classes.sectionRow}>
              <SvgIcon
                className={classes.icon}
                viewBox="0 0 16 16"
                component={PermissionsIcon}
              />
              <Typography variant="h1">{"Permissions"}</Typography>
            </Box>
            <Box mt={1} mb={2}>
              <Typography>
                Control your guests' access to check-in directions, guidebooks,
                upsells & address
              </Typography>
            </Box>
            <CheckinSettingsPanel
              disableEdit={disableEdit || loadingBoardingPass}
              verificationType={verificationType}
              restrict_checkin={data?.restrict_checkin}
              restrict_checkout={data?.restrict_checkout}
              restrict_verification={data?.restrict_verification}
              restrict_address={data?.restrict_address}
              restrict_codes={data?.restrict_codes}
              show_info_before_verification={
                data?.show_info_before_verification
              }
              hideCheckoutDirections={hideCheckoutDirections}
              setHideCheckoutDirections={handleHideCheckoutDirections}
              hideCheckinDirections={hideCheckinDirections}
              setHideCheckinDirections={handleHideCheckinDirections}
              manual_release_checkin={data?.manual_release_checkin}
              hideAddress={hideAddress}
              hideCodes={hideCodes}
              setHideAddress={setHideAddress}
              editData={editData}
              onSave={() => {
                isUpdatingExperience.current = true;
                handleSave();
              }}
              hideVerification={hideVerification}
              setHideVerification={handleHideVerification}
              setHideCodes={(e) => setHideCodes(e)}
            />
          </Box>
        </>
      );
    } else {
      return null;
    }
  }

  const copyExistingModal = (
    <CustomDialog
      fullWidth
      disableEnforceFocus
      maxWidth="md"
      customHeight="100%"
      disableActions
      open={!!openModal}
      titleVariant="header"
      transitionDuration={0}
      title={"New verification settings"}
      onClose={() => setOpenModal((prev) => false)}
      content={
        <Box height="100%">
          <CreateFromExistingList
            resource="keycard"
            onSelect={selectExistingKeycard}
          />
        </Box>
      }
    />
  );

  const confirmModal = !!updateSession && (
    <ConfirmDialog
      disableDismiss
      open={true}
      onClose={() => setUpdateSession(null)}
      title="Confirm updates?"
      message={
        <>
          {"Updating this verification setting will affect the following:"}
          <UpdateSession updates={updateSession.updates} />
        </>
      }
      confirmLabel="Confirm"
      confirmAction={() => {
        isUpdatingExperience.current = true;
        isDeletingKC
          ? deleteElement({})
          : handleSaveKeycard({newData: {saveUpdate: true}});
      }}
      cancelLabel="Cancel"
      cancelAction={() => setUpdateSession(null)}
    />
  );

  const serviceModal = !!selectedService && (
    <ServiceInfoDialog
      open
      btnLabel={
        !!awaitingServiceActivation.current
          ? `Enable for ${connectedTo === "ALL" ? "all listings" : `${listingGroups[groupId].name} group`}`
          : "Enable"
      }
      loading={loadingSubscription}
      service={selectedService}
      headerIcon={selectedService?.icon_url ?? null}
      dialogBGImage={selectedService?.cover_photo_url}
      handleEnable={handleServiceEnabled}
      onClose={handleServiceCancel}
      dialogBGColors={THEME[selectedService?.["Provider"]] ?? null}
    />
  );

  if (!!allowRedirect) {
    return <Redirect push to="/admin/settings/billing/:redirect" />;
  }
  return (
    <div className={classes.container}>
      {confirmModal}
      {serviceModal}
      {copyExistingModal}
      <CustomCardHeader
        title="Verification settings"
        className={classes.header}
        action={
          <>
            {showPreview && !!flags.verificationSettings && (
              <Box mr={3}>
                <CreationButton
                  type="verification settings"
                  disabled={!!disableEdit}
                  onScratch={handleCreateFromScratch}
                  onExisting={() => setOpenModal((prev) => true)}
                />
              </Box>
            )}
            <CloseIconButton
              sm
              onClick={() => {
                if (!showPreview && !!flags.verificationSettings) {
                  setShowPreview(true);
                  history.push(
                    `/admin/listings/groups/${groupId}/${section}/${card}`,
                  );
                } else {
                  onClose();
                }
              }}
            />
          </>
        }
      />
      <div
        className={clsx(classes.content, {
          pt0: showPreview && !!flags.verificationSettings,
        })}
      >
        {getContent()}
      </div>
    </div>
  );
};

export default withLDConsumer()(BoardingPassPanel);
