import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {getEncodedFilters, newUuid} from "utilities/helperFunctions";
import {getGuests} from "redux/actions/guestsActions";
import {InboxContext} from "ui/page/Inbox";

import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import {ReactComponent as SearchIcon} from "connectui/icons/search.svg";
import {ReactComponent as FilterIcon} from "connectui/icons/filter.svg";

import Text from "connectui/component/display/Text";
import SvgIcon from "connectui/component/display/SvgIcon";
import Container from "connectui/component/layout/Container";
import FlexContainer from "connectui/component/layout/FlexContainer";
import TextInput from "connectui/component/input/text/type/TextInput";
import OutlinedInputBase from "connectui/component/input/text/OutlinedInputBase";
import VirtualizedListComponent from "../../../../connectui/component/list/VirtualizedListComponent";
import ThreadCardComponent from "../threads/ThreadCardComponent";
import ButtonIconText from "../../../../connectui/component/input/button/ButtonIconText";
import OutlinedButtonBase from "../../../../connectui/component/input/button/base/OutlinedButtonBase";
import ChipText from "../../../../connectui/component/display/chip/ChipText";
import ListComponent from "../../../../connectui/component/list/ListComponent";
import Skeleton from "../../../../connectui/component/display/Skeleton";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    contentArea: {
      flex: 1,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.colors.white,
    },
    loadingText: {
      padding: theme.spacing(4),
    },
    icon: {
      width: "20px",
      height: "20px",
      marginRight: theme.spacing(2),
    },
  });

const MessageThreadsPanel = ({
  sx = {},
  openFilters = () => null,
  expandArrow = null,
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const history = useHistory();
  const {guest_id} = useParams();
  const {appliedFilters} = useContext(InboxContext);
  const currentRequest = useRef(null);

  const dispatch = useDispatch();
  const hasError = useSelector(
    (state) => state.defaultReducer.errors.guests.hasError,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const guests = useSelector((state) => state.defaultReducer.guests_dict);

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [hasNextPage, setHasNextPage] = useState(true); // Assume more pages by default
  const [totalCount, setTotalCount] = useState(0); // track guest count idk
  const [loadingGuests, setLoadingGuests] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const viewId = searchParams.get("view_id");
    if (!appliedFilters.length && !!viewId) {
      return;
    }
    loadPage(0);
  }, [appliedFilters]);

  const loadPage = (start) => {
    if (start === 0) {
      setLoadingGuests(true);
    }
    let params = `enso_key=${current_user}&start=${start}`;
    if (!!searchText) {
      params += `&query=${encodeURIComponent(searchText)}`;
    }
    if (!!appliedFilters.length) {
      params += `&filters=${getEncodedFilters(appliedFilters)}`;
    }

    const requestID = newUuid();
    currentRequest.current = requestID;

    dispatch(
      getGuests({
        params,
        add: start > 0,
        onSuccess: (response) => {
          if (requestID !== currentRequest.current) {
            return;
          }
          if (start === 0) {
            setData(response.hits);
            setTotalCount(response.count || response.hits.length);
          } else {
            setData((prev) => prev.concat(response.hits));
          }
          setHasNextPage(!!response.start_key);
          setLoadingGuests(false);
        },
        onError: () => {
          if (requestID !== currentRequest.current) {
            return;
          }
          setHasNextPage(false);
          setLoadingGuests(false);
        },
      }),
    );
  };

  const isRowLoaded = ({index}) => index < data.length;

  const loadMoreRows = ({startIndex, stopIndex}) => {
    if (!hasNextPage || loadingGuests) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      loadPage(data.length);
      resolve();
    });
  };

  const filteredData = data.filter((guest) =>
    guest.name?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const totalRows = hasNextPage ? filteredData.length + 1 : filteredData.length;

  return (
    <Container className={classes.container} sx={sx} {...props}>
      <FlexContainer
        flexDirection="column"
        p={theme.spacing(4)}
        gap={theme.spacing(4)}
      >
        <FlexContainer gap={theme.spacing(3)} alignItems="center">
          {expandArrow}
          <Text variant="displayXS" weight="semibold">
            {t("message-threads-panel-header")}
          </Text>
        </FlexContainer>
        <TextInput
          inputBase={OutlinedInputBase}
          startIcon={
            <SvgIcon
              component={SearchIcon}
              className={classes.icon}
              sx={{color: theme.colors.gray500}}
            />
          }
          inputProps={{
            placeholder: t("search"),
            value: searchText,
            onChange: (e) => {
              setSearchText(e.target.value);
              // If search changes, consider clearing data and reloading:
              setData([]);
              setHasNextPage(true);
              loadPage(0);
            },
          }}
        />
        <FlexContainer gap={theme.spacing(3)} sx={{width: "100%"}}>
          <ButtonIconText
            sx={{flex: 1}}
            buttonBase={OutlinedButtonBase}
            label={
              !!appliedFilters.length ? (
                <FlexContainer gap={theme.spacing(1)} alignItems="center">
                  {"Filters"}
                  <ChipText
                    text={appliedFilters.length}
                    sx={{
                      minWidth: "24px",
                      padding: theme.spacing(0.5, 2),
                      cursor: "pointer",
                      justifyContent: "center",
                      color: theme.colors.primary700,
                      backgroundColor: theme.colors.primary50,
                      "&:hover": {
                        backgroundColor: theme.colors.primary50,
                      },
                    }}
                  />
                </FlexContainer>
              ) : (
                "Filters"
              )
            }
            weight="semibold"
            typographyVariant="textSM"
            onClick={openFilters}
            icon={<SvgIcon component={FilterIcon} className={classes.icon} />}
          />
        </FlexContainer>
      </FlexContainer>

      <Container className={classes.contentArea}>
        {loadingGuests && !hasError && filteredData.length === 0 && (
          <ListComponent gap={"0px"}>
            {Array.from({length: 10}).map((_, index) => (
              <FlexContainer
                key={index}
                gap={theme.spacing(3)}
                sx={{
                  width: "100%",
                  height: "100px",
                  borderBottom: "1px solid #EFEFEF",
                  padding: theme.spacing(4, 2, 2.5, 5),
                }}
              >
                <Skeleton
                  width={"40px"}
                  height={"40px"}
                  style={{borderRadius: "50%"}}
                />
                <FlexContainer flexDirection="column" gap={theme.spacing(2)}>
                  <Skeleton
                    width={"72px"}
                    height={"20px"}
                    style={{borderRadius: `${theme.radius.lg}px`}}
                  />
                  <Skeleton
                    width={"245px"}
                    height={"12px"}
                    style={{borderRadius: `${theme.radius.lg}px`}}
                  />
                </FlexContainer>
              </FlexContainer>
            ))}
          </ListComponent>
        )}
        {hasError && (
          <Text variant="textSM" className={classes.loadingText}>
            {t("error_loading_guests")}
          </Text>
        )}
        {!loadingGuests && !hasError && filteredData.length === 0 && (
          <FlexContainer justifyContent={"center"}>
            <Text variant="textMD" className={classes.loadingText}>
              {t("no_guests_found")}
            </Text>
          </FlexContainer>
        )}
        {!hasError && filteredData.length > 0 && (
          <VirtualizedListComponent
            totalRows={totalRows}
            rowHeight={100}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
          >
            {(index) => {
              const guest =
                guests[filteredData[index]?.guest_id] ?? filteredData[index];
              return (
                <ThreadCardComponent
                  guest={guest}
                  selected={guest?.guest_id === guest_id}
                  onClick={() => {
                    if (guest?.guest_id) {
                      history.push(`/admin/messages/${guest?.guest_id}`);
                    }
                  }}
                  skeleton={{
                    enabled: index >= filteredData.length,
                    width: "100%",
                    height: "111px",
                  }}
                />
              );
            }}
          </VirtualizedListComponent>
        )}
      </Container>
    </Container>
  );
};

export default MessageThreadsPanel;
