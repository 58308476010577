import React from "react";
import {useTheme} from "../../theme/ThemeProvider";
import Container from "../layout/Container";
import {generateStyles} from "../../theme/styleUtilities";
import PropTypes from "prop-types";

/**
 * Text is a component that renders styled text with customizable variant, weight, and color.
 * It allows rendering of different HTML elements via the `as` prop and supports additional styles through `sx`.
 *
 * @param {Object} props - The props for the Text component.
 * @param {string} [props.variant="textMD"] - The typography variant to apply to the text.
 * @param {string} [props.weight="regular"] - The font weight for the text.
 * @param {string} [props.color="textPrimary"] - The color for the text.
 * @param {string} [props.as="p"] - The element type to render (e.g., "p", "span").
 * @param {Object} [props.sx={}] - The style object that can be used to override default text styles.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered Text component.
 */
const Text = ({
  variant = "textMD",
  weight = "regular",
  color = "textPrimary",
  as = "p",
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const themeStyles = theme.typography[variant]?.[weight] || {};
  const defaultStyles = {
    margin: 0,
    color: theme.colors[color] || theme.colors.textPrimary,
    ...themeStyles,
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return <Container as={as} sx={styles} {...props} />;
};

Text.propTypes = {
  variant: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  as: PropTypes.string,
  sx: PropTypes.any,
};

export default Text;
