let styleSheet;

/**
 * Dynamically generates class names and injects them into a stylesheet.
 * @param {object} styles - Object containing style definitions.
 * @returns {object} - Object mapping style keys to generated class names.
 */
export const buildStyles = (styles) => {
  if (!styleSheet) {
    const styleElement = document.createElement("style");
    document.head.appendChild(styleElement);
    styleSheet = styleElement.sheet;
  }

  const classes = {};

  Object.entries(styles).forEach(([key, style]) => {
    if (key.startsWith("@")) {
      if (key.startsWith("@keyframes")) {
        const animationName = `keyframes-${Math.random()
          .toString(36)
          .substr(2, 5)}`;
        const keyframes = Object.entries(style)
          .map(
            ([percentage, properties]) =>
              `${percentage} { ${Object.entries(properties)
                .map(([prop, value]) => `${toKebabCase(prop)}: ${value};`)
                .join(" ")} }`,
          )
          .join(" ");
        try {
          styleSheet.insertRule(
            `@keyframes ${animationName} { ${keyframes} }`,
            styleSheet.cssRules.length,
          );
          classes[key] = animationName;
        } catch (error) {
          console.error("Failed to insert @keyframes rule:", error);
        }
      }
      return;
    }

    const className = `class-${key}-${Math.random().toString(36).substr(2, 5)}`;
    const flatStyle = flattenStyle(style);
    const styleString = Object.entries(flatStyle)
      .filter(([prop]) => !prop.startsWith("&"))
      .map(([prop, value]) => `${toKebabCase(prop)}: ${value};`)
      .join(" ");

    styleSheet.insertRule(
      `.${className} { ${styleString} }`,
      styleSheet.cssRules.length,
    );

    Object.entries(flatStyle)
      .filter(([prop]) => prop.startsWith("&"))
      .forEach(([pseudo, pseudoStyle]) => {
        const pseudoString = Object.entries(pseudoStyle)
          .map(([prop, value]) => `${toKebabCase(prop)}: ${value};`)
          .join(" ");
        styleSheet.insertRule(
          `.${className}${pseudo.slice(1)} { ${pseudoString} }`,
          styleSheet.cssRules.length,
        );
      });

    classes[key] = className;
  });

  return classes;
};

/**
 * Flattens nested style objects.
 * @param {object} style - Nested style object.
 * @returns {object} - Flattened style object.
 */
const flattenStyle = (style) => {
  return Object.keys(style).reduce((acc, key) => {
    if (typeof style[key] === "object" && !Array.isArray(style[key])) {
      acc[key] = flattenStyle(style[key]);
    } else {
      acc[key] = style[key];
    }
    return acc;
  }, {});
};

/**
 * Converts camelCase properties to kebab-case.
 * @param {string} str - camelCase string.
 * @returns {string} - kebab-case string.
 */
const toKebabCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
