import React from "react";
import PropTypes from "prop-types";
import {lighten} from "@material-ui/core/styles";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import BaseFieldComponent from "../BaseFieldComponent";
import {BOOKING_STATUS_COLORS, THEME} from "configuration/settings";
import {bookingStatusLabels} from "configuration/constants";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import Container from "../../../../connectui/component/layout/Container";
import Text from "../../../../connectui/component/display/Text";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";

const bookingOptions = Object.entries(bookingStatusLabels).map(
  ([key, value]) => ({
    key,
    label: value,
  }),
);

const colors = Object.fromEntries(
  Object.entries(BOOKING_STATUS_COLORS).map(([key, color]) => [
    key,
    {color, bg: lighten(color, 0.85)},
  ]),
);

const BookingStatusField = ({fieldData, handleValueChange, ...props}) => {
  const renderInput = () => (
    <SelectChipContainer
      options={bookingOptions.map((opt) => opt.key)}
      selectedOptions={fieldData?.val}
      onChange={(newSelection) => {
        handleValueChange(
          fieldData.type,
          Array.isArray(newSelection)
            ? newSelection.filter((f) => f !== "")
            : newSelection,
        );
      }}
      multiSelect={true}
      customRender={(option) => (
        <Container style={{display: "flex", alignItems: "center"}}>
          <DotIcon
            style={{
              color: colors[option]?.color ?? THEME.primary,
              marginRight: 8,
            }}
          />
          <Text variant="textSM" weight="medium">
            {bookingOptions.find((o) => o.key === option)?.label || option}
          </Text>
        </Container>
      )}
    />
  );

  const getLabel = () => getDefaultFieldLabel(fieldData?.val);

  const getOperators = () => [
    "contains",
    "not_contains",
    "exists",
    "not_exists",
    "is_one_of",
  ];

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

BookingStatusField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
};

export default BookingStatusField;
