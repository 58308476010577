import React, {useEffect, useState} from "react";
import {buildStyles} from "../../buildStyles";
import Container from "../../layout/Container";
import Text from "../Text";
import SvgIcon from "../SvgIcon";
import {ReactComponent as ArrowUpIcon} from "../../../icons/chevron-up.svg";
import {ReactComponent as ArrowDownIcon} from "../../../icons/chevron-down.svg";
import {useTheme} from "../../../theme/ThemeProvider";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    dropdown: {
      borderBottom: `1px solid ${theme.colors.divider}`,
      paddingBottom: theme.spacing(3),
      width: "100%",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    },
    label: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      marginTop: theme.spacing(2),
      display: "none",
    },
    contentVisible: {
      display: "block",
    },
    arrow: {
      width: "10px",
      height: "10px",
      color: theme.colors.gray400,
    },
  });

/**
 * DropdownExpand is a component that renders a dropdown with a label and collapsible content.
 * It toggles the visibility of the content when the header is clicked.
 *
 * @param {Object} props - The props for the DropdownExpand component.
 * @param {string} props.label - The label to display in the dropdown header.
 * @param {React.ReactNode} [props.customLabel] - A custom label component to display in place of the default label.
 * @param {boolean} [props.expanded] - Controls the expanded state of the dropdown.
 * @param {Function} [props.onExpand] - The callback function when expanding/collapsing the dropdown.
 * @param {Function} [props.actions=null] - The content to display in the dropdown header next to the label.
 * @param {React.ReactNode} props.children - The content to display inside the dropdown.
 * @param {Object} [props.sx={}] - The style object that can be used to override default button styles.
 *
 * @returns {JSX.Element} The rendered DropdownExpand component.
 */
const DropdownExpand = ({
  label,
  customLabel,
  expanded,
  onExpand = () => null,
  actions = null,
  children,
  sx = {},
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(!!expanded);
  const {theme} = useTheme();
  const classes = useStyles(theme);

  useEffect(() => {
    if (expanded !== undefined) {
      setIsOpen(expanded);
    }
  }, [expanded]);

  const toggleDropdown = () => {
    onExpand(!isOpen);
    if (expanded === undefined) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Container className={classes.dropdown} sx={sx} {...props}>
      <Container className={classes.header} onClick={toggleDropdown}>
        <Container className={classes.label}>
          {customLabel ? (
            customLabel
          ) : (
            <Text variant="textMD" weight="medium">
              {label}
            </Text>
          )}
          {actions}
        </Container>
        <SvgIcon
          component={isOpen ? ArrowDownIcon : ArrowUpIcon}
          className={classes.arrow}
        />
      </Container>
      <Container
        className={`${classes.content} ${isOpen ? classes.contentVisible : ""}`}
      >
        {children}
      </Container>
    </Container>
  );
};

DropdownExpand.propTypes = {
  label: PropTypes.string,
  customLabel: PropTypes.node,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  actions: PropTypes.node,
  children: PropTypes.node,
  sx: PropTypes.any,
};

export default DropdownExpand;
