import {makeStyles} from "@material-ui/core/styles";

const useMessageThreadStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    display: (props) => (!!props.isTabletView ? "contents" : "flex"),
    flexDirection: "column",
  },
  newInboxRoot: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  hideScrollbar: {
    [theme.breakpoints.up("sm")]: {padding: theme.spacing(0, 2) + "!important"},
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  emailView: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  startText: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  collapse: {
    position: "relative",
    width: "100%",
    overflow: "auto",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    backgroundColor: theme.palette.grey[50],
    flexGrow: 1,
    borderTopRightRadius: (props) => (!!props.isTabletView ? 10 : 0),
    "& > .MuiCollapse-wrapper": {height: "100%"},
  },
  floatButton: {
    backgroundColor: theme.palette.common.white,
    width: 50,
    height: 50,
    position: "sticky",
    float: "right",
    bottom: 10,
    right: 5,
    boxShadow: "0 0 20px -10px",
    opacity: 0.7,
    transition: "transform .1s",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      opacity: 0.9,
    },
    zIndex: 2,
  },
  floatButtonHidden: {
    transform: "scale(0)",
    display: "none",
  },
  floatButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  circleProgress: {
    width: "30px!important",
    height: "30px!important",
    position: "absolute",
    top: theme.spacing(2),
    left: "calc(50% - 15px)",
    zIndex: 2,
    "&.mobile": {top: 46},
  },
  inputSection: {
    overflow: "auto",
    backgroundColor: "#FAFAFA",
    borderBottomRightRadius: (props) => (!!props.isTabletView ? 10 : 0),
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  topGradient: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 30,
    background:
      "linear-gradient(180deg, rgba(250, 250, 250, 0.5) 0%, rgba(250, 250, 250, 0) 100%)",
    zIndex: 2,
  },
  channelSection: {
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  scheduleBtn: {
    width: "fit-content",
    height: "fit-content",
    textTransform: "none",
    textAlign: "end",
    padding: 0,
    color: "#6D7175",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    "&:hover": {backgroundColor: "#fafafa"},
  },
  filledBtn: {
    color: "#FFF",
    margin: 0,
    padding: theme.spacing(1, 3),
    maxHeight: 30,
    minWidth: "fit-content",
  },
  badge: {
    boxShadow: "-1px 1px 0px rgba(0, 0, 0, 0.160784)",
    margin: theme.spacing(0, 1),
    position: "relative",
    transform: "none",
  },
  scheduleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
  scheduleRowMobile: {
    paddingRight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  disabledMessageBox: {
    padding: theme.spacing(4),
    borderRadius: 16.5,
    backgroundColor: "rgba(255, 255, 255, 0.501961)",
  },
  startIcon: {margin: 0},
  btnLabel: {
    minWidth: "fit-content",
    maxHeight: 40,
  },
  ensoAISpacing: {paddingBottom: "45px !important"},
}));

export default useMessageThreadStyles;
