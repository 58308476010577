import defaultTheme from "connectui/theme/defaultTheme";
import {useState, useEffect} from "react";

/**
 * @typedef {Object} UseViewportWidthOptions
 * @property {boolean} [useBreakpointLabels=false] - If true, returns a string representing the current breakpoint label instead of the numeric width.
 */

/**
 * @typedef {'xs' | 'sm' | 'md' | 'lg'} BreakpointLabel
 */

/**
 * This hook listens to the window resize event and updates the value whenever the viewport is resized.
 * It returns by default the current viewport width as a number, but if you pass `{ useBreakpointLabels: true }`
 * one of the following breakpoint labels: `'xs'`, `'sm'`, `'md'`, `'lg'`
 *
 * @param {UseViewportWidthOptions} [options={}] - Configuration options for the hook.
 * @returns {number | BreakpointLabel} - The current viewport width as a number if `useBreakpointLabels` is false (or not provided), or a string representing the breakpoint if `useBreakpointLabels` is true.
 *
 * @example
 * // Returns a number representing the current viewport width
 * const width = useViewportWidth();
 *
 * @example
 * // Returns 'xs', 'sm', 'md' or 'lg'
 * const label = useViewportWidth({ useBreakpointLabels: true });
 */
export default function useViewportWidth(options = {}) {
  const {useBreakpointLabels = false} = options;
  const [width, setWidth] = useState(window?.innerWidth ?? 0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!useBreakpointLabels) {
    return width;
  }

  // Determine breakpoint label based on width
  if (width < defaultTheme.breakpoints.sm) {
    return "xs";
  } else if (width < defaultTheme.breakpoints.md) {
    return "sm";
  } else if (width < defaultTheme.breakpoints.lg) {
    return "md";
  } else {
    return "lg";
  }
}
