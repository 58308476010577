import React, {forwardRef} from "react";
import {useTheme} from "../../theme/ThemeProvider";
import {generateStyles} from "../../theme/styleUtilities";
import Skeleton from "../display/Skeleton";
import PropTypes from "prop-types";

/**
 * Container is a flexible wrapper component that renders a specified element with optional skeleton loading state.
 * It can display a loading skeleton or render the content based on the `skeleton.enabled` flag.
 *
 * @param {Object} props - The props for the Container component.
 * @param {React.ElementType} [props.as="div"] - The element type to render (defaults to "div").
 * @param {Object} [props.skeleton={enabled: false, width: "100%", height: "100%"}] - The skeleton loading state configuration.
 * @param {boolean} props.skeleton.enabled - Whether to display the skeleton loader.
 * @param {string} [props.skeleton.width="100%"] - The width of the skeleton loader.
 * @param {string} [props.skeleton.height="100%"] - The height of the skeleton loader.
 * @param {React.ReactNode} props.children - The content to render inside the container.
 * @param {Object} props.rest - Any other props that should be passed to the specified `Component`.
 *
 * @returns {JSX.Element} The rendered Container component.
 */
const Container = forwardRef(
  (
    {
      as: Component = "div",
      skeleton = {enabled: false, width: "100%", height: "100%"},
      children,
      ...props
    },
    ref,
  ) => {
    const {theme} = useTheme();
    const styles = generateStyles(props, theme);

    if (skeleton.enabled) {
      return (
        <Skeleton
          width={skeleton.width}
          height={skeleton.height}
          style={{...styles, ...skeleton.style}}
        />
      );
    }

    return (
      <Component ref={ref} style={styles} {...props}>
        {children}
      </Component>
    );
  },
);

Container.propTypes = {
  as: PropTypes.any,
  skeleton: PropTypes.shape({
    enabled: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    style: PropTypes.object,
  }),
  children: PropTypes.node,
};

export default Container;
