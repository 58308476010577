import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {editAccount, setUserProfile} from "redux/actions/accountsActions";
import {buildStyles} from "connectui/component/buildStyles";
import {useTheme} from "connectui/theme/ThemeProvider";
import ICONS from "connectui/icons/iconMap";
// Components
import FlexContainer from "connectui/component/layout/FlexContainer";
import SvgIcon from "connectui/component/display/SvgIcon";
import ButtonIcon from "connectui/component/input/button/ButtonIcon";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import ScrollableContainer from "connectui/component/layout/ScrollableContainer";
import ListComponent from "connectui/component/list/ListComponent";
import FiltersSetItem from "../view/FilterSetItem";
import TextInput from "connectui/component/input/text/type/TextInput";
import FilledInputBase from "connectui/component/input/text/FilledInputBase";
import Text from "connectui/component/display/Text";
import Skeleton from "connectui/component/display/Skeleton";

const useStyles = (theme) =>
  buildStyles({
    icon: {
      width: "19px",
      height: "19px",
    },
    hoverIcon: {
      width: "16px",
      height: "16px",
    },
    actionsIcon: {
      width: "18px",
      height: "18px",
      cursor: "pointer",
    },
  });

const HoverActions = ({openMenu, editName}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <FlexContainer alignItems="center" gap={theme.spacing(2)}>
      <SvgIcon
        className={classes.hoverIcon}
        component={ICONS.edit}
        sx={{color: theme.colors.textSecondary}}
        onClick={(e) => {
          e.stopPropagation();
          editName();
        }}
      />
      <ButtonIcon
        buttonBase={OutlinedButtonBase}
        sx={{
          padding: "0px",
          borderRadius: `${theme.radius.sm}px`,
        }}
        icon={
          <SvgIcon
            className={classes.hoverIcon}
            component={ICONS["more-vertical"]}
          />
        }
        onClick={openMenu}
      />
    </FlexContainer>
  );
};

const FilterSetList = ({views, selected, openMenu, onFilterClick}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [editItem, setEditItem] = useState({id: null, name: ""});

  const getColors = (color) => {
    const itemColor = theme.colors[color] || theme.colors.primary;
    const selectedBG =
      theme.colors[color.replace(/\d+$/, "50")] || theme.colors.primary50;
    return {icon: itemColor, bg: selectedBG};
  };

  const saveViewName = () => {
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    const selectedView = views.find((v) => v.id === editItem.id);
    selectedView.name = editItem.name;
    const prevViews = _.cloneDeep(views);

    // Update root object in redux store
    dispatch(
      setUserProfile({
        ...user_profile,
        views: views.map((v) => (v.id === editItem.id ? selectedView : v)),
      }),
    );

    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {...user, edited_view: selectedView},
    };
    dispatch(
      editAccount({
        body: {body},
        disableUpdate: true,
        successMsg: "View updated correctly",
        errorMsg: "Failed to save changes",
        onError: () => {
          dispatch(setUserProfile({...user_profile, views: prevViews}));
        },
      }),
    );
    setEditItem({id: null, name: ""});
  };

  return (
    <ScrollableContainer
      width={"100%"}
      height={"100%"}
      p={theme.spacing(0, 4, 4)}
    >
      {!views ? (
        <>
          <Text
            variant="textSM"
            weight="medium"
            color="textSecondary"
            sx={{marginBottom: theme.spacing(6)}}
          >
            {t(`custom-filter-sets-section-title`)}
          </Text>
          <ListComponent gap={theme.spacing(1)}>
            {Array.from({length: 4}).map((_, index) => (
              <FlexContainer
                key={index}
                alignItems="center"
                gap={theme.spacing(3)}
                sx={{
                  width: "100%",
                  height: "36px",
                  padding: theme.spacing(2, 3),
                }}
              >
                <Skeleton
                  width={"20px"}
                  height={"20px"}
                  style={{borderRadius: `${theme.radius.lg}px`}}
                />
                <Skeleton
                  width={"128px"}
                  height={"20px"}
                  style={{borderRadius: `${theme.radius.lg}px`}}
                />
              </FlexContainer>
            ))}
          </ListComponent>
        </>
      ) : !views.length ? (
        t("empty")
      ) : (
        <ListComponent gap={theme.spacing(6)}>
          <ListComponent
            gap={theme.spacing(1)}
            label={t(`custom-filter-sets-section-title`)}
            labelProps={{
              variant: "textSM",
              weight: "medium",
              color: "textSecondary",
              sx: {marginBottom: theme.spacing(4)},
            }}
          >
            {views.map((filterSet) => {
              const isSelected = selected === filterSet.id;
              const colors = getColors(filterSet.color);

              if (editItem.id === filterSet.id) {
                return (
                  <TextInput
                    inputBase={FilledInputBase}
                    sx={{width: "100%"}}
                    inputProps={{
                      placeholder: "View name",
                      value: editItem.name,
                      onChange: (e) => {
                        setEditItem({id: filterSet.id, name: e.target.value});
                      },
                    }}
                    actions={
                      <FlexContainer
                        alignItems="center"
                        gap={theme.spacing(1)}
                        sx={{marginLeft: theme.spacing(3)}}
                      >
                        <SvgIcon
                          className={classes.actionsIcon}
                          component={ICONS.check}
                          sx={{color: theme.colors.textSecondary}}
                          onClick={saveViewName}
                        />
                        <SvgIcon
                          className={classes.actionsIcon}
                          component={ICONS["small-x"]}
                          sx={{color: theme.colors.textSecondary}}
                          onClick={() => setEditItem({id: null, name: ""})}
                        />
                      </FlexContainer>
                    }
                  />
                );
              }

              return (
                <FiltersSetItem
                  key={filterSet.id}
                  hoverActions={
                    !filterSet.sub_views?.length && (
                      <HoverActions
                        openMenu={openMenu(filterSet)}
                        editName={() =>
                          setEditItem({id: filterSet.id, name: filterSet.name})
                        }
                      />
                    )
                  }
                  icon={
                    <SvgIcon
                      className={classes.icon}
                      sx={{color: colors.icon}}
                      component={ICONS[filterSet.icon]}
                    />
                  }
                  onClick={onFilterClick(filterSet)}
                  label={filterSet.name}
                  itemProps={{
                    sx: isSelected ? {backgroundColor: colors.bg} : {},
                  }}
                  dropdownContent={
                    !!filterSet.sub_views?.length ? (
                      <ListComponent gap={theme.spacing(1)}>
                        {filterSet.sub_views.map((subFilterSet) => {
                          const isSubSetSelected = selected === subFilterSet.id;
                          return (
                            <FiltersSetItem
                              key={subFilterSet.id}
                              label={subFilterSet.name}
                              totalCount={subFilterSet.total_count}
                              hoverActions={
                                !subFilterSet.sub_views?.length && (
                                  <HoverActions
                                    openMenu={openMenu(subFilterSet)}
                                    editName={() =>
                                      setEditItem({
                                        id: subFilterSet.id,
                                        name: subFilterSet.name,
                                      })
                                    }
                                  />
                                )
                              }
                              onClick={onFilterClick(subFilterSet)}
                              itemProps={{
                                sx: isSubSetSelected
                                  ? {backgroundColor: colors.bg}
                                  : {},
                              }}
                            />
                          );
                        })}
                      </ListComponent>
                    ) : null
                  }
                />
              );
            })}
          </ListComponent>
        </ListComponent>
      )}
    </ScrollableContainer>
  );
};

export default FilterSetList;
