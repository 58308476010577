import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import DateComponent from "../../../../components/TextFields/Date";

const TimeField = ({fieldData, handleValueChange, options, ...props}) => {
  const renderInput = () => (
    <DateComponent
      date={new Date()}
      showInfoText
      time
      edit={true}
      value={fieldData.val}
      onValueChange={(newValue) => {
        handleValueChange(fieldData.type, newValue);
      }}
    />
  );

  const getOperators = () => ["is_greater_than", "is_less_than"];

  const getLabel = () => getDefaultFieldLabel(fieldData?.val, fieldData);

  return (
    <BaseFieldComponent
      renderInput={renderInput}
      getLabel={getLabel}
      getOperators={getOperators}
      {...props}
    />
  );
};

TimeField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default TimeField;
