import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import ChipText from "../../../../connectui/component/display/chip/ChipText";
import Text from "../../../../connectui/component/display/Text";
import ChipBase from "../../../../connectui/component/display/chip/base/ChipBase";
import XChipBase from "../../../../connectui/component/display/chip/base/XChipBase";
import React from "react";

export default function FilterListChip({selected, filter, ...props}) {
  const {theme} = useTheme();
  return (
    <ChipText
      text={
        <Text
          variant="textSM"
          weight="medium"
          color={!selected ? "gray700" : "blue700"}
        >
          {filter ?? "None"}
        </Text>
      }
      chipBase={!selected ? ChipBase : XChipBase}
      sx={{
        cursor: "pointer",
        color: !selected ? theme.colors.gray700 : theme.colors.blue700,
        backgroundColor: !selected ? theme.colors.gray100 : theme.colors.blue50,
        "&:hover": {
          backgroundColor: !selected
            ? theme.colors.gray200
            : theme.colors.blue100,
        },
      }}
      {...props}
    />
  );
}
