import React from "react";
import Inbox from "ui/page/Inbox";
import Messages from "./Messages";
import {useFlags} from "launchdarkly-react-client-sdk";

const MessagesPage = () => {
  const flags = useFlags();

  if (flags.newInbox) {
    return <Inbox />;
  }

  return <Messages />;
};

export default MessagesPage;
