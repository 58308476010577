import React, {useState, useEffect} from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {ReactComponent as SearchIcon} from "connectui/icons/search.svg";
import SvgIcon from "connectui/component/display/SvgIcon";
import FlexContainer from "connectui/component/layout/FlexContainer";
import TextInput from "connectui/component/input/text/type/TextInput";
import OutlinedInputBase from "connectui/component/input/text/OutlinedInputBase";
import ScrollableContainer from "../../../../connectui/component/layout/ScrollableContainer";
import DropdownExpand from "connectui/component/display/dropdown/DropdownExpand";
import ChipText from "connectui/component/display/chip/ChipText";
import {fieldParams} from "../../../../configuration/specs";
import {useDispatch} from "react-redux";
import {getCustomFields} from "../../../../redux/actions/settingsActions";
import FilterItemComponent from "../filterlist/FilterItemComponent";

const useStyles = (theme) =>
  buildStyles({
    searchIcon: {
      width: "20px",
      height: "20px",
      marginRight: theme.spacing(2),
    },
  });

const FilterListPanel = ({filters, setFilters}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const [expanded, setExpanded] = useState(null);
  const dispatch = useDispatch();
  const [customFields, setCustomFields] = useState({});

  const updateCustomFields = () => {
    let isMounted = true;
    dispatch(
      getCustomFields({
        objects: [], // Add other calls if needed
        onSuccess: (response) => {
          if (isMounted) {
            setCustomFields((prev) => response ?? {});
          }
        },
      }),
    );

    return () => {
      isMounted = false;
    };
  };
  useEffect(updateCustomFields, []);

  const getCount = (filterGroup) => {
    return filters.filter((f) => f.path === filterGroup).length;
  };

  return (
    <>
      <FlexContainer px={theme.spacing(4)} width={"100%"}>
        <TextInput
          inputBase={OutlinedInputBase}
          startIcon={
            <SvgIcon
              component={SearchIcon}
              className={classes.searchIcon}
              sx={{color: theme.colors.gray500}}
            />
          }
          inputProps={{placeholder: "Search"}}
        />
      </FlexContainer>
      <ScrollableContainer
        width={"100%"}
        height={"100%"}
        p={theme.spacing(0, 4, 4)}
      >
        {Object.entries(fieldParams).map(([fieldName, fieldConfig]) => {
          if (!fieldConfig.filter_available) {
            return null; // Skip entries without filters available
          }

          const count = getCount(fieldName);
          const isExpanded = expanded === fieldName;

          return (
            <DropdownExpand
              key={fieldName}
              label={fieldConfig.name || fieldName}
              actions={
                !!count && (
                  <ChipText
                    text={count}
                    sx={{
                      marginLeft: theme.spacing(2),
                      backgroundColor: "#e3edff",
                      "&:hover": {backgroundColor: "#cfe0fe"},
                    }}
                  />
                )
              }
              sx={{marginTop: theme.spacing(4.5)}}
              expanded={isExpanded}
              onExpand={() => setExpanded(isExpanded ? null : fieldName)}
            >
              {isExpanded && (
                <FilterItemComponent
                  field={fieldName}
                  selectedFilters={filters}
                  setFilters={setFilters}
                  customFields={customFields}
                />
              )}
            </DropdownExpand>
          );
        })}
      </ScrollableContainer>
    </>
  );
};

export default FilterListPanel;
