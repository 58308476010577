import React from "react";
import {makeStyles} from "@material-ui/core";
import WarningAlert from "core/alerts/WarningAlert";
import {useTranslation} from "react-i18next";
import {getPriceByIso} from "utilities/twilioPriceUtil";
import {countryName} from "configuration/constants";

const useStyles = makeStyles((theme) => ({
  warningContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default function SmsCostWarningAlert({
  country,
  multiplier = 0,
  frequency,
}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const countryCode = country?.countryCode;
  const name =
    countryCode && countryName[countryCode.toUpperCase()]
      ? countryName[countryCode.toUpperCase()]
      : "";
  const price = countryCode ? getPriceByIso(countryCode) || 0 : 0;

  let lowerFactor;
  let higherFactor;
  let titleKey = "sms-cost-warning-title";
  let subtitleKey;

  if (frequency === "monthly") {
    subtitleKey = "sms-cost-warning-subtitle-monthly";
    lowerFactor = 10;
    higherFactor = 100;
  } else if (frequency === "onetime") {
    subtitleKey = "sms-cost-warning-subtitle-onetime";
    lowerFactor = 2;
    higherFactor = 4;
  } else {
    return null;
  }

  // Handle the 0 price case
  if (price === 0) {
    // A generic message when we can't estimate price
    return (
      <div className={classes.warningContainer}>
        <WarningAlert
          disableMargin
          title={t(titleKey)}
          subtitle={t("sms-cost-warning-subtitle-generic")}
        />
      </div>
    );
  }

  const lowerCost = `$ ${(price * multiplier * lowerFactor).toFixed(2)}`;
  const higherCost = `$ ${(price * multiplier * higherFactor).toFixed(2)}`;

  return (
    <div className={classes.warningContainer}>
      <WarningAlert
        disableMargin
        title={t(titleKey)}
        subtitle={t(subtitleKey)
          .replace("{lower-cost}", lowerCost)
          .replace("{higher-cost}", higherCost)
          .replace("{country-name}", name)}
      />
    </div>
  );
}
