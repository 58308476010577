import Listings from "routes/Listings.jsx";
import Register from "routes/Login/Register/Register.jsx";
import Signup from "routes/Login/Signup.jsx";
import Forgot from "routes/Login/Forgot/Forgot.jsx";
import Login from "routes/Login/Login/Login.jsx";
import AddDevices from "routes/Devices/AddDevices.jsx";
import MessagesPage from "../routes/MessagesPage.jsx";
import BusinessSettings from "../routes/BusinessSettings/BusinessSettings.jsx";
import Dashboard from "../routes/Dashboard.jsx";
import Reset from "routes/Login/Reset/Reset.jsx";
import ChannelAuth from "routes/PropertyConnection/ChannelAuth.jsx";
import PairDevices from "routes/Devices/PairDevices.jsx";
import ErrorPage from "routes/ErrorPage.jsx";
import CRMV2 from "../ui/page/CRMV2";
import TestingPage from "../routes/TestingPage";

const routes = [
  {
    path: "/listings",
    name: "Listings",
    component: Listings,
    layout: "/admin",
  },
  {
    path: "/messages/:guest_id?",
    name: "Messages",
    component: MessagesPage,
    layout: "/admin",
  },
  {
    path: "/messages/chat/:guest_id",
    name: "Chat",
    component: MessagesPage,
    layout: "/admin",
  },
  {
    path: "/messages/profile/:guest_id",
    name: "Profile",
    component: MessagesPage,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register/:channel/:version",
    name: "Register",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/register/:channel",
    name: "Register",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    layout: "/auth",
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: Forgot,
    layout: "/auth",
  },
  {
    path: "/adddevices",
    name: "Add Devices",
    component: AddDevices,
    layout: "/admin",
  },
  {
    path: "/connect",
    name: "Connect Account",
    component: AddDevices,
    layout: "/admin",
  },
  {
    path: "/pairdevices",
    name: "Pair Devices",
    component: PairDevices,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Business Settings",
    component: BusinessSettings,
    layout: "/admin",
  },
  {
    path: "/home",
    name: "Home",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/guests",
    name: "Guests",
    component: CRMV2,
    layout: "/admin",
  },
  {
    path: "/channel_auth/:channel_confirmation",
    name: "Account Connection",
    component: ChannelAuth,
    layout: "/admin",
  },
  {
    path: "/channel_auth/:channel_confirmation",
    name: "Account Connection",
    component: Signup,
    layout: "/auth",
  },
  {
    path: "/error",
    name: "Error Page",
    component: ErrorPage,
    layout: "/admin",
  },
];

export default routes;
