import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Storage} from "aws-amplify";
// UI
import ReactQuill from "react-quill";
import Quill from "quill";
import ImageResize from "quill-image-resize-module-react";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  MenuList,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import CloseIcon from "@material-ui/icons/Close";
import {ReactComponent as BoardingPassIcon} from "assets/icons/boarding-pass.svg";
import "react-quill/dist/quill.snow.css";
// Custom
import CustomFieldDropdown from "../MultiOption/CustomFieldDropdown";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomMenu from "core/menus/CustomMenu";
import FullLoader from "../Dialogs/FullLoader";
import VideoPrompt from "./VideoPrompt";
// Actions
import {
  closeGeneralError,
  getCustomFields,
  openGeneralError,
  validateText,
} from "redux/actions/settingsActions";
import {getGuestKeycardLink} from "redux/actions/accountsActions";
// Utilities
import {
  convertTextToHTML,
  getEmailContentWrapper,
  newUuid,
  sleep,
  validateEmail,
  validateNonEmptyHTML,
} from "utilities/helperFunctions";
import {
  highlightCustomFields,
  removeHighlightedTags,
} from "utilities/formatUtilities";
import useWindowUnloadEffect from "hooks/useWindowUnloadEffect";
import {useImageCompressor} from "utilities/fileUtilities";
import config, {THEME} from "configuration/settings.js";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

Quill.register("modules/imageResize", ImageResize);

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    "&.-schedule-container": {
      padding: 0,
      overflow: "hidden",
    },
    "& > #quill-toolbar": {padding: theme.spacing(5, 0, 3)},
    "& > .quill": {
      flexGrow: 1,
      paddingBottom: theme.spacing(4),
      "& > .ql-container": {
        borderRadius: 4,
        "& > .ql-editor": {padding: theme.spacing(4)},
      },
      "& .ql-tooltip": {left: "0 !important"},
    },
  },
  outlinedRoot: {
    border: "1px solid #F2F2F2",
    borderRadius: 8,
    padding: 0,
    "& > #quill-toolbar": {padding: theme.spacing(4)},
    "& > .quill": {
      flexGrow: 1,
      borderTop: "1px solid #F2F2F2",
      padding: 0,
      margin: 0,
      "& > .ql-container": {
        border: "none",
        "& > .ql-editor": {padding: theme.spacing(4)},
      },
    },
  },
  recipientsSection: {
    display: "flex",
    flexDirection: "column",
  },
  autocomplete: {marginBottom: theme.spacing(2)},
  input: {borderBottom: `1px solid ${theme.palette.grey[300]}`},
  subjectInput: {marginBottom: theme.spacing(2)},
  subjectInputRoot: {borderBottom: `1px solid ${theme.palette.grey[300]}`},
  inputMultiline: {marginRight: "15px!important"},
  listItemIcon: {minWidth: 30},
  iconButton: {
    padding: 0,
    paddingRight: theme.spacing(2),
    transition: ".15s",
  },
  sendBtnRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

const modules = {
  toolbar: {
    container: "#quill-toolbar",
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const CustomToolbar = (props) => (
  <div
    id="quill-toolbar"
    style={{
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      border: "none",
    }}
  >
    {!props.flags.guidebooksV2 && <select className="ql-font"></select>}
    <select className="ql-size"></select>
    <Divider orientation="vertical" flexItem className="ml-2 mr-2" />
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-strike"></button>
    <select className="ql-color"></select>
    <select className="ql-background"></select>
    <Divider orientation="vertical" flexItem className="ml-2 mr-2" />
    <select className="ql-align"></select>
    {!props.flags.guidebooksV2 && (
      <>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-indent" value="-1"></button>
        <button className="ql-indent" value="+1"></button>
      </>
    )}
    <Divider orientation="vertical" flexItem className="ml-2 mr-2" />
    <button className="ql-blockquote"></button>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
    <button className="ql-video"></button>
    <Divider orientation="vertical" flexItem className="ml-2 mr-2" />
    {!props.flags.guidebooksV2 && (
      <>
        <button className="ql-undo">
          <UndoIcon />
        </button>
        <button className="ql-redo">
          <RedoIcon />
        </button>
        <button className="ql-clean"></button>
      </>
    )}
  </div>
);

const EmailEditor = ({
  guest,
  guest_email,
  subject,
  editorOnly,
  sendMessage,
  closeFn,
  schedule,
  placeholder = "Enter email content...",
  outlined,
  value = null,
  onChange = null,
  imagePath = "email_images",
  useCustomFields,
  lang,
  disabled = false,
  externalEmailContent = null,
  setExternalEmailContent = () => null,
  AIprops,
  cleanAIContent = () => null,
  email_signature,
  onValidityCheck = null,
  onKeyDown,
  commandText,
  clearCommandText,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  // Refs
  const quillRef = React.useRef();
  const flags = useFlags();
  const imgInputRef = React.useRef();
  const sent = React.useRef(false);
  const contentRef = React.useRef("");
  const didMount = React.useRef(false);
  const validatedText = React.useRef(null);
  const isInsertingValidatedText = React.useRef(false);
  // State
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const [content, setContent] = React.useState(
    !!email_signature ? convertTextToHTML(email_signature) : "",
  );
  const [validContent, setValidContent] = React.useState(false);
  const [loaderOpen, setLoaderOpen] = React.useState(false);
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [progressObj, setProgressObj] = React.useState({loaded: 0, total: 100});
  const [attachmentsAnchorEl, setAttachmentsAnchorEl] = React.useState(null);
  const [showVideoPrompt, setShowVideoPrompt] = React.useState(false);
  const [emailSubject, setEmailSubject] = React.useState({
    text: subject,
    invalid: false,
  });
  const [recipients, setRecipients] = React.useState({
    to: {
      address: "",
      list: [guest_email],
      invalid: false,
    },
    cc: {
      address: "",
      list: [],
      invalid: false,
    },
    bcc: {
      address: "",
      list: [],
      invalid: false,
    },
  });
  const [customFields, setCustomFields] = React.useState({});
  const prevGuestEmail = usePrevious(guest_email);

  modules.toolbar.handlers = {
    undo: handleUndo,
    redo: handleRedo,
    image: insertImage,
    video: insertVideo,
  };

  useWindowUnloadEffect(() => (!!onChange ? null : removeUnusedImgs()), true);
  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  const updateCustomFields = () => {
    let isMounted = true;
    dispatch(
      getCustomFields({
        onSuccess: (response) =>
          !isMounted ? null : setCustomFields((prev) => response ?? {}),
      }),
    );

    return () => {
      isMounted = false;
    };
  };

  React.useEffect(updateCustomFields, []);

  React.useEffect(() => {
    if (
      prevGuestEmail !== guest_email &&
      !recipients.to.list.includes(guest_email)
    ) {
      setRecipients((prev) => ({
        ...prev,
        to: {...prev.to, list: [guest_email]},
      }));
    }
  }, [guest_email]);

  React.useEffect(() => {
    if (!!subject?.trim()) {
      setEmailSubject((prev) => ({text: subject, invalid: false}));
    }
  }, [subject]);

  React.useEffect(() => {
    if (!!AIprops?.content) {
      setContent((prev) => prev + AIprops?.content);
      cleanAIContent();
    }
  }, [AIprops?.content]);

  React.useEffect(() => {
    if (!didMount.current) {
      if (!!AIprops?.content) {
        didMount.current = true;
        return;
      }
      if (!!externalEmailContent) {
        setContent((prev) => externalEmailContent.content);
        setRecipients((prev) => externalEmailContent.recipients);
        setEmailSubject((prev) => externalEmailContent.emailSubject);
      } else if (value !== null && value !== content) {
        setContent((prev) => value);
      }
      didMount.current = true;
    } else {
      if (value !== null && value !== content) {
        setContent((prev) => value);
      }
    }
  }, [lang]);

  React.useEffect(() => {
    if (!!didMount.current) {
      setExternalEmailContent({
        recipients,
        emailSubject,
        content,
      });
    }
  }, [content, recipients, emailSubject]);

  React.useEffect(() => {
    const insertCommand = async () => {
      const quill = quillRef.current;
      if (quill) {
        const position = quill.editor.getSelection() ?? {index: 0, length: 0};
        let startIndex = position.index - commandText.search.length;
        let endIndex = startIndex + commandText.result.length;
        await quill.editor.deleteText(startIndex, commandText.search.length);
        await quill.editor.insertText(startIndex, commandText.result);
        await quill.editor.setSelection(endIndex);
      }
    };

    if (!!commandText?.result) {
      insertCommand();
      clearCommandText();
    }
  }, [commandText]);

  React.useEffect(() => {
    let isMounted = true;
    let timer = null;

    if (validatedText.current === content) {
      validatedText.current = null;
      if (isInsertingValidatedText.current) {
        isInsertingValidatedText.current = false;
      }
      return;
    }
    contentRef.current = removeHighlightedTags(content);
    if (!!onChange || !!useCustomFields) {
      timer = setTimeout(() => {
        if (!!onChange && !!isMounted) {
          onChange(contentRef.current);
        }
        if (!!useCustomFields) {
          dispatch(
            validateText({
              text: contentRef.current,
              format: "html",
              onSuccess: (response) => {
                if (!!onValidityCheck && !!isMounted) {
                  onValidityCheck(
                    response.invalid_fields && !response.invalid_fields.length,
                  );
                }
                const text = highlightCustomFields({
                  text: contentRef.current,
                  format: "html",
                  validFields: response.valid_fields,
                  invalidFields: response.invalid_fields,
                });
                if (!!isMounted && text !== content) {
                  validatedText.current = text;
                  isInsertingValidatedText.current = true;
                  setContent((prev) => text);
                }
              },
            }),
          );
        }
      }, 150);
    }

    return () => {
      clearTimeout(timer);
      isMounted = false;
    };
  }, [content]);

  function handleUndo() {
    if (quillRef.current) quillRef.current.editor.history.undo();
  }

  function handleRedo() {
    if (quillRef.current) quillRef.current.editor.history.redo();
  }

  function insertImage() {
    if (imgInputRef.current) imgInputRef.current.click();
  }

  function insertVideo() {
    setShowVideoPrompt(true);
  }

  const handleLoaderClose = () => setLoaderOpen((prev) => !prev);
  const handleCloseError = () => dispatch(closeGeneralError());
  const handleVideoPromptCancel = () => {
    setShowVideoPrompt(false);
  };

  const handleVideoInsert = async (url) => {
    const quill = quillRef.current;
    let videoLink = url;
    let id = "";
    if (url.includes("youtube.com/watch?v=")) {
      id = url.split("v=")[1];
      videoLink = `https://www.youtube.com/embed/${id}?showinfo=0`;
    } else if (url.includes("youtube.com/shorts/")) {
      id = url.split("shorts/")[1];
      videoLink = `https://www.youtube.com/embed/${id}?showinfo=0`;
    }

    if (quill) {
      try {
        const position = quill.editor.getSelection()?.index ?? 0;
        await quill.editor.insertEmbed(position, "video", videoLink);
        await quill.editor.insertText(position + 1, "\n");
        await quill.editor.setSelection(position + 2);
      } catch (error) {
        console.log("error inserting video", error);
      }
    }

    setShowVideoPrompt(false);
  };

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setImgToUpload((prev) => null);
    handleUploadImage(outputImgs[0]);
  };

  const handleError = () => {
    const message = "Image could not be loaded";
    const subtitle =
      "There was a problem uploading the image, please try again or with another image.";
    dispatch(
      openGeneralError({
        open: true,
        message: message,
        disableSeverity: true,
        subtitle: subtitle,
        duration: 6000,
        onClose: handleCloseError,
      }),
    );
  };

  const removeUnusedImgs = () => {
    uploadedImages.forEach((img) => {
      const link = `${config.s3.URL}${img}`;
      if (!sent.current || content.search(`<img src="${link}">`) === -1) {
        Storage.remove(img);
      }
    });
  };

  const uploadImageToS3 = async (file) => {
    setProgressObj((prev) => ({loaded: 0, total: 100}));
    setLoaderOpen((prev) => true);

    const imageName = `${current_user}/${imagePath}/img-${newUuid()}`;
    try {
      await Storage.put(imageName, file, {
        contentType: file.type,
        progressCallback(progress) {
          setProgressObj((prev) => ({
            loaded: progress.loaded,
            total: progress.total,
          }));
        },
      });
      const link = `${config.s3.URL}${imageName}`;
      setUploadedImages((prev) => [...prev, imageName]);
      setLoaderOpen((prev) => false);
      return link;
    } catch (error) {
      console.log("error uploading image", error);
      handleError();
      setLoaderOpen((prev) => false);
      return null;
    }
  };

  const insertImageToEditor = async (imageUrl, position) => {
    const quill = quillRef.current;
    if (!quill || !imageUrl) return;

    try {
      await quill.editor.insertEmbed(position, "image", imageUrl);
      await quill.editor.insertText(position + 1, "\n");
      await quill.editor.setSelection(position + 2);
    } catch (error) {
      console.log("error inserting image to editor", error);
      handleError();
    }
  };

  const handleUploadImage = async (file) => {
    const quill = quillRef.current;
    if (!quill) return;

    const position = quill.editor.getSelection().index;
    const imageUrl = await uploadImageToS3(file);
    await insertImageToEditor(imageUrl, position);
  };

  const handlePastedImage = async (imageFile) => {
    const quill = quillRef.current;
    if (!quill) return;

    const position =
      quill.editor.getSelection()?.index ?? quill.editor.getLength();
    const imageUrl = await uploadImageToS3(imageFile);
    await insertImageToEditor(imageUrl, position);
  };

  const handleSelectImage = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setProgressObj((prev) => ({loaded: 0, total: 100}));
    setLoaderOpen((prev) => true);
    setImgToUpload((prev) => file);
    sleep(250).then(() => setCompressEnabled((prev) => true));
  };

  const insertText = async (text, isTag) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      let position = quill.getSelection();
      if (!position) {
        quill.focus();
        position = quill.getSelection() ?? {index: 0, length: 0};
      }
      await quill.deleteText(position.index, position.length);
      await quill.insertText(position.index, isTag ? `<${text}>` : text);
      await quill.setSelection(position.index + text.length + 2);
    }
  };

  const parseAIText = async (text) => {
    let quill = quillRef.current;
    if (quill) {
      let newDelta = quill.editor.clipboard.convert(text);
      let existingDelta = quill.editor.getContents();
      let combinedDeltas = existingDelta.concat(newDelta);
      quill.editor.setContents(combinedDeltas, "silent");
    }
  };

  const handleSubmit = () => {
    const html = getEmailContentWrapper(content);
    sent.current = true;
    const message = {
      to: [...recipients.to.list.filter((val) => val !== guest_email)],
      cc: recipients.cc.list,
      bcc: recipients.bcc.list,
      subject: emailSubject.text,
      html: html,
    };
    sendMessage(null, message);
  };

  const handleSubjectChange = (e) => {
    e.persist();
    const isValid = !!e.target.value.trim();
    setEmailSubject((prev) => ({invalid: !isValid, text: e.target.value}));
  };

  const handleRecipientsChange = (type) => (e, values, reason) => {
    const address = e.target.value;
    if (validateEmail(address) || reason !== "create-option") {
      setRecipients((prev) => ({
        ...prev,
        [type.toLowerCase()]: {
          ...prev[type.toLowerCase()],
          invalid: false,
          list:
            type === "To"
              ? [guest_email, ...values.filter((v) => v !== guest_email)]
              : [...values],
        },
      }));
    } else if (reason === "create-option") {
      setRecipients((prev) => ({
        ...prev,
        [type.toLowerCase()]: {
          ...prev[type.toLowerCase()],
          invalid: true,
          address: address,
        },
      }));
    }
  };

  const onRecipientsInputChange = (type) => (e, val) => {
    setRecipients((prev) => ({
      ...prev,
      [type.toLowerCase()]: {
        ...prev[type.toLowerCase()],
        address: val,
      },
    }));
  };

  const handleContentChange = (val) => {
    const isValid = validateNonEmptyHTML(val);
    if (isInsertingValidatedText.current) {
      isInsertingValidatedText.current = false;
    } else {
      setContent((prev) => val);
    }
    if (isValid !== validContent) setValidContent(isValid);
  };

  const getKeycard = () => {
    setAttachmentsAnchorEl((prev) => null);
    dispatch(
      getGuestKeycardLink(
        guest.guest_id,
        guest.bookings[guest.bookings.length - 1],
        (link) => {
          insertText(link);
        },
      ),
    );
  };

  function getRecipientsInput(type, object) {
    return (
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={object.list}
        inputValue={object.address}
        limitTags={3}
        closeIcon={
          <Typography variant="caption" color="textSecondary">
            CLEAR
          </Typography>
        }
        onInputChange={onRecipientsInputChange(type)}
        onChange={handleRecipientsChange(type)}
        classes={{
          root: classes.autocomplete,
          inputRoot: classes.input,
          tag: classes.chip,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={object.invalid}
            multiline
            helperText={object.invalid ? "Invalid email address" : ""}
            onChange={handleRecipientsChange}
            InputProps={{
              ...params.InputProps,
              classes: {inputMultiline: classes.inputMultiline},
              disableUnderline: true,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Typography color="textSecondary">{type}:</Typography>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(val, getTagProps) =>
          val.map((option, index) => (
            <Chip
              key={option}
              color="primary"
              variant="outlined"
              size="small"
              label={option}
              {...getTagProps({index})}
              deleteIcon={option !== guest_email ? <CloseIcon /> : null}
              onDelete={
                option === guest_email ? null : getTagProps({index}).onDelete
              }
              disabled={option === guest_email}
            />
          ))
        }
      />
    );
  }

  React.useEffect(() => {
    const handlePaste = async (e) => {
      const clipboardItems = e.clipboardData?.items;
      if (!clipboardItems) return;

      let hasImage = false;
      let hasOtherContent = false;

      // First scan clipboard items to determine content types
      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];
        if (item.type.indexOf("image") !== -1) {
          hasImage = true;
        } else {
          hasOtherContent = true;
        }
      }

      // If we have images, handle them
      if (hasImage) {
        // Only prevent default if we're dealing exclusively with images
        if (!hasOtherContent) {
          e.preventDefault();
        }

        // Process any images found
        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];
          if (item.type.indexOf("image") !== -1) {
            const file = item.getAsFile();
            if (file) {
              await handlePastedImage(file);
            }
          }
        }
      }
    };

    const editorElement = document.querySelector(".ql-editor");
    if (editorElement) {
      editorElement.addEventListener("paste", handlePaste);
    }

    return () => {
      const editorElement = document.querySelector(".ql-editor");
      if (editorElement) {
        editorElement.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  return (
    <>
      <div
        id="react-quill-wrapper"
        className={clsx(classes.root, {
          "-schedule-container": !!schedule,
          [classes.outlinedRoot]: !!outlined,
        })}
      >
        <FullLoader
          open={loaderOpen}
          loadingText="Uploading Image..."
          disableDismiss
          onClose={handleLoaderClose}
          progress={progressObj}
        />
        <input
          id="imageAttachmentInput"
          ref={imgInputRef}
          style={{display: "none"}}
          type="file"
          accept="image/*"
          onChange={handleSelectImage}
        />
        {!editorOnly && (
          <div className={classes.recipientsSection}>
            {!schedule && (
              <IconButton
                onClick={closeFn}
                style={{alignSelf: "flex-end", padding: 0}}
              >
                <CloseIcon />
              </IconButton>
            )}
            {getRecipientsInput("To", recipients.to)}
            {getRecipientsInput("CC", recipients.cc)}
            {getRecipientsInput("BCC", recipients.bcc)}
            <TextField
              value={emailSubject.text}
              fullWidth
              className={classes.subjectInput}
              error={emailSubject.invalid}
              helperText={emailSubject.invalid ? "Subject cannot be empty" : ""}
              onChange={handleSubjectChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">Subject:</InputAdornment>
                ),
                classes: {root: classes.subjectInputRoot},
              }}
            />
          </div>
        )}
        <CustomToolbar flags={flags} />
        <ReactQuill
          ref={quillRef}
          value={content}
          modules={modules}
          readOnly={!!disabled}
          bounds="#react-quill-wrapper"
          placeholder={placeholder}
          onChange={handleContentChange}
          onKeyDown={onKeyDown}
        />
        {showVideoPrompt && (
          <VideoPrompt
            onConfirm={handleVideoInsert}
            onCancel={handleVideoPromptCancel}
          />
        )}
        {!editorOnly && (
          <div className={classes.sendBtnRow}>
            <CustomMenu
              open={!!attachmentsAnchorEl}
              anchorEl={attachmentsAnchorEl}
              placement="top-start"
              onClose={() => setAttachmentsAnchorEl((prev) => null)}
              content={
                <MenuList>
                  <MenuItem onClick={getKeycard}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <SvgIcon
                        viewBox="0 0 21 14"
                        component={BoardingPassIcon}
                      />
                    </ListItemIcon>
                    <Typography id="keycard">Boarding Pass</Typography>
                  </MenuItem>
                </MenuList>
              }
            />
            <Tooltip title="Attachments">
              <IconButton
                className={classes.iconButton}
                onClick={(e) => setAttachmentsAnchorEl(e.currentTarget)}
              >
                <AddIcon style={{color: THEME.email}} />
              </IconButton>
            </Tooltip>
            <PrimaryButton
              id="create-schedule-message-btn"
              label={schedule ? "Schedule" : "Submit"}
              onClick={handleSubmit}
              disabled={!validContent || !emailSubject.text.trim()}
              alignEnd
            />
          </div>
        )}
      </div>
      {!!useCustomFields && !disabled && (
        <CustomFieldDropdown
          onSelect={(tag) => insertText(tag, true)}
          customFields={customFields}
          AIprops={{
            type: AIprops?.type,
            show: !!AIprops?.type,
            objects: AIprops?.objects,
            insertGeneratedText: (generatedText) => parseAIText(generatedText),
          }}
        />
      )}
    </>
  );
};

export default withLDConsumer()(EmailEditor);
