import React from "react";
import ButtonBase from "./base/ButtonBase";
import Text from "../../display/Text";
import PropTypes from "prop-types";

/**
 * ButtonText is a component that renders a button with label or children text inside.
 * It uses the provided ButtonBase component or defaults to ButtonBase, and allows customization of typography, label, and content.
 *
 * @param {Object} props - The props for the ButtonText component.
 * @param {string} [props.typographyVariant="textMD"] - The typography variant to apply to the label text.
 * @param {string} [props.weight="medium"] - The font weight for the label text.
 * @param {string} [props.color="textPrimary"] - The font color for the label text.
 * @param {function} [props.buttonBase=ButtonBase] - The component to render for the button base (defaults to ButtonBase).
 * @param {React.ReactNode} [props.label] - The label text to display inside the button (if no children are provided).
 * @param {React.ReactNode} [props.children] - The children to display inside the button (if no label is provided).
 * @param {Object} [props.sx={}] - The style object that can be used to override default button styles.
 * @param {Object} props.rest - Any other props that should be passed to the ButtonBase component.
 *
 * @returns {JSX.Element} The rendered ButtonText component.
 */
const ButtonText = ({
  typographyVariant = "textMD",
  weight = "medium",
  color = "textPrimary",
  buttonBase: ButtonBaseComponent = ButtonBase,
  children,
  label,
  sx = {},
  ...props
}) => {
  return (
    <ButtonBaseComponent {...props} sx={sx}>
      <Text variant={typographyVariant} weight={weight} color={color}>
        {label && !children && label}
        {children && !label && children}
      </Text>
    </ButtonBaseComponent>
  );
};

ButtonText.propTypes = {
  typographyVariant: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  buttonBase: PropTypes.func,
  label: PropTypes.node,
  children: PropTypes.node,
  sx: PropTypes.any,
};

export default ButtonText;
