import React from "react";
import {useTheme} from "../../../../theme/ThemeProvider";
import {buildStyles} from "../../../buildStyles";
import Container from "../../../layout/Container";
import PropTypes from "prop-types";

/**
 * ChipBase is a customizable chip component used to display small, interactive pieces of content.
 * It allows for style overrides and supports custom internal element rendering.
 *
 * @param {Object} props - The props for the ChipBase component.
 * @param {React.ElementType} [props.as="div"] - The component to render (defaults to "div").
 * @param {Object} [props.sx={}] - The style object that can be used to override default styles.
 * @param {React.ReactNode} props.children - The content of the chip, which can be any valid React node.
 * @param {Object} props.rest - Any other props that should be passed to the container component.
 *
 * @returns {JSX.Element} The rendered ChipBase component.
 */
const ChipBase = ({as: Component = "div", sx = {}, children, ...props}) => {
  const {theme} = useTheme();

  const defaultStyles = {
    display: "inline-flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 2.5),
    backgroundColor: theme.colors.primary100,
    color: theme.colors.white,
    borderRadius: theme.spacing(4),
    fontSize: theme.typography.textSM.fontSize,
    cursor: "default",
    transition: "all 0.2s ease",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.colors.primary200,
    },
    "&:disabled": {
      backgroundColor: theme.colors.gray300,
      color: theme.colors.gray500,
      cursor: "not-allowed",
      opacity: 0.6,
    },
  };

  const mergedStyles = {...defaultStyles, ...sx};

  const classes = buildStyles({
    chip: mergedStyles,
  });

  return (
    <Container className={classes.chip} {...props}>
      {children}
    </Container>
  );
};

ChipBase.propTypes = {
  as: PropTypes.any,
  sx: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default ChipBase;
