import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import BaseFieldComponent from "../BaseFieldComponent";
import SelectChipContainer from "../../../../connectui/component/input/SelectChipContainer";
import withReducer from "../../../../utilities/withReducer";
import {buildStyles} from "connectui/component/buildStyles";
import Text from "connectui/component/display/Text";

const styles = (theme) => ({
  selectContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
});

const GuestGroupIdField = ({
  fieldData,
  handleValueChange,
  reducer,
  ...props
}) => {
  const classes = buildStyles(styles);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups,
  );
  const listingGroupsDict = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const groups = reducer.listing_groups || listingGroups;
  const groupsDict = reducer.listing_groups_dict || listingGroupsDict;
  const options = [
    {group_id: null, name: "None"},
    ...groups.filter((g) => g.group_id !== "ALL"),
  ].map((group) => group?.group_id);

  const renderInput = () => (
    <div className={classes.selectContainer}>
      <SelectChipContainer
        options={options}
        selectedOptions={fieldData.val}
        onChange={(newSelection) => {
          handleValueChange(
            fieldData.type,
            Array.isArray(newSelection)
              ? newSelection.filter((f) => f !== "")
              : newSelection,
          );
        }}
        multiSelect={fieldData?.operator !== "is_not"}
        customRender={(option) => {
          const group = groupsDict[option];
          const isSelected = fieldData?.val?.includes(option);
          return (
            <Text
              variant="textSM"
              weight="medium"
              color={isSelected ? "blue700" : "gray700"}
            >
              {option === null ? "None" : group?.name}
            </Text>
          );
        }}
      />
    </div>
  );

  const renderText = () => {
    return (
      groups.find((group) => group === fieldData?.val)?.name || fieldData?.val
    );
  };

  return (
    <BaseFieldComponent
      displayType={fieldData?.displayType}
      renderInput={renderInput}
      renderLabel={renderText}
      {...props}
    />
  );
};

GuestGroupIdField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  reducer: PropTypes.object.isRequired,
};

export default withReducer(GuestGroupIdField);
