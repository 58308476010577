import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
// UI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import "styles/MessageStyles.css";
// Custom
import CustomTabs from "core/tabs/CustomTabs";
import {EmptyBookingInfoPanel} from "../../Helpers/EmptyPanels";
import BookingPanel from "components/Panels/Booking/BookingPanel.jsx";
import GuestPanel from "components/Panels/GuestPanel.jsx";
import ListingDetailsPanel from "components/Panels/Listing/ListingDetailsPanel";
import ListingContentDetailsPanel from "components/Panels/ListingContent/ListingContentDetailsPanel";
import EmptyContentText from "components/Misc/EmptyContentText";
// Actions
import {setSelectedGuestBookings} from "redux/actions/bookingsActions";
import {getGuest} from "redux/actions/guestsActions";
import {getHouseContent} from "redux/actions/listingsActions";
// Utilities
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import {orderBookings, sleep} from "utilities/helperFunctions";
import {THEME} from "configuration/settings.js";
import usePrevious from "hooks/usePrevious";
import {format} from "date-fns";
import clsx from "clsx";
import _ from "lodash";

const now = new Date();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    overflow: "auto",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  hideScrollbar: {"&::-webkit-scrollbar": {width: "0!important"}},
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pt: {
    paddingTop: 16,
    "&.extraTopPadding": {paddingTop: theme.spacing(6)},
    "&.smPadd": {paddingTop: theme.spacing(2)},
  },
  paper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  closeRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

const BookingInfoDrawer = ({
  guestId,
  hideListingLink,
  hideRedirectIcon,
  setDetailsPanel = () => null,
  onClose,
  loadingAnimations,
  setLoadingAnimations = () => null,
  extraTopPadding,
  smPadd,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags();
  const match = useRouteMatch("/admin/messages/:guest_id");
  const mobileChatMatch = useRouteMatch("/admin/messages/chat/:guest_id");
  const mobileProfileMatch = useRouteMatch("/admin/messages/profile/:guest_id");
  const guests = useSelector((state) => state.defaultReducer.guests);
  const guests_dict = useSelector((state) => state.defaultReducer.guests_dict);
  const bookings_dict = useSelector(
    (state) => state.defaultReducer.bookings_dict,
  );
  const houses_dict = useSelector(
    (state) => state.defaultReducer.house_data_dict,
  );
  const startKeys = useSelector((state) => state.defaultReducer.start_keys);
  const configuredExperiences = useSelector(
    (state) => state.experiencesReducer.configured_experiences,
  );
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  // State
  const [tab, setTab] = React.useState(0);
  const [orderedBookings, setOrderedBookings] = React.useState([]);
  const [uiBookingMap, setUIBookingMap] = React.useState({
    currentBookings: [],
    upcomingBookings: [],
    pastBookings: [],
  });
  const [loadGuest, setLoadGuest] = React.useState(true);
  const [listingDetailsView, setListingDetailsView] = React.useState(null);
  const [listingContentTab, setListingContentTab] = React.useState(0);
  const [detailPanel, setDetailPanel] = React.useState(null);
  const [listingId, setListingId] = React.useState(null);
  const [selectedExperience, setSelectedExperience] = React.useState(null);
  const [calendarProps, setCalendarProps] = React.useState({
    dialog: {
      open: false,
      title: format(now, "MMM d, Y"),
      view: null,
      dateRange: [now, now],
      data: null,
    },
    startDate: null,
    endDate: null,
    formattedRanges: {
      bookings: [],
      availability: [],
      rates: [],
    },
    useEndDateFocus: false,
  });
  // General
  let paramsGuestId =
    mobileChatMatch?.params?.guest_id ??
    mobileProfileMatch?.params?.guest_id ??
    match?.params.guest_id;
  let tabs = React.useMemo(() => {
    let newTabs = [];
    if (!!uiBookingMap.currentBookings.length) {
      newTabs.push(`Active-${uiBookingMap.currentBookings.length}`);
    }
    if (!!uiBookingMap.upcomingBookings.length) {
      newTabs.push(`Upcoming-${uiBookingMap.upcomingBookings.length}`);
    }
    if (!!uiBookingMap.pastBookings.length) {
      newTabs.push(`Past-${uiBookingMap.pastBookings.length}`);
    }
    return !!flags.bookingPanelRedesign
      ? newTabs
      : ["Active bookings", "Upcoming bookings", "Past bookings"];
  }, [uiBookingMap, flags]);
  const selectedGuestId = React.useMemo(() => {
    if (!!loadingAnimations) {
      return null;
    } else {
      return guestId ?? paramsGuestId ?? null;
    }
  }, [match, mobileChatMatch, mobileProfileMatch, guestId, loadingAnimations]);
  const [guest, setGuest] = React.useState(guests_dict[selectedGuestId]);
  const selectedHouse = React.useMemo(
    () => houses_dict[listingId],
    [houses_dict, listingId],
  );
  const prevGuest = usePrevious(guest);
  const readOnly = user_profile.scopes?.listings !== "write";

  React.useLayoutEffect(() => {
    let isMounted = true;
    sleep(THEME.transitions.mobileScreens + 500).then(() => {
      if (isMounted && !!loadingAnimations) {
        setLoadingAnimations(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [loadingAnimations]);

  React.useEffect(() => {
    setDetailsPanel(listingDetailsView);
  }, [listingDetailsView]);

  React.useEffect(() => {
    if (
      !guests_dict[selectedGuestId] &&
      prevGuest?.guest_id === selectedGuestId
    ) {
      return;
    } else {
      setGuest((prev) => guests_dict[selectedGuestId]);
    }
  }, [guests_dict, selectedGuestId]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (!!loadGuest && !!selectedGuestId && startKeys.guests !== null) {
        dispatch(
          getGuest(
            selectedGuestId,
            (newGuest) => setLoadGuest((prev) => false),
            () => setLoadGuest((prev) => false),
          ),
        );
      }
    });

    return () => clearTimeout(timer);
  }, [loadGuest, selectedGuestId, startKeys]);

  React.useEffect(() => {
    if (!!prevGuest && !guest && selectedGuestId === prevGuest.guest_id) {
      return;
    } else if (!guest?.metadata && !!selectedGuestId) {
      setLoadGuest((prev) => true);
    }
  }, [guest, selectedGuestId]);

  React.useEffect(() => {
    if (!guest) return;
    closeListingDetails();
  }, [selectedGuestId]);

  React.useEffect(() => {
    if (!guest) return;
    const newOrderedBkgs = orderBookings(guest, bookings_dict, selectedGuestId);
    setOrderedBookings(newOrderedBkgs);
  }, [bookings_dict, guest]);

  React.useEffect(() => {
    dispatch(setSelectedGuestBookings(orderedBookings));
  }, [orderedBookings]);

  React.useEffect(() => {
    if (!!listingId && !!selectedExperience) {
      const newExperience =
        configuredExperiences.listingId[listingId][
          selectedExperience.experience_id
        ];
      setSelectedExperience((prev) => newExperience);
    }
  }, [configuredExperiences]);

  React.useEffect(() => {
    let [pBookings, cBookings, uBookings] = [[], [], []];

    let currentTime = Date.now();
    for (let booking of orderedBookings) {
      if (booking.checkout_raw < currentTime) {
        pBookings.push(booking);
      } else if (booking.booking_id && booking.checkin_raw < currentTime) {
        cBookings.push(booking);
      } else {
        uBookings.push(booking);
      }
    }

    setUIBookingMap({
      currentBookings: cBookings,
      upcomingBookings: _.orderBy(uBookings, "checkin_raw", "checkout_raw"),
      pastBookings: pBookings,
    });
  }, [orderedBookings, loadGuest]);

  React.useEffect(() => {
    // If guest is loaded and there are no current bookings
    if (
      !loadGuest &&
      !!guest?.metadata &&
      !uiBookingMap.currentBookings.length
    ) {
      if (
        !uiBookingMap.upcomingBookings.length &&
        !!uiBookingMap.pastBookings.length
      ) {
        setTab((prev) => tabs.findIndex((t) => t.startsWith("Past")));
      } else if (!!uiBookingMap.upcomingBookings.length) {
        setTab((prev) => tabs.findIndex((t) => t.startsWith("Upcoming")));
      }
    } else if (
      !loadGuest &&
      !!guest?.metadata &&
      !!uiBookingMap.currentBookings.length
    ) {
      setTab((prev) => tabs.findIndex((t) => t.startsWith("Active")));
    }
  }, [uiBookingMap, loadGuest]);

  const handleRedirectClick = () => {
    history.push(`/admin/messages/${selectedGuestId}`);
  };

  const closeCalendarDialog = () =>
    setCalendarProps((prev) => ({
      ...prev,
      dialog: {...prev.dialog, open: false},
    }));

  const handleCalendarPropChange = (key, value) => {
    setCalendarProps((prev) => ({...prev, [key]: value}));
  };

  const changeView = (view) => {
    setListingDetailsView((prev) => "details");
    setDetailPanel((prev) => view);
  };

  const closeListingDetails = () => {
    setListingId((prev) => null);
    setListingDetailsView((prev) => null);
    setDetailPanel((prev) => null);
    setListingContentTab((prev) => 0);
    setCalendarProps((prev) => ({...prev, startDate: null, endDate: null}));
  };

  const closeDetailPanel = () => {
    setListingDetailsView((prev) => "overview");
    setDetailPanel((prev) => null);
  };

  const openListingDetails = (houseId) => {
    if (hideListingLink) {
      return;
    }
    dispatch(getHouseContent(houseId));
    setListingId((prev) => houseId);
    setListingDetailsView((prev) => "overview");
  };

  const handleBkgSelect = (bkg) => {
    if (bkg.guest_id === guest.guest_id) {
      closeListingDetails();
    } else {
      closeListingDetails();
    }
  };

  const handleExperienceClose = () => {
    setListingDetailsView((prev) => "overview");
    setSelectedExperience((prev) => null);
  };

  function getBkgListContent(bkgs) {
    if (!bkgs.length) return;
    return (
      <>
        {bkgs.map((bkg, index) => (
          <BookingPanel
            key={bkg.booking_id ?? `inquiry-${bkg.listing_id}-${index}`}
            booking={bkg}
            guestId={selectedGuestId}
            hideListingLink={hideListingLink}
            openListingDetails={() => openListingDetails(bkg.listing_id)}
          />
        ))}
      </>
    );
  }

  function getListingDetailsView() {
    switch (listingDetailsView) {
      case "overview":
        return (
          <ListingDetailsPanel
            outsideView
            disableEdit={!!isMobile || readOnly}
            tab={listingContentTab}
            listingId={selectedHouse.listing_id}
            calendarProps={calendarProps}
            setView={changeView}
            setTab={setListingContentTab}
            onClose={closeListingDetails}
            onBkgSelect={handleBkgSelect}
            selectExperience={setSelectedExperience}
            closeCalendarDialog={closeCalendarDialog}
            onCalendarPropChange={handleCalendarPropChange}
          />
        );
      case "details":
        return (
          <ListingContentDetailsPanel
            outsideView
            disableEdit={!!isMobile || readOnly}
            contentId={detailPanel}
            listingId={selectedHouse.listing_id}
            calendarProps={calendarProps}
            selectedExperience={selectedExperience}
            goBack={closeDetailPanel}
            selectExperience={setSelectedExperience}
            setContentId={setDetailPanel}
            onCalendarPropChange={handleCalendarPropChange}
            onExperienceClose={handleExperienceClose}
          />
        );

      default:
        return null;
    }
  }

  function getTabContent() {
    if (!tabs.length) {
      return <EmptyContentText label="There are no bookings" />;
    } else if (tabs[tab]?.startsWith("Active")) {
      return (
        <>
          {!uiBookingMap.currentBookings.length && (
            <Typography variant="h1" color="textSecondary">
              {"There are no active bookings"}
            </Typography>
          )}
          {getBkgListContent(uiBookingMap.currentBookings)}
        </>
      );
    } else if (tabs[tab]?.startsWith("Upcoming")) {
      return (
        <>
          {!uiBookingMap.upcomingBookings.length && (
            <Typography variant="h1" color="textSecondary">
              {"There are no upcoming bookings"}
            </Typography>
          )}
          {getBkgListContent(uiBookingMap.upcomingBookings)}
        </>
      );
    } else if (tabs[tab]?.startsWith("Past")) {
      return (
        <>
          {!uiBookingMap.pastBookings.length && (
            <Typography variant="h1" color="textSecondary">
              {"There are no past bookings"}
            </Typography>
          )}
          {getBkgListContent(uiBookingMap.pastBookings)}
        </>
      );
    } else {
      return null;
    }
  }

  const guestPanel = React.useMemo(() => {
    return (
      <GuestPanel
        handleRedirectClick={!hideRedirectIcon ? handleRedirectClick : null}
        guestId={selectedGuestId}
      />
    );
  }, [selectedGuestId, hideRedirectIcon]);

  if (!!listingDetailsView) {
    return getListingDetailsView();
  } else if (!guest && (!prevGuest || prevGuest.guest_id !== selectedGuestId)) {
    return (
      <EmptyBookingInfoPanel
        loading={startKeys.guests === null || !!guests.length}
      />
    );
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.pt]: !onClose,
        [classes.hideScrollbar]: !!isMobileView,
        extraTopPadding: extraTopPadding,
        smPadd: smPadd,
      })}
    >
      <Paper elevation={0} className={classes.paper}>
        {!!onClose && (
          <div className={classes.closeRow}>
            <IconButton onClick={onClose} style={{padding: 4}}>
              <CloseIcon color="disabled" />
            </IconButton>
          </div>
        )}
        {guestPanel}
        {loadGuest ||
        !guest?.bookings ||
        !!loadingAnimations ||
        !guest?.metadata ? (
          <EmptyBookingInfoPanel loading bkgPanel />
        ) : (
          <div className={classes.content}>
            <CustomTabs
              disableGutters
              selectedTab={tab}
              onChange={setTab}
              tabs={tabs}
            />
            {getTabContent()}
          </div>
        )}
      </Paper>
    </div>
  );
};

export default withLDConsumer()(BookingInfoDrawer);
