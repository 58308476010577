import React from "react";
import {useParams} from "react-router-dom";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import Container from "connectui/component/layout/Container";
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import Text from "connectui/component/display/Text";
import FlexContainer from "connectui/component/layout/FlexContainer";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
    },
  });

const GuestDetailsPanel = ({}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {guest_id} = useParams();

  return (
    <Container className={classes.container}>
      {!guest_id ? (
        <FlexContainer
          className={classes.container}
          alignItems="center"
          justifyContent="center"
        >
          <Text variant="textLG" color="gray400">
            {"Select a guest to see guest details"}
          </Text>
        </FlexContainer>
      ) : (
        <BookingInfoDrawer hideRedirectIcon />
      )}
    </Container>
  );
};

export default GuestDetailsPanel;
