import React from "react";
import {useTheme} from "../../theme/ThemeProvider";
import Container from "./Container";
import {generateStyles} from "../../theme/styleUtilities";
import PropTypes from "prop-types";

/**
 * FlexContainer is a component that renders a `div` with flexbox layout and customizable properties.
 * It supports `flexDirection`, `alignItems`, `justifyContent`, and `gap` for flexible positioning and spacing.
 *
 * @param {Object} props - The props for the FlexContainer component.
 * @param {"row" | "column"} [props.flexDirection="row"] - The direction of the flex container's children.
 * @param {"flex-start" | "center" | "flex-end" | "stretch"} [props.alignItems="flex-start"] - The alignment of items along the cross axis.
 * @param {"flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly"} [props.justifyContent="flex-start"] - The alignment of items along the main axis.
 * @param {string} [props.gap="0px"] - The gap between flex items.
 * @param {Object} [props.sx={}] - The style object that can be used to override default flex container styles.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered FlexContainer component.
 */
const FlexContainer = ({
  flexDirection = "row",
  alignItems = "flex-start",
  justifyContent = "flex-start",
  gap = "0px",
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const defaultStyles = {
    display: "flex",
    flexDirection: flexDirection,
    alignItems: alignItems,
    justifyContent: justifyContent,
    gap: gap,
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return <Container sx={styles} {...props} />;
};

FlexContainer.propTypes = {
  flexDirection: PropTypes.oneOf(["row", "column"]),
  alignItems: PropTypes.oneOf(["flex-start", "center", "flex-end", "stretch"]),
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  gap: PropTypes.string,
  sx: PropTypes.any,
};

export default FlexContainer;
