import React from "react";
import PropTypes from "prop-types";
import {getDefaultFieldLabel} from "../../../../utilities/helperFunctions";
import BaseFieldComponent from "../BaseFieldComponent";
import DateComponent from "../../../../components/TextFields/Date";

function renderInput({fieldData, handleValueChange}) {
  return (
    <DateComponent
      date={new Date()}
      showInfoText
      value={fieldData.val}
      edit={true}
      onValueChange={(newValue) => {
        handleValueChange(fieldData.type, newValue);
      }}
    />
  );
}

function getOperators() {
  return ["is_greater_than", "is_less_than"];
}

function getLabel(fieldData) {
  return getDefaultFieldLabel(fieldData?.val, fieldData);
}

const DateField = ({fieldData, handleValueChange, options, ...props}) => {
  return (
    <BaseFieldComponent
      type="input"
      renderInput={() => renderInput({fieldData, handleValueChange})}
      getLabel={() => getLabel(fieldData)}
      getOperators={getOperators}
      {...props}
    />
  );
};

DateField.propTypes = {
  fieldData: PropTypes.object.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default DateField;
