import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useEmptyPanelsStyles = makeStyles((theme) => ({
  skeletonBase: {
    backgroundColor: theme.palette.grey[200],
    transform: "none",
    "&.br10": {borderRadius: 10},
  },
  skeleton: {backgroundColor: theme.palette.grey[200]},
  skeletonDarker: {backgroundColor: theme.palette.grey[300]},
  fullHeight: {
    padding: theme.spacing(4),
    flexGrow: 1,
    overflow: "hidden",
    height: "100%",
    "&.np": {padding: 0},
  },
  row: {
    display: "flex",
    flexDirection: "row",
    "&.-end": {justifyContent: "flex-end"},
    "&.-center": {alignItems: "center"},
  },
  col: {
    display: "flex",
    flexDirection: "column",
    "&.-start": {alignItems: "flex-start"},
  },
  justSB: {justifyContent: "space-between"},
  messagesRoot: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[50],
  },
  header: {
    padding: theme.spacing(0, 4),
    paddingTop: (props) => (!!props.disableTopSpacing ? 0 : theme.spacing(4)),
  },
  container: {
    padding: theme.spacing(4),
    paddingTop: 0,
    height: "100%",
    overflow: "hidden",
    backgroundColor: (props) =>
      !!props.whitePaper ? "#FAFAFA" : !!props.grey ? "#F1F3F9" : "#FFF",
    "&.-house": {
      paddingTop: (props) => (!!props.grey ? 16 : 4),
      borderRadius: "0 0 10px 10px",
    },
    "&.-messages": {padding: theme.spacing(0, 2)},
  },
  disableGutters: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  searchContainer: {
    position: "relative",
    marginBottom: (props) =>
      !!props.disableBottomSpacing ? 0 : theme.spacing(4),
    marginTop: (props) => (!!props.disableTopSpacing ? 0 : theme.spacing(4)),
    borderRadius: 8,
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    height: 48,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  searchIcon: {
    fontSize: "1.25rem",
    color: "rgba(60,60,67,0.6)",
  },
  listTitle: {
    textTransform: "capitalize",
    marginBottom: theme.spacing(3),
  },
  listContainer: {marginTop: -theme.spacing(1)},
  customListPadding: {padding: (props) => props.customListPadding},
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: theme.spacing(3),
    height: (props) => props.height,
    "&.-house": {
      marginBottom: theme.spacing(3),
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      borderRadius: 10,
      padding: theme.spacing(3, 4),
    },
  },
  experienceListItem: {padding: theme.spacing(0, 3, 1)},
  noLeftPad: {paddingLeft: 0},
  br5: {borderRadius: 5},
  leftIcon: {marginRight: theme.spacing(3)},
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
  },
  itemActionContainer: {
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    fontSize: 16,
    color: theme.palette.action.focus,
    marginRight: -theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginTop: 5,
  },
  rounded: {borderRadius: 10},
  fullRounded: {borderRadius: 999},
  input: {
    transform: "none",
    marginBottom: theme.spacing(4),
    borderRadius: 10,
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    overflow: "hidden",
  },
  gridRow: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: theme.spacing(3),
    padding: theme.spacing(0, 4),
  },
  cardElement: {
    height: 172,
    width: "100%",
  },
  startText: {
    textAlign: "center",
    flexGrow: 1,
    color: THEME.subdued,
    marginTop: theme.spacing(1),
  },
  checkbox: {
    width: "fit-content",
    height: "fit-content",
    alignSelf: "center",
  },
  sectionTitle: {marginBottom: theme.spacing(4)},
  none: {
    color: "rgba(60, 60, 67, 0.6)",
    marginTop: -theme.spacing(2),
  },
  houseOverview: {padding: theme.spacing(5)},
  picturesContainer: {
    padding: theme.spacing(4, 4, 0),
    overflow: "hidden",
  },
  pictureGrid: {overflow: "hidden"},
  subtitleSecondary: {
    color: "rgba(0, 0, 0, 0.870588)",
    textTransform: "none",
  },
  connector: {
    position: "absolute",
    top: 27,
    left: -25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > #circle": {
      width: 9,
      height: 9,
      backgroundColor: "#F1F3F9",
      borderRadius: "50%",
    },
    "& > #line": {
      width: 30,
      height: 1,
      backgroundColor: "#F1F3F9",
    },
  },
  divider: {
    backgroundColor: "#F1F3F9",
    marginRight: theme.spacing(4),
  },
  resourceContainer: {
    width: "100%",
    borderRadius: 5,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    backgroundColor: "#F5F5F5",
  },
  navigateNextIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  ctaContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  ctaCardsContainer: {gap: theme.spacing(4)},
  ctaCard: {flex: 1},
}));

export default useEmptyPanelsStyles;
