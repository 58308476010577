import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {updateGuest} from "redux/actions/guestsActions";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import ICONS from "connectui/icons/iconMap";
// Components
import Container from "connectui/component/layout/Container";
import FlexContainer from "connectui/component/layout/FlexContainer";
import ButtonIcon from "connectui/component/input/button/ButtonIcon";
import SvgIcon from "connectui/component/display/SvgIcon";
import useViewportWidth from "hooks/useViewportWidth";
import MessagesPanel from "components/Panels/MessagesPanel";
import Text from "connectui/component/display/Text";
import TextInput from "connectui/component/input/text/type/TextInput";
import FilledInputBase from "connectui/component/input/text/FilledInputBase";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
    },
    iconBtn: {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.colors.white,
      cursor: "pointer",
      padding: "0px",
      border: "none",
      "&:hover": {backgroundColor: theme.colors.hoverGray},
      "&.open": {backgroundColor: theme.colors.gray200},
    },
    icon: {
      width: "28px",
      height: "28px",
      justifyContent: "center",
      padding: "5px",
      color: theme.colors.gray400,
      "&.open": {color: theme.colors.gray900},
    },
    nameContainer: {
      "&:hover > .-edit-icon": {opacity: 1},
    },
    hoverIcon: {
      width: "16px",
      height: "16px",
      cursor: "pointer",
      transition: "opacity 0.15s",
      marginTop: theme.spacing(0.5),
      opacity: 0,
    },
    actionsIcon: {
      width: "18px",
      height: "18px",
      cursor: "pointer",
    },
  });

const ChatPanel = ({sx = {}, panelOpen, togglePanelOpen, ...props}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {guest_id} = useParams();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const width = useViewportWidth({useBreakpointLabels: true});
  const guests_dict = useSelector((state) => state.defaultReducer.guests_dict);
  const [guestName, setGuestName] = useState("");
  const [editName, setEditName] = useState(false);
  const isLG = width === "lg" || width === "md";
  const guest = guests_dict[guest_id] ?? {};

  const saveGuestName = () => {
    dispatch(updateGuest({guest_id: guest_id, field: "name", val: guestName}));
    setEditName(false);
  };

  return (
    <FlexContainer
      className={classes.container}
      flexDirection="column"
      {...props}
    >
      {(!!guest.name || !isLG) && (
        <FlexContainer
          width="100%"
          p={theme.spacing(4)}
          justifyContent="space-between"
        >
          {editName ? (
            <TextInput
              inputBase={FilledInputBase}
              inputProps={{
                placeholder: t("guest_name_placehoder"),
                value: guestName,
                onChange: (e) => {
                  setGuestName(e.target.value);
                },
              }}
              actions={
                <FlexContainer
                  alignItems="center"
                  gap={theme.spacing(1)}
                  sx={{marginLeft: theme.spacing(3)}}
                >
                  <SvgIcon
                    className={classes.actionsIcon}
                    component={ICONS.check}
                    sx={{color: theme.colors.textSecondary}}
                    onClick={saveGuestName}
                  />
                  <SvgIcon
                    className={classes.actionsIcon}
                    component={ICONS["small-x"]}
                    sx={{color: theme.colors.textSecondary}}
                    onClick={() => setEditName(false)}
                  />
                </FlexContainer>
              }
            />
          ) : (
            <FlexContainer
              className={classes.nameContainer}
              alignItems="center"
              gap={theme.spacing(3)}
            >
              <Text variant="textLG" weight="semibold">
                {guest.name ?? ""}
              </Text>
              <SvgIcon
                className={`${classes.hoverIcon} -edit-icon`}
                component={ICONS.edit}
                sx={{color: theme.colors.textSecondary}}
                onClick={() => {
                  setGuestName(guest.name ?? "");
                  setEditName(true);
                }}
              />
            </FlexContainer>
          )}
          {!isLG && (
            <ButtonIcon
              icon={
                <SvgIcon
                  component={ICONS["layout-sidebar-right"]}
                  className={[classes.icon, panelOpen ? "open" : ""].join(" ")}
                />
              }
              className={[classes.iconBtn, panelOpen ? "open" : ""].join(" ")}
              onClick={togglePanelOpen}
            />
          )}
        </FlexContainer>
      )}
      <Container className={classes.container} sx={{overflow: "hidden"}}>
        {!guest_id ? (
          <FlexContainer
            className={classes.container}
            alignItems="center"
            justifyContent="center"
          >
            <Text variant="textLG" color="gray400">
              {"Select a guest to see messages"}
            </Text>
          </FlexContainer>
        ) : (
          <MessagesPanel />
        )}
      </Container>
    </FlexContainer>
  );
};

export default ChatPanel;
