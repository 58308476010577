import React from "react";
import {buildStyles} from "../buildStyles";
import {useTheme} from "connectui/theme/ThemeProvider";
import PropTypes from "prop-types";
import Container from "./Container";

const useStyles = (theme) =>
  buildStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "100%",
    },
    panel: {
      flex: 1,
      borderRight: `1px solid ${theme.colors.gray200}`,
      backgroundColor: theme.colors.white,
      overflowY: "auto",
      "&:last-child": {border: "none"},
    },
  });

/**
 * VerticalPanelLayout is a component that renders a layout with multiple vertical panels.
 * It dynamically adjusts the width of each panel based on the `panelStyles` prop and displays them in a grid.
 *
 * @param {Object} props - The props for the VerticalPanelLayout component.
 * @param {Array<React.ReactNode>} props.panels - The panels to display inside the layout.
 * @param {Array<Object>} [props.panelStyles] - The widths of the panels in the layout. Each value corresponds to a column in the grid.
 *
 * @returns {JSX.Element} The rendered VerticalPanelLayout component.
 */
const VerticalPanelLayout = ({panels, panelStyles}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.container}>
      {panels.map((panel, index) =>
        !!panel ? (
          <Container
            key={index}
            className={classes.panel}
            sx={panelStyles[index]}
          >
            {panel}
          </Container>
        ) : null,
      )}
    </div>
  );
};

VerticalPanelLayout.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.node),
  panelStyles: PropTypes.arrayOf(PropTypes.object),
};

export default VerticalPanelLayout;
