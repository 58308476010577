import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import Container from "connectui/component/layout/Container";
import {buildStyles} from "../buildStyles";
import {generateStyles} from "connectui/theme/styleUtilities";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    scrollable: {
      overflowY: "auto",
      paddingRight: theme.spacing(1),
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
        borderRadius: theme.radius.xl,
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.colors.gray200,
        borderRadius: theme.radius.xl,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.colors.gray300,
      },
    },
  });

/**
 * ScrollableContainer is a component that renders a container with scrollable content and custom scrollbar styles.
 * It supports custom width, height, and additional styles through the `sx` prop.
 *
 * @param {Object} props - The props for the ScrollableContainer component.
 * @param {string} [props.width="100%"] - The width of the scrollable container.
 * @param {string} [props.height="100%"] - The height of the scrollable container.
 * @param {Object} [props.sx={}] - The style object that can be used to override default scrollable container styles.
 * @param {React.ReactNode} props.children - The content to render inside the scrollable container.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered ScrollableContainer component.
 */
const ScrollableContainer = ({
  children,
  width = "100%",
  height = "100%",
  sx = {},
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const defaultStyles = {
    width: width,
    height: height,
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return (
    <Container className={classes.scrollable} style={styles} {...props}>
      {children}
    </Container>
  );
};

ScrollableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  sx: PropTypes.any,
};

export default ScrollableContainer;
