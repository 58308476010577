import React from "react";
import PropTypes from "prop-types";
import {AutoSizer, InfiniteLoader, List} from "react-virtualized";
import Container from "../layout/Container";

/**
 * VirtualizedListComponent supporting infinite scroll.
 *
 * @param {number} totalRows - The total number of rows, including not-yet-loaded ones.
 * @param {number} [rowHeight=50] - Height of each row.
 * @param {function} isRowLoaded - ({index}) => boolean, returns true if row is already loaded.
 * @param {function} loadMoreRows - ({startIndex, stopIndex}) => Promise, loads more data.
 * @param {function} children - A function(index) that returns the JSX for a given row index.
 * @param rest
 */
const VirtualizedListComponent = ({
  totalRows,
  rowHeight = 50,
  isRowLoaded,
  loadMoreRows,
  children,
  ...rest
}) => {
  const rowRenderer = ({index, key, style}) => (
    <Container key={key} style={style}>
      {children(index)}
    </Container>
  );

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={totalRows}
    >
      {({onRowsRendered, registerChild}) => (
        <AutoSizer>
          {({height, width}) => (
            <List
              height={height}
              width={width}
              rowCount={totalRows}
              rowHeight={rowHeight}
              onRowsRendered={onRowsRendered}
              rowRenderer={rowRenderer}
              ref={registerChild}
              {...rest}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

VirtualizedListComponent.propTypes = {
  totalRows: PropTypes.number.isRequired,
  rowHeight: PropTypes.number,
  minimumBatchSize: PropTypes.number,
  threshold: PropTypes.number,
  isRowLoaded: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default VirtualizedListComponent;
