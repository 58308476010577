import React, {useState} from "react";
import Container from "../layout/Container";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import PropTypes from "prop-types";
import FlexContainer from "../layout/FlexContainer";

const useStyles = (theme) =>
  buildStyles({
    tabsWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    tabsHeader: {
      display: "flex",
      gap: theme.spacing(4),
      borderBottom: `1px solid ${theme.colors.gray300}`,
      marginBottom: theme.spacing(4),
    },
    tabButton: {
      flex: 1,
      padding: theme.spacing(0, 1, 4),
      cursor: "pointer",
      background: "none",
      border: "none",
      borderBottom: `2px solid transparent`,
      fontWeight: 500,
      transition: "border-color 0.2s, color 0.2s",
      color: theme.colors.textSecondary,
    },
    tabButtonActive: {
      borderBottomColor: theme.colors.primary500,
      color: theme.colors.primary500,
      fontWeight: 600,
    },
    tabContent: {
      padding: 0,
      flex: 1,
      position: "relative",
      overflow: "hidden",
    },
  });

/**
 * Tabs is a component that renders a tabbed interface with clickable tabs to switch between content.
 * It displays a set of tabs with labels and shows the content of the active tab.
 *
 * @param {Object} props - The props for the Tabs component.
 * @param {Array<string>} props.labels - The labels for each tab.
 * @param {Array<React.ReactNode>} props.children - The content to display for each tab.
 *
 * @returns {JSX.Element} The rendered Tabs component.
 */
const Tabs = ({labels, children}) => {
  const [activeTab, setActiveTab] = useState(0);
  const {theme} = useTheme();
  const classes = useStyles(theme);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <Container as="div" className={classes.tabsWrapper}>
      <Container as="div" className={classes.tabsHeader}>
        {labels.map((label, index) => (
          <Container
            as="button"
            key={index}
            className={`${classes.tabButton} ${
              activeTab === index ? classes.tabButtonActive : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {label}
          </Container>
        ))}
      </Container>
      <FlexContainer flexDirection="column" className={classes.tabContent}>
        {children[activeTab]}
      </FlexContainer>
    </Container>
  );
};

Tabs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
export default Tabs;
