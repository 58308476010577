import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {generateStyles} from "connectui/theme/styleUtilities";
import InputBase from "./InputBase";
import PropTypes from "prop-types";

/**
 * FilledInputBase is a variant of the InputBase component that renders an input field with background.
 * It supports additional customization through the `sx` prop.
 *
 * @param {Object} props - The props for the FilledInputBase component.
 * @param {Object} [props.inputProps={}] - The properties to pass to the `<input>` element.
 * @param {Object} [props.sx={}] - The style object that can be used to override default outlined input styles.
 * @param {Object} props.rest - Any other props that should be passed to the InputBase component.
 *
 * @returns {JSX.Element} The rendered FilledInputBase component.
 */
const FilledInputBase = ({inputProps = {}, sx = {}, ...props}) => {
  const {theme} = useTheme();
  const defaultContainerStyles = {
    borderRadius: `${theme.radius.md}px`,
    backgroundColor: theme.colors.inputBG,
    padding: theme.spacing(2),
    width: "fit-content",
  };

  const defaultInputStyles = {
    backgroundColor: theme.colors.inputBG,
    borderBottom: `1px solid ${theme.colors.gray300}`,
  };

  const styles = generateStyles(
    {sx: {...defaultContainerStyles, ...sx}, ...props},
    theme,
  );

  const inputStyles = generateStyles(
    {sx: {...defaultInputStyles, ...(inputProps.sx ?? {})}},
    theme,
  );

  return (
    <InputBase
      sx={styles}
      inputProps={{style: inputStyles, ...inputProps}}
      {...props}
    />
  );
};

FilledInputBase.propTypes = {
  inputProps: PropTypes.object,
  sx: PropTypes.any,
};

export default FilledInputBase;
