import React from "react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {
  Box,
  Card,
  MenuItem,
  MenuList,
  SvgIcon,
  Typography,
  makeStyles,
  ListItemIcon,
  ListItemText,
  List,
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/NavigateNext";
import {ReactComponent as ClipboardIcon} from "assets/icons/clipboard.svg";
import {ReactComponent as DragIcon} from "assets/icons/Icon_Reorder.svg";
// Custom
import EditIconButton from "core/buttons/EditIconButton";
import FieldTypeInput from "core/inputs/FieldTypeInput";
import PrimaryButton from "core/buttons/PrimaryButton";
import FieldTypeIcon from "core/icons/FieldTypeIcon";
import ExpandButton from "core/buttons/ExpandButton";
import CustomDialog from "core/dialogs/CustomDialog";
import PreviewBPPanel from "../PreviewBPPanel";
import CustomMenu from "core/menus/CustomMenu";
// Utils
import {
  BOARDING_PASS_FIELD_TYPES,
  collectFieldTypes,
} from "configuration/constants";
import {boardingPassCollectFields} from "configuration/enums";
import {newUuid} from "utilities/helperFunctions";
import clsx from "clsx";
const bpCollectFieldsKeys = Object.keys(boardingPassCollectFields);

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  icon: {
    width: 16,
    height: 16,
  },
  card: {
    position: "relative",
    backgroundColor: "#FBFBFC",
    borderRadius: 10,
    boxShadow: "none",
    padding: theme.spacing(3),
    paddingRight: 37,
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  cardAction: {
    position: "absolute",
    top: 16,
    right: 12,
  },
  bold: {fontWeight: 500},
  bolder: {fontWeight: 700},
  listItemIcon: {
    minWidth: 0,
    padding: 0,
    paddingRight: theme.spacing(3),
    "&.end": {
      marginRight: -12,
      paddingRight: 0,
    },
  },
  menuArrow: {
    color: theme.palette.grey[300],
    fontSize: 20,
  },
  description: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(3),
  },
  expandBtn: {height: "fit-content"},
  text: {
    flex: 1,
    color: theme.palette.text.primary,
  },
  emptyData: {
    opacity: 0.5,
    borderRadius: 10,
    border: "1px solid #F5F5F5",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(3),
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dragIcon: {
    width: 12,
    cursor: "move",
  },
}));

export default function CollectDetailsPanel({
  group,
  enter_details = [],
  getUpdatedContent = () => {},
  saveCollectedData,
  disableEdit,
}) {
  const classes = useStyles();
  const customOptClicked = React.useRef(false);
  const tempCollectedInfo = React.useRef(null);
  const [edit, setEdit] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [previewData, setPreviewData] = React.useState(null);
  const [fieldsAnchorEl, setFieldsAnchorEl] = React.useState(null);
  const [customFieldsAnchorEl, setCustomFieldsAnchorEl] = React.useState(null);
  const availableFields = React.useMemo(() => {
    return bpCollectFieldsKeys.filter(
      (f) => !data.find((d) => d.field === f && !d.edit),
    );
  }, [data]);
  let selectedFields =
    tempCollectedInfo.current?.map(
      (f) => boardingPassCollectFields[f.field]?.name ?? f.field,
    ) || [];
  let listingId = group.connected_to.ids[0];
  const missingFields = !!data.filter((f) => !f.field).length;

  React.useEffect(() => {
    let newData = Object.assign({}, getUpdatedContent(true));
    newData.enter_details = data.map((d) => {
      let newItem = {...d};
      delete newItem.id;
      return newItem;
    });
    if (!newData) {
      return;
    }
    setPreviewData((prev) => newData);
  }, [data]);

  React.useEffect(() => {
    let newData = enter_details.map((ed) => ({
      id: newUuid(),
      field: ed.field,
      required: ed.required,
      edit: !bpCollectFieldsKeys.includes(ed.field),
      field_type: ed.field_type,
      field_category: ed.field_category ?? BOARDING_PASS_FIELD_TYPES[ed.field],
      field_options: ed.field_options ?? [],
    }));
    tempCollectedInfo.current = newData;
    setData((prev) => newData);
  }, [enter_details]);

  const closeFormDialog = () => setEdit((prev) => false);
  const openFormDialog = () => setEdit((prev) => true);
  const handleDataChange = (newData) => setData((prev) => newData);

  const handleCancelDataChange = () => {
    setData((prev) => tempCollectedInfo.current);
    closeFormDialog();
  };

  const handleSaveCollectedData = () => {
    let dataToSave = data.map((d) => {
      let newItem = {...d};
      delete newItem.id;
      return newItem;
    });
    tempCollectedInfo.current = dataToSave;
    closeFormDialog();
    saveCollectedData(dataToSave);
  };

  const handleFieldsClose = (force) => {
    if (!force) {
      return;
    }
    setFieldsAnchorEl((prev) => null);
  };

  const handleCustomFieldsClose = () => {
    customOptClicked.current = false;
    setCustomFieldsAnchorEl((prev) => null);
    handleFieldsClose(true);
  };

  const handleAddField = (field) => (e) => {
    let el = e.currentTarget;
    if (field === "custom") {
      customOptClicked.current = true;
      setCustomFieldsAnchorEl((prev) => el);
    } else {
      customOptClicked.current = false;
      let fieldCategory = BOARDING_PASS_FIELD_TYPES[field];
      handleCustomFieldsClose();
      handleDataChange(
        data.concat({
          id: newUuid(),
          field: field,
          required: true,
          field_type: collectFieldTypes[fieldCategory].type,
          field_category: fieldCategory,
          field_options: [],
        }),
      );
      handleFieldsClose();
    }
  };

  const handleEditField = (i, field, val) => {
    let newData = [...data];
    newData[i] = {...newData[i], [field]: val};
    handleDataChange(newData);
  };

  const handleRemoveField = (ind, field) => {
    let newData = data.filter((ed, i) => {
      if (ed.field === field && i === ind) {
        return false;
      } else {
        return true;
      }
    });
    handleDataChange(newData);
  };

  const handleCustomFieldClick = (type) => () => {
    let newField = {
      id: newUuid(),
      field: collectFieldTypes[type].defaultValue,
      required: true,
      edit: true,
      field_category: type,
      field_type: collectFieldTypes[type].type,
    };
    customOptClicked.current = false;
    handleDataChange(data.concat(newField));
    handleCustomFieldsClose();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(data);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setData(newItems);
  };

  function getMenuContent() {
    const hiddenFieldTypes = ["email", "phone", "address"];
    let fieldList = Object.keys(collectFieldTypes).filter(
      (f) => !hiddenFieldTypes.includes(f),
    );

    return (
      <MenuList>
        <MenuItem key="select-field" disabled value="">
          Select a field
        </MenuItem>
        {availableFields.map((f) => (
          <MenuItem
            button
            disableRipple
            key={`select-${f}`}
            value={f}
            onClick={handleAddField(f)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FieldTypeIcon type={f} lg />
            </ListItemIcon>
            {["checkin_time", "checkout_time"].includes(f)
              ? `Expected check-${f === "checkin_time" ? "in" : "out"} time`
              : `Guest ${boardingPassCollectFields[f].name}`}
          </MenuItem>
        ))}
        {fieldList.map((cftk) => (
          <MenuItem
            button
            disableRipple
            key={cftk}
            onClick={handleCustomFieldClick(cftk)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FieldTypeIcon type={cftk} lg />
            </ListItemIcon>
            <Typography>{collectFieldTypes[cftk].name}</Typography>
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  function getDialogContent() {
    if (!data.length) {
      return (
        <div className={classes.emptyData}>
          <Typography variant="h1" align="center">
            {"No form fields"}
          </Typography>
        </div>
      );
    } else {
      return (
        <div className={classes.formContainer}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {data.map((f, i) => (
                    <Draggable key={f.id} draggableId={f.id} index={i}>
                      {(provided) => (
                        <Box
                          mb={3}
                          component={"div"}
                          className={classes.row}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SvgIcon
                            viewBox="0 0 14 15"
                            component={DragIcon}
                            className={classes.dragIcon}
                          />
                          <Box flex={1}>
                            <FieldTypeInput
                              field={f}
                              value={
                                !f.edit
                                  ? boardingPassCollectFields[f.field]?.name ||
                                    f.field
                                  : f.field
                              }
                              key={`field-${i}`}
                              onOptionsChange={(options) =>
                                handleEditField(i, "field_options", options)
                              }
                              onValueChange={(e) =>
                                handleEditField(i, "field", e.target.value)
                              }
                              onRequiredChange={(val) =>
                                handleEditField(i, "required", val)
                              }
                              onDelete={() => handleRemoveField(i, f.field)}
                            />
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      );
    }
  }

  function getPreviewContent() {
    if (!edit) {
      return null;
    }
    return (
      <PreviewBPPanel
        type={"details"}
        content={previewData}
        listingId={listingId}
      />
    );
  }

  const fieldsMenu = (
    <CustomMenu
      zIndex={1300}
      open={Boolean(fieldsAnchorEl)}
      anchorEl={fieldsAnchorEl}
      onClose={() => handleFieldsClose(!customOptClicked.current)}
      content={getMenuContent()}
    />
  );

  const formDialog = (
    <CustomDialog
      fullWidth
      disableExit
      maxWidth="lg"
      customHeight={"calc(100% - 20px)"}
      open={!!edit}
      title="Add form"
      onClose={closeFormDialog}
      content={
        <>
          <div style={{display: "flex", marginBottom: 10, marginTop: -20}}>
            <div style={{flex: 1}} />
            <ExpandButton
              hasPopup
              label={`Add field`}
              size="small"
              variant="contained"
              disabled={data.length === 40}
              className={classes.expandBtn}
              onClick={(e) => setFieldsAnchorEl(e.currentTarget)}
            />
          </div>
          {getDialogContent()}
        </>
      }
      labelConfirm="Save"
      disableConfirm={disableEdit || missingFields}
      actionConfirm={handleSaveCollectedData}
      labelCancel="Cancel"
      actionCancel={handleCancelDataChange}
      previewContent={getPreviewContent()}
    />
  );

  return (
    <div>
      {formDialog}
      {fieldsMenu}
      <Box className={classes.row} py={1}>
        <SvgIcon
          className={classes.icon}
          viewBox="0 0 16 16"
          component={ClipboardIcon}
        />
        <Typography variant="h1">{"Guest information collection"}</Typography>
      </Box>
      <Box mt={1} mb={2}>
        <Typography>Collect information from your guests</Typography>
      </Box>
      {!!data?.length || !!tempCollectedInfo.current?.length ? (
        <Card className={classes.card} onClick={openFormDialog}>
          <EditIconButton
            extraClass={clsx(classes.cardAction, "cardActionBtn")}
            onClick={openFormDialog}
          />
          <div>
            <Typography component="span" className={classes.bold}>
              {"Collect "}
            </Typography>
            {selectedFields.map((sf, ind) => (
              <Typography key={sf} component="span" className={classes.bolder}>
                {ind === selectedFields.length - 1 ? ` & ${sf}` : `${sf}, `}
              </Typography>
            ))}
          </div>
        </Card>
      ) : (
        <div className={classes.description}>
          <Typography className={classes.text}>
            {
              "Create a form for the guest to complete during the verification process, the information collected will be available to view in the Guest Information Panel."
            }
          </Typography>
          <PrimaryButton
            label="Add form"
            size="small"
            disabled={disableEdit}
            className={classes.expandBtn}
            onClick={openFormDialog}
          />
        </div>
      )}
    </div>
  );
}
