import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Auth, Storage} from "aws-amplify";
import {Col, Row} from "reactstrap";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import FullLoader from "components/Dialogs/FullLoader";
import ErrorAlert from "core/alerts/ErrorAlert";
import CustomCardHeader from "core/cards/CustomCardHeader";
import FilledTextField from "core/inputs/FilledTextField";
import FilledSelect from "core/selects/FilledSelect";
import CustomTabs from "core/tabs/CustomTabs";
import InputField from "core/inputs/InputField";
import NotificationSettingsPanel from "components/Panels/NotificationSettingsPanel";
import UserScopedListingGroups from "ui/component/admin/users/UserScopedListingGroups.jsx";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import UserTypePanel from "components/Panels/User/UserTypePanel.jsx";
import {
  createAccount,
  deleteAccount,
  editAccount,
  getUserDetails,
} from "redux/actions/accountsActions";
import {useImageCompressor} from "utilities/fileUtilities";
import {newUuid, sleep} from "utilities/helperFunctions";
import {scopeLabels} from "configuration/enums";
import {scopes as constScopes} from "configuration/constants";
import config, {THEME} from "configuration/settings.js";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import UserAccessCodesComponent from "../../ui/component/admin/users/UserAccessCodesComponent";
import {useFlags} from "launchdarkly-react-client-sdk";

const emptyUser = {
  name: "",
  enso_user_email: "",
  phone: null,
  picture: null,
  scopes: {},
  access_code_enabled: false,
  access_code: null,
};
const newUser = {...emptyUser};
const scopes = Object.keys(scopeLabels);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  form: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    overflow: "auto",
    "& > *": {marginBottom: theme.spacing(4)},
  },
  contactInfo: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  avatar: {
    width: 160,
    height: 160,
  },
  marginRight: theme.spacing(4),
  avatarLoading: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    display: "grid",
    placeContent: "center",
  },
  label: {marginBottom: theme.spacing(1)},
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  linkPassword: {"&:hover": {color: theme.palette.primary.dark}},
  linkDelete: {"&:hover": {color: theme.palette.error.dark}},
  passwordForm: {marginBottom: theme.spacing(4)},
  avatarLayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.active,
    opacity: 0,
    transition: "opacity .15s",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {opacity: 1},
  },
  avatarLabel: {color: theme.palette.common.white},
  selected: {fontWeight: 700},
  readOnlyText: {
    display: "block",
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  errorButton: {
    color: THEME.error,
    marginRight: theme.spacing(2),
    borderColor: THEME.error + "!important",
    "&:hover": {backgroundColor: THEME.error + "0a"},
  },
}));

export default function UserPanel({userId, myUser, onClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const users = user_profile.enso_users;
  const create = userId === "new_user";
  const user = create
    ? newUser
    : userId === myUser.enso_user_id
      ? myUser
      : (users.find((u) => u.enso_user_id === userId) ?? emptyUser);

  console.log("USERS OBJECT", user);
  const itsMe = !create && userId === myUser.enso_user_id;
  const canWrite = user_profile?.scopes?.accounts === "write";
  const canRead = user_profile?.scopes?.accounts === "read";
  const prevUser = usePrevious(user);
  const [disabledSave, setDisabledSave] = React.useState(true);
  const [userInfo, setUserInfo] = React.useState({
    name: user.name,
    email: user.enso_user_email,
    dialCode: "1",
    phone: user.phone === "False" ? null : user.phone,
    picture: user.profile_picture,
    scopes: user.scopes ?? {},
    access_code_enabled: user.access_code_enabled,
    access_code: user.access_code,
    access_codes: user?.access_codes || []
  });
  const imageName = React.useRef();
  const [newImage, setNewImage] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [limit, setLimit] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [scopedGroupsEnabled, setScopedGroupsEnabled] = React.useState(false);
  const [dialogSubtitle, setDialogSubtitle] = React.useState("");
  const [error, setError] = React.useState({show: false, message: ""});
  const [phone, setPhone] = React.useState(user.phone);
  const [country, setCountry] = React.useState({
    name: "United States",
    dialCode: "1",
    countryCode: "us",
  });
  const [validPhone, setValidPhone] = React.useState(true);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [notificationsHaveChanged, setNotificationsHaveChanged] =
    React.useState(false);
  const [scopesHaveChanged, setScopesHaveChanged] = React.useState(false);
  const [userTypeSelected, setUserTypeSelected] = React.useState(false);
  const [userNotifs, setUserNotifs] = React.useState(null);
  const [notificationSettings, setNotificationSettings] = React.useState({
    sms_notifications: user.sms_notifications || [],
    email_notifications: user.email_notifications || [],
    mobile_notifications: user.mobile_notifications || [],
  });

  const {t} = useTranslation();
  const flag = useFlags();

  useImageCompressor({
    srcImgs: !!compressEnabled && !!newImage ? [newImage] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  React.useEffect(() => {
    setUserInfo((prev) => ({
      ...prev,
      dialCode:
        prev.dialCode == country.dialCode ? prev.dialCode : country.dialCode,
      phone: prev.phone == phone ? prev.phone : phone,
    }));
  }, [country, phone]);

  // reset menu for user type selection if user clicks off the menu
  React.useEffect(() => {
    return () => {
      setUserTypeSelected(false);
    };
  }, [userId]);

  React.useEffect(() => {
    imageName.current = `profile_pictures/${create ? "new-user_" + newUuid() : userId}`;
    dispatch(
      getUserDetails(userId, (response) => {
        setNotificationSettings((prev) => ({
          sms_notifications: response.sms_notifications || [],
          email_notifications: response.email_notifications || [],
          mobile_notifications: response.mobile_notifications || [],
        }));
        setUserNotifs((prev) => ({
          sms: response.sms_notifications,
          email: response.email_notifications,
          mobile: response.mobile_notifications,
        }));
        setUserInfo((prev) => ({
          ...prev,
          access_codes: response.access_codes,
        }));
      }),
    );
  }, [userId]);

  React.useEffect(() => {
    if (_.isEqual(prevUser, user)) {
      return;
    }
    const sameInfo = isSameInfo();
    !sameInfo &&
      setUserInfo({
        name: user.name,
        email: user.enso_user_email || "",
        phone: user.phone || "",
        picture: user.profile_picture,
        scopes: user.scopes ?? {},
        access_code: user.access_code,
        access_code_enabled: user.access_code_enabled,
        access_codes: user.access_codes
      });
    setPhone(itsMe ? user_profile.phone : (user.phone ?? ""));
    setSelectedLang(user_profile.language);
    setSelectedGroups(user.listing_groups || []);
    setScopedGroupsEnabled(!!user.listing_groups?.length);
    !disabledSave && setDisabledSave(true);
  }, [user]);

  React.useEffect(() => {
    const sameInfo =
      isSameInfo() && !notificationsHaveChanged && !scopesHaveChanged;
    const validFields = validateFields();
    if (sameInfo) {
      !disabledSave && setDisabledSave(true);
    } else {
      if (!validFields && !disabledSave) setDisabledSave(true);
      else if (validFields && disabledSave) setDisabledSave(false);
    }
  }, [userInfo, notificationSettings, selectedGroups, scopedGroupsEnabled]);

  const resetData = () => {
    setSelectedGroups(user.listing_groups || []);
    setUserInfo({
      name: user.name,
      email: user.enso_user_email,
      dialCode: "1",
      phone: user.phone === "False" ? null : user.phone,
      picture: user.profile_picture,
      access_code: user.access_code,
      access_code_enabled: user.access_code_enabled,
      scopes: user.scopes ?? {},
      access_codes: user?.access_codes || []
    });
  };

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setNewImage((prev) => null);
    uploadImg(outputImgs[0]);
  };

  const validateFields = () => {
    const validName = !!userInfo.name?.trim();
    const validEmail = !!userInfo.email?.trim();
    const validUserGroup =
      (scopedGroupsEnabled && selectedGroups.length > 0) ||
      !scopedGroupsEnabled;
    const hasScopes = Object.keys(userInfo.scopes).length;
    return validName && validEmail && validPhone && validUserGroup && hasScopes;
  };

  const isSameInfo = () => {
    const userPhone = user.phone === "False" ? null : user.phone;
    return (
      userInfo.name?.trim() === user.name &&
      userInfo.email?.trim() === user.enso_user_email &&
      userInfo.phone === userPhone &&
      userInfo.picture === user.profile_picture &&
      _.isEqual(user.scopes, userInfo.scopes) &&
      userInfo.access_code_enabled === user.access_code_enabled &&
      userInfo.access_code === user.access_code
    );
  };

  const saveCallback = () => {
    if (create) {
      resetData();
    }
    setLoadingOpen((prev) => false);
    onClose();
  };

  const uploadImg = async (file) => {
    try {
      await Storage.put(imageName.current, file, {
        contentType: file.type,
        progressCallback(progress) {
          let progressProps = {loaded: progress.loaded, total: progress.total};
          setLoadingImg(progressProps);
        },
      });
      setLoadingImg(null);
    } catch (error) {
      setLoadingImg(null);
      setError({
        show: true,
        message:
          "Sorry, there was an error uploading the image, try again with another image",
      });
      console.log(`error uploading image: \n`, error);
    }
  };

  const handleNotificationSettingsChange = (newSettings) => {
    setNotificationsHaveChanged((prev) => true);
    setNotificationSettings(newSettings);
  };

  const saveAccount = (data) => {
    if (create) {
      const body = {
        ...data,
        enso_key: current_user,
      };
      setLoadingOpen((prev) => true);
      if (!!data.profile_picture) {
        setDialogSubtitle("Uploading image");
        sleep(250).then(() => setCompressEnabled((prev) => true));
      }
      setDialogSubtitle("Granting permissions");
      dispatch(createAccount({body}, saveCallback, saveCallback));
    }
    if (!create) {
      const body = {
        enso_key: current_user,
        enso_user_id: user.enso_user_id,
        data,
      };
      setDialogSubtitle("");
      setDisabledSave(true);
      dispatch(
        editAccount({
          body: {body},
          onSuccess: saveCallback,
          onError: saveCallback,
        }),
      );
    }
  };

  const handleSave = async () => {
    setDisabledSave(true);
    const hasNewImage = create
      ? !!userInfo.picture
      : userInfo.picture !== user.profile_picture;
    const data = {
      name: userInfo.name.trim(),
      listing_groups: scopedGroupsEnabled ? selectedGroups : [],
      phone: validPhone ? userInfo.phone : user.phone,
      scopes: userInfo.scopes,
      access_code_enabled: userInfo.access_code_enabled,
      access_code: userInfo.access_code,
      ...notificationSettings,
    };

    if (create) {
      data.enso_user_email = userInfo.email.trim();
      data.profile_picture = hasNewImage
        ? `${config.s3.URL}${imageName.current}`
        : "";
      saveAccount(data);
    } else {
      data.enso_user_email = userInfo.email.trim();
      data.profile_picture = hasNewImage
        ? `${config.s3.URL}${imageName.current}`
        : user.profile_picture;
      setLoadingOpen(true);
      if (hasNewImage) {
        setDialogSubtitle("Uploading image");
        sleep(250).then(() => setCompressEnabled((prev) => true));
      }
      if (itsMe) {
        data.language = selectedLang;
      }
      saveAccount(data);
    }
  };

  const deleteCallback = () => {
    setLoadingDelete((prev) => false);
    onClose();
  };

  const handleDelete = () => {
    setLoadingDelete((prev) => true);
    dispatch(
      deleteAccount(
        current_user,
        user.enso_user_id,
        deleteCallback,
        deleteCallback,
      ),
    );
  };

  const handleChange = (event) => {
    event.persist();
    setUserInfo((prev) => ({...prev, [event.target.id]: event.target.value}));
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setNewImage(file);
      setUserInfo((prev) => ({...prev, picture: reader.result}));
    };
    reader.readAsDataURL(file);
  };

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      setLimit("New password does not match");
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) => {
          setLimit(false);
          setPassword(true);
          setNewPassword("");
          setConfirmPassword("");
          setOldPassword("");
        })
        .catch((err) => {
          console.log(err, "err");
          if (err.code === "NotAuthorizedException") {
            setLimit("Error: Wrong password... please try again");
          } else if (err.code === "InvalidParameterException") {
            setLimit(
              "Error: Passwords must have a length greater than or equal to 6",
            );
          } else if (err.code === "LimitExceededException") {
            setLimit("Error: " + err.message);
          } else {
            setLimit(err.message);
          }
        });
    }
  };

  const handleScopeChange = (scope) => (e) => {
    const val = e.target.value;
    setUserInfo((prev) => ({...prev, scopes: {...prev.scopes, [scope]: val}}));
  };

  const handleClose = () => {
    if (dialogOpen === "password") {
      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
    }
    setDialogOpen(false);
  };

  const handleErrorClose = () => setError({show: false, message: ""});

  const renderTextField = (value, onChange, id, placeholder, label, type) => {
    return (
      <FilledTextField
        id={id}
        placeholder={placeholder}
        label={label}
        type={type}
        value={value}
        onChange={onChange}
        fullWidth
      />
    );
  };

  if (!user) return null;

  const getPasswordDialogContent = () => {
    switch (dialogOpen) {
      case "password":
        return (
          <>
            <DialogTitle id="dialog-title" style={{paddingBottom: "0"}}>
              Change Password
            </DialogTitle>
            <DialogContent>
              <form
                className={classes.passwordForm}
                noValidate
                autoComplete="off"
              >
                <div>
                  {renderTextField(
                    oldPassword,
                    (e) => setOldPassword(e.target.value),
                    "old-password",
                    "",
                    "Old Password",
                    "password",
                  )}
                </div>
                <div style={{marginTop: 5}}>
                  {renderTextField(
                    newPassword,
                    (e) => setNewPassword(e.target.value),
                    "new-password",
                    "",
                    "New Password",
                    "password",
                  )}
                </div>
                <div style={{marginTop: 5}}>
                  {renderTextField(
                    confirmPassword,
                    (e) => setConfirmPassword(e.target.value),
                    "confirm-password",
                    "",
                    "Confirm Password",
                    "password",
                  )}
                </div>
              </form>
              {limit && (
                <div style={{fontSize: "15px", color: "red"}}>{limit}</div>
              )}
              {password && (
                <div style={{fontSize: "20px", color: "green"}}>
                  Password Changed
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleChangePassword}
                color="primary"
                autoFocus
                disabled={
                  !oldPassword ||
                  !newPassword ||
                  !confirmPassword ||
                  newPassword !== confirmPassword
                }
              >
                {"Confirm"}
              </Button>
            </DialogActions>
          </>
        );
      case "confirm-delete":
        return (
          <>
            <DialogTitle id="dialog-title" style={{paddingBottom: "0"}}>
              Delete User?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {
                  "This user will be permanently deleted and will not be able to log in again"
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {"Cancel"}
              </Button>
              <Button onClick={handleDelete} color="primary">
                {"Delete"}
              </Button>
            </DialogActions>
          </>
        );
    }
  };

  const loadingDeleteModal = (
    <FullLoader
      open={loadingDelete}
      disableDismiss
      onClose={() => setLoadingDelete((prev) => false)}
      loadingText="Deleting user..."
    />
  );

  const tabsList = ["Notifications", "Permissions", "Assigned Listing Groups"];

  if (flag.userDeviceCodes) {
    tabsList.push(t("user-device-codes-tab"));
  }
  return (
    <div className={classes.root}>
      {loadingDeleteModal}
      <FullLoader
        open={loadingOpen}
        loadingText="Saving Changes..."
        subtitle={dialogSubtitle}
        progress={loadingImg}
      />
      <ErrorAlert
        open={error.show}
        onClose={handleErrorClose}
        message={error.message}
      />
      <Dialog
        open={!!dialogOpen}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        fullWidth
        maxWidth="xs"
      >
        {getPasswordDialogContent()}
      </Dialog>
      <CustomCardHeader
        title={create ? "Create User" : "Edit Profile"}
        type="title"
        className={classes.header}
        action={
          <>
            {!create && canWrite && (
              <Button
                className={classes.errorButton}
                variant="outlined"
                onClick={() => setDialogOpen("confirm-delete")}
              >
                {"Delete User"}
              </Button>
            )}
            <Button
              className="mr-2"
              classes={{label: classes.cancelBtn}}
              size="small"
              onClick={onClose}
            >
              Cancel
            </Button>
            {!create && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                disableElevation
                disabled={disabledSave}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
          </>
        }
      />

      <div style={{height: "100%", overflow: "scroll"}}>
        <div className={classes.content} style={{display: "flex"}}>
          <Box>
            <input
              id="avatarInput"
              style={{display: "none"}}
              type="file"
              accept="image/*"
              onChange={handleImage}
            />
            <label htmlFor="avatarInput">
              <div style={{position: "relative"}}>
                <Avatar
                  className={classes.avatar}
                  variant="rounded"
                  alt={user.name}
                  src={userInfo.picture}
                />
                <div className={classes.avatarLayer}>
                  <Typography variant="h1" className={classes.avatarLabel}>
                    EDIT
                  </Typography>
                </div>
              </div>
            </label>
          </Box>
          <div
            id="user-info-form"
            className={classes.contactInfo}
            style={{marginLeft: "40px"}}
          >
            <Box>
              {renderTextField(
                userInfo.name,
                handleChange,
                "name",
                "John Smith",
                "Name",
              )}
            </Box>
            <Box>
              {renderTextField(
                userInfo.email,
                handleChange,
                "email",
                "user@example.com",
                "Email Address",
              )}
            </Box>
            <Box>
              <InputField
                fullWidth
                editOnly
                type="phone"
                label="Phone (Optional)"
                value={phone}
                disableEnterSave
                onChange={setPhone}
                isInvalid={!validPhone}
                phoneInputProps={{
                  allowEmpty: true,
                  setValidPhone: setValidPhone,
                  setCountry: setCountry,
                }}
              />
            </Box>
            {itsMe && (
              <Box>
                <LanguageSelector
                  plain
                  preventOverflow
                  selectedLanguage={selectedLang}
                  setSelectedLang={(e) => {
                    setSelectedLang(e);
                    setDisabledSave(false);
                  }}
                />
              </Box>
            )}
            {!create && (
              <Box>
                <Typography className={classes.label} color="textSecondary">
                  Password
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={clsx(classes.link, classes.linkPassword)}
                  onClick={() => setDialogOpen("password")}
                >
                  {"Change Password"}
                </Typography>
              </Box>
            )}
            {create && (
              <Box>
                <Button
                  id="send-invitation-btn"
                  color="primary"
                  variant="contained"
                  disabled={disabledSave}
                  onClick={handleSave}
                >
                  {"Send Invitation Mail"}
                </Button>
              </Box>
            )}
          </div>
        </div>
        {!userTypeSelected && create && (
          <UserTypePanel
            setUserTypeSelected={setUserTypeSelected}
            setScopedGroupsEnabled={setScopedGroupsEnabled}
            setSelectedTab={setSelectedTab}
            setUserInfo={setUserInfo}
          ></UserTypePanel>
        )}

        {(userTypeSelected || !create) && (
          <div className={classes.content}>
            <CustomTabs
              disableGutters
              selectedTab={selectedTab}
              onChange={setSelectedTab}
              tabs={tabsList}
            />
            {
              {
                0: (
                  <>
                    <NotificationSettingsPanel
                      user={user}
                      userNotifs={userNotifs}
                      triggerList={notificationSettings}
                      setTriggerList={handleNotificationSettingsChange}
                      country={country}
                    />
                  </>
                ),
                1: (
                  <>
                    <Box mt={2}>
                      <Row>
                        {scopes.map((s) => (
                          <Col key={s} xs={12} className="mb-2">
                            <FilledSelect
                              fullWidth
                              displayEmpty
                              value={userInfo.scopes[s] || ""}
                              label={constScopes[s].label}
                              onChange={handleScopeChange(s)}
                            >
                              <MenuItem value="" disabled>
                                Select an option
                              </MenuItem>
                              <MenuItem
                                value="hide"
                                selected={userInfo.scopes[s] === "hide"}
                                classes={{selected: classes.selected}}
                              >
                                {scopeLabels[s].hide}
                              </MenuItem>

                              {!!scopeLabels[s].read && (
                                <MenuItem
                                  value="read"
                                  selected={userInfo.scopes[s] === "read"}
                                  classes={{selected: classes.selected}}
                                  disabled={user_profile.scopes[s] === "hide"}
                                >
                                  {scopeLabels[s].read}
                                </MenuItem>
                              )}

                              <MenuItem
                                value="write"
                                selected={userInfo.scopes[s] === "write"}
                                classes={{selected: classes.selected}}
                                disabled={user_profile.scopes[s] !== "write"}
                              >
                                {scopeLabels[s].write}
                              </MenuItem>
                            </FilledSelect>
                          </Col>
                        ))}
                      </Row>
                    </Box>
                  </>
                ),
                2: (
                  <UserScopedListingGroups
                    selectedListings={selectedGroups}
                    setSelectedListings={(e) => {
                      setSelectedGroups(e);
                      setScopesHaveChanged(true);
                    }}
                    scopedGroupsEnabled={scopedGroupsEnabled}
                    setScopedGroupsEnabled={(e) => {
                      setScopedGroupsEnabled(!scopedGroupsEnabled);
                      setScopesHaveChanged(true);
                    }}
                  />
                ),
                3: (
                  <UserAccessCodesComponent
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                  />
                ),
              }[selectedTab]
            }
          </div>
        )}
      </div>
    </div>
  );
}
