import React from "react";
import Container from "../layout/Container";
import Text from "../display/Text";
import {buildStyles} from "../buildStyles";
import {useTheme} from "../../theme/ThemeProvider";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    listContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    listLabel: {
      fontSize: theme.typography.textSM.fontSize,
      fontWeight: theme.typography.textSM.semibold.fontWeight,
      color: theme.colors.textPrimary,
    },
    list: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      margin: 0,
    },
  });

/**
 * ListComponent is a component that renders a list with an optional label and custom indentation and spacing.
 * It displays a list of items with support for customization through the `label`, `indent`, and `gap` props.
 *
 * @param {Object} props - The props for the ListComponent.
 * @param {string} [props.label] - The label to display above the list.
 * @param {Object} [props.labelProps] - Additional properties to pass to the label element.
 * @param {React.ReactNode} props.children - The list items to display inside the component.
 * @param {string} [props.indent] - The amount of indentation for the list items.
 * @param {string} [props.gap] - The gap between the list items.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered ListComponent.
 */
const ListComponent = ({
  label,
  labelProps,
  children,
  indent,
  gap,
  ...props
}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  return (
    <Container className={classes.listContainer} {...props}>
      {label && (
        <Text className={classes.listLabel} {...labelProps}>
          {label}
        </Text>
      )}
      <Container
        className={classes.list}
        sx={{
          marginLeft: indent ? indent : undefined,
          gap: gap ? gap : theme.spacing(3),
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

ListComponent.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.object,
  children: PropTypes.node,
  indent: PropTypes.string,
  gap: PropTypes.string,
};

export default ListComponent;
