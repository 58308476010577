import React from "react";
import ChipBase from "./base/ChipBase";
import Text from "../Text";
import PropTypes from "prop-types";

/**
 * ChipText is a component that renders a chip with text inside.
 * It uses the provided ChipBase component or defaults to the ChipBase component.
 *
 * @param {Object} props - The props for the ChipText component.
 * @param {React.ReactNode} props.text - The text to display inside the chip.
 * @param {string} [props.typographyVariant="textSM"] - The typography variant to apply to the text.
 * @param {string} [props.weight="regular"] - The font weight for the text.
 * @param {string} [props.color="primary600"] - The font color for the text.
 * @param {function} [props.chipBase=ChipBase] - The component to render for the chip base (defaults to ChipBase).
 * @param {Object} [props.sx={}] - The style object that can be used to override default styles.
 * @param {Object} props.rest - Any other props that should be passed to the ChipBase component.
 *
 * @returns {JSX.Element} The rendered ChipText component.
 */
const ChipText = ({
  text,
  typographyVariant = "textSM",
  weight = "medium",
  color = "primary600",
  chipBase: ChipBaseComponent = ChipBase,
  sx = {},
  ...props
}) => {
  return (
    <ChipBaseComponent sx={sx} {...props}>
      <Text as="div" variant={typographyVariant} weight={weight} color={color}>
        {text}
      </Text>
    </ChipBaseComponent>
  );
};

ChipText.propTypes = {
  text: PropTypes.node.isRequired,
  typographyVariant: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  chipBase: PropTypes.func,
  sx: PropTypes.any,
};
export default ChipText;
