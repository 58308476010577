import React from "react";
import ButtonBase from "./base/ButtonBase";
import PropTypes from "prop-types";

/**
 * ButtonIcon is a component that renders a button with an optional icon inside.
 * It uses the provided ButtonBase component or defaults to ButtonBase and allows customization through the `sx` prop.
 *
 * @param {Object} props - The props for the ButtonIcon component.
 * @param {React.ElementType} [props.buttonBase=ButtonBase] - The component to render for the button base (defaults to ButtonBase).
 * @param {React.ReactNode} [props.icon=null] - The icon to display inside the button.
 * @param {Object} [props.sx={}] - The style object that can be used to override default button styles.
 * @param {Object} props.rest - Any other props that should be passed to the ButtonBase component.
 *
 * @returns {JSX.Element} The rendered ButtonIcon component.
 */
const ButtonIcon = ({
  buttonBase: ButtonBaseComponent = ButtonBase,
  icon = null,
  sx = {},
  ...props
}) => {
  return (
    <ButtonBaseComponent sx={sx} {...props}>
      {icon && icon}
    </ButtonBaseComponent>
  );
};

ButtonIcon.propTypes = {
  buttonBase: PropTypes.any,
  icon: PropTypes.node,
  sx: PropTypes.any,
};

export default ButtonIcon;
