import React, {createContext, useEffect, useState} from "react";
import useViewportWidth from "hooks/useViewportWidth";
// Styles & icons
import {useTheme} from "../../connectui/theme/ThemeProvider";
import {buildStyles} from "../../connectui/component/buildStyles";
import {ReactComponent as ArrowLeftIcon} from "connectui/icons/chevron-left.svg";
import {ReactComponent as ArrowRightIcon} from "connectui/icons/chevron-right.svg";
// Panels
import MessageThreadsPanel from "ui/component/inbox/panel/MessageThreadsPanel";
import FilterSetsPanel from "ui/component/inbox/panel/FilterSetsPanel";
import GuestDetailsPanel from "ui/component/inbox/panel/GuestDetailsPanel";
import FiltersPanel from "ui/component/inbox/panel/FiltersPanel";
import ChatPanel from "ui/component/inbox/panel/ChatPanel";
// Components
import Container from "../../connectui/component/layout/Container";
import VerticalPanelLayout from "connectui/component/layout/VerticalPanelLayout";
import SvgIcon from "connectui/component/display/SvgIcon";
import ButtonIcon from "../../connectui/component/input/button/ButtonIcon";
import SaveViewPanel from "ui/component/inbox/panel/SaveViewPanel";
import EditViewPanel from "ui/component/inbox/panel/EditViewPanel";

const PANEL_STYLES = [
  {minWidth: "260px", maxWidth: "310px"}, // filter_sets
  {minWidth: "280px", maxWidth: "345px"}, // threads
  {minWidth: "400px"}, // chat
  {minWidth: "370px", maxWidth: "400px"}, // guest_details
];

const useStyles = (theme) =>
  buildStyles({
    outerContainer: {
      backgroundColor: theme.colors.white,
      width: "100%",
      height: "100vh",
      filter: "drop-shadow(0px 1px 2px rgba(10, 13, 18, 0.05))",
      display: "flex",
      flexDirection: "row",
    },
    iconBtn: {
      boxShadow: theme.shadows.default,
      borderRadius: theme.spacing(2),
      border: `1px solid ${theme.colors.border}`,
      backgroundColor: theme.colors.white,
      cursor: "pointer",
      padding: "0px",
      "&:hover": {backgroundColor: theme.colors.hoverGray},
    },
    icon: {
      width: "24px",
      height: "24px",
      justifyContent: "center",
      padding: "5px",
    },
  });

const defaultContext = {
  appliedFilters: [],
  setAppliedFilters: () => {},
};

export const InboxContext = createContext(defaultContext);

const Inbox = () => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const width = useViewportWidth({useBreakpointLabels: true});
  const isLG = width === "lg" || width === "md";
  const [showFilterSets, setShowFilterSets] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [viewPanelType, setViewPanelType] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [hideGuestPanel, setHideGuestPanel] = useState(!isLG && showFilterSets);
  const searchParams = new URLSearchParams(window.location.search);
  const viewId = searchParams.get("view_id");

  useEffect(() => {
    if (!isLG) {
      if (showFilterSets && !hideGuestPanel) {
        setHideGuestPanel(true);
      }
    } else if (hideGuestPanel) {
      setHideGuestPanel(false);
    }
  }, [width, showFilterSets, hideGuestPanel]);

  const toggleGuestDetailsPanelOpen = () => {
    setHideGuestPanel((prev) => !prev);
    if (!isLG) {
      setShowFilterSets((prev) => !prev);
    }
  };

  const toggleFilterSetsPanelOpen = () => {
    setShowFilterSets((prev) => !prev);
    if (!isLG) {
      setHideGuestPanel((prev) => !prev);
    }
  };

  const handleEditView = (filterSet) => {
    setViewPanelType("edit");
    setShowFilterSets(false);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?view_id=${filterSet.id}`,
    );
  };

  const handleCloseView = (keepViewsOpen) => {
    if (keepViewsOpen) {
      setShowFilterSets(true);
      if (!isLG) {
        setHideGuestPanel(true);
      }
      window.history.pushState({}, "", window.location.pathname);
    }
    setViewPanelType(null);
  };

  const handleSaveView = (filters) => {
    setAppliedFilters(filters);
    setFiltersOpen(false);
    setViewPanelType("create");
    setShowFilterSets(false);
  };

  const expandArrow = (
    <ButtonIcon
      icon={
        <SvgIcon
          component={showFilterSets ? ArrowLeftIcon : ArrowRightIcon}
          className={classes.icon}
        />
      }
      className={classes.iconBtn}
      onClick={toggleFilterSetsPanelOpen}
    />
  );

  return (
    <InboxContext.Provider value={{appliedFilters, setAppliedFilters}}>
      <Container className={classes.outerContainer}>
        <VerticalPanelLayout
          panels={[
            showFilterSets && !viewPanelType ? (
              <FilterSetsPanel
                expandArrow={showFilterSets ? expandArrow : null}
                editView={handleEditView}
              />
            ) : null,
            viewPanelType === "edit" ? (
              <EditViewPanel onClose={handleCloseView} />
            ) : viewPanelType === "create" ? (
              <SaveViewPanel onClose={handleCloseView} />
            ) : filtersOpen ? (
              <FiltersPanel
                onClose={() => setFiltersOpen(false)}
                saveView={handleSaveView}
              />
            ) : (
              <MessageThreadsPanel
                openFilters={() => setFiltersOpen(true)}
                expandArrow={!showFilterSets ? expandArrow : null}
              />
            ),
            <ChatPanel
              panelOpen={!hideGuestPanel}
              togglePanelOpen={toggleGuestDetailsPanelOpen}
            />,
            !hideGuestPanel ? <GuestDetailsPanel /> : null,
          ]}
          panelStyles={PANEL_STYLES}
        />
      </Container>
    </InboxContext.Provider>
  );
};

export default Inbox;
