import React, {useEffect, useState, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Storage} from "aws-amplify";
// UI
import {
  Button,
  makeStyles,
  Typography,
  InputAdornment,
  MenuItem,
  MenuList,
  IconButton,
  Box,
  Chip,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Popover from "@material-ui/core/Popover";
// Actions
import {
  createBranding,
  updateBranding,
  deleteBranding,
} from "redux/actions/accountsActions";
// Custom
import CustomMenu from "core/menus/CustomMenu";
import InputField from "core/inputs/InputField";
import DragAndDropFile from "core/inputs/DragAndDropFile";
import ExpandButton from "core/buttons/ExpandButton";
import Country from "components/TextFields/Country";
// Utils
import clsx from "clsx";
import {useFlags} from "launchdarkly-react-client-sdk";
import {HexColorPicker} from "react-colorful";
import config, {THEME} from "configuration/settings.js";
import {convertToPNG, useImageCompressor} from "utilities/fileUtilities";
import {sleep, validateFile} from "utilities/helperFunctions";
import {capitalize, newUuid} from "utilities/helperFunctions.js";
import {useTranslation} from "react-i18next";
import ScopedListingGroupsModal from "../../components/Dialogs/ScopedListingGroupsModal";
import CustomCardHeader from "../../core/cards/CustomCardHeader";
import CloseIcon from "@material-ui/icons/Close";
import theme from "../../configuration/theme";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import FullLoader from "../../components/Dialogs/FullLoader";
import {
  closeGeneralError,
  openGeneralError,
} from "../../redux/actions/settingsActions";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    flex: 1,
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },

  dashed: {
    border: `2px dashed #d2d2d2`,
    borderRadius: 10,
    width: "100%",
    maxWidth: 300,
    height: 200,
    display: "grid",
    placeContent: "center",
    backgroundColor: "#F6F9FF",
  },
  divider: {backgroundColor: "rgba(13, 86, 140, 0.05)"},
  chipRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
  icon: {
    fontSize: 60,
    justifySelf: "center",
    marginBottom: 10,
  },
  subtitle: {
    marginTop: THEME.spacing.lg,
    marginBottom: THEME.spacing.sm,
  },
}));

const all_profiles = [
  "facebook",
  "instagram",
  "twitter",
  "youtube",
  "tiktok",
  "threads",
  "snapchat",
  "pinterest",
  "linkedin",
];

function SocialProfilesEditor({profiles, disabled, setProfiles}) {
  const [fieldsAnchorEl, setFieldsAnchorEl] = useState(null);
  const [availableProfiles, setAvailableProfiles] = useState([]);

  useEffect(() => {
    setAvailableProfiles(
      all_profiles.filter((p) => !profiles.find((f) => f.account_type === p)),
    );
  }, [profiles]);

  function handleAddProfile(f) {
    setFieldsAnchorEl(null);
    setProfiles([
      ...profiles,
      {
        account_type: f,
        url: "",
      },
    ]);
  }

  function handleRemoveProfile(f) {
    setProfiles(profiles.filter((p) => p.account_type !== f));
  }

  function handleEditProfile(i, val) {
    let new_profiles = Object.assign([], profiles);
    new_profiles[i].url = val;
    setProfiles(new_profiles);
  }

  return (
    <div style={{marginTop: THEME.spacing.xs}}>
      {profiles.map((p, i) => {
        return (
          <div
            key={p.account_type}
            style={{display: "flex", marginBottom: THEME.spacing.md}}
          >
            <InputField
              fullWidth
              editOnly
              disableEnterSave
              disableMultiline
              placeholder={`https://${p.account_type}.com/yourprofile`}
              label={capitalize(p.account_type)}
              value={p.url}
              onChange={(e) => handleEditProfile(i, e.target.value)}
              inputProps={{
                disabled: disabled,
              }}
            />
            <IconButton
              disabled={disabled}
              onClick={() => handleRemoveProfile(p.account_type)}
            >
              <DeleteIcon id={`delete${p.account_type}`} />
            </IconButton>
          </div>
        );
      })}
      <CustomMenu
        open={Boolean(fieldsAnchorEl)}
        anchorEl={fieldsAnchorEl}
        onClose={() => setFieldsAnchorEl(null)}
        content={
          <MenuList>
            {availableProfiles.map((f) => (
              <MenuItem
                key={`select-${f}`}
                value={f}
                onClick={() => handleAddProfile(f)}
              >
                {capitalize(f)}
              </MenuItem>
            ))}
          </MenuList>
        }
      />
      {!!availableProfiles.length && (
        <ExpandButton
          hasPopup
          id="social-media-profile"
          label={`Add social media profile`}
          size="small"
          variant="contained"
          disabled={disabled}
          onClick={(e) => setFieldsAnchorEl(e.currentTarget)}
        />
      )}
    </div>
  );
}

export default function Branding({
  branding,
  setBranding,
  showCreateButton = true,
  showSaveButton = true,
  onClose = null,
  showDeleteButton = false,
  displayCloseButton = true,
  onUpdateItemList,
  onUpdateDefaultBrand,
}) {
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  // const [branding, setBranding] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [brandingLogo, setBrandingLogo] = useState(null);
  const imageRef = React.useRef(null);
  const [, setError] = useState(null);
  const classes = useStyles();
  const [country, setCountry] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedColor, setSelectedColor] = React.useState(null);
  const [primaryColor, setPrimaryColor] = useState("");
  const [primaryColorError, setPrimaryColorError] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [secondaryColor, setSecondaryColor] = useState("");
  const [secondaryColorError, setSecondaryColorError] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");
  const [directBookingUrl, setDirectBookingUrl] = useState("");
  const [customKeycardUrl, setCustomKeycardUrl] = useState("");
  const [brandName, setBrandName] = useState();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [saveButton, setSaveButton] = React.useState({
    enabled: false,
    label: "Saved",
  });
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [scopedGroupsModalOpen, setScopedGroupsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const isNewRoot = branding?.id === "brand#root";
  const isOldRoot = branding?.id === "root#brand"; // will be deprecated
  const isNewBrand = branding?.id === "brand#new_item";
  const {t} = useTranslation();
  const flag = useFlags();

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
    fileInObject: true,
  });

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setImgToUpload((prev) => null);
    setBrandingLogo((prev) => ({
      file: outputImgs[0].file,
      picture: outputImgs[0].picture,
      description: [{language: "en", value: ""}],
    }));
  };

  const loadImages = async (file) => {
    await handleFile(file);
  };

  // This is a way to handle the case the id doesnt match the brandId
  useEffect(() => {
    if (branding?.id !== brandId) {
      setBrandName(branding?.name || "");
      setPrimaryColor(branding?.color1 || "FFFFFF");
      setSecondaryColor(branding?.color2 || "000000");
      setCountry(branding?.country || "");
      setEmailSignature(branding?.email_signature || "");
      setDirectBookingUrl(branding?.direct_booking_url || "");
      setCustomKeycardUrl(branding?.custom_keycard_url || "");
      setProfiles(branding?.social_profiles || []);
      setSelectedGroups(branding.group_ids || []);

      setBrandingLogo(
        branding?.logo
          ? {
              file: null,
              picture: branding?.logo,
              description: [{language: "en", value: ""}],
            }
          : null,
      );
      setBrandId(branding?.id);
    }
  }, [branding, brandId]);

  const handleFile = (file) => {
    if (!file) {
      return;
    }
    setSaveButton({enabled: true, label: "Save"});
    return new Promise((resolve) => {
      // file = convertedFile;
      const {isValid, error} = validateFile(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (isValid) {
          setImgToUpload((prev) => ({
            file,
            picture: e.target.result,
            description: [{language: "en", value: ""}],
          }));
          sleep(250).then(() => setCompressEnabled((prev) => true));
        } else {
          displayError(null, "custom", error);
        }
        resolve(true);
      };
    });
  };

  function setColor(val) {
    let func = selectedColor === 1 ? setPrimaryColor : setSecondaryColor;
    let errfunc =
      selectedColor === 1 ? setPrimaryColorError : setSecondaryColorError;
    if (val.length < 7 && val.match(/[^A-Fa-f0-9]/g) === null) {
      if (val.length == 6) {
        setSaveButton({enabled: true, label: "Save"});
        errfunc(false);
      } else {
        errfunc(true);
        setSaveButton({enabled: false, label: "Save"});
      }
      func(val.toUpperCase());
    }
  }

  const handleInvalidForm = () => {
    dispatch(
      openGeneralError({
        message: "Missing fields",
        subtitle:
          "Brand name and country are required fields. Please fill them out and try again.",
        disableSeverity: true,
        onClose: () => dispatch(closeGeneralError()),
      }),
    );
  };

  async function createBrand() {
    if (!brandName?.trim() || !country) {
      handleInvalidForm();
      return null;
    }
    setLoading(true);
    let params = {
      name: brandName,
      color1: primaryColor,
      country: country,
      color2: secondaryColor,
      email_signature: emailSignature,
      direct_booking_url: directBookingUrl,
      custom_keycard_url: customKeycardUrl,
      social_profiles: profiles,
      listing_group_ids: selectedGroups,
    };
    if (!flag.customBoardingPassUrl) {
      delete params.custom_keycard_url;
    }
    if (!!brandingLogo) {
      if (brandingLogo.file) {
        let imageResponse = await Storage.put(
          `${user_profile.enso_key}/${newUuid()}`,
          brandingLogo.file,
          {contentType: brandingLogo.file.type},
        );
        params.logo = config.s3.URL + imageResponse.key;
      } else if (brandingLogo.picture) {
        params.logo = brandingLogo.picture;
      }
    }

    dispatch(
      createBranding(
        params,
        function (resp) {
          if (onUpdateItemList) {
            onUpdateItemList(resp, "create");
          }
          if (onUpdateItemList) {
            onUpdateItemList("brand#new_item", "delete");
          }

          if (onClose) {
            onClose();
          }
          setLoading(false);
        },
        function () {
          setLoading(false);
        },
      ),
    );
  }

  async function updateBrand() {
    if (!brandName?.trim() || !country) {
      handleInvalidForm();
      return null;
    }
    setSaveButton({label: "Saved", enabled: false});
    let params = {
      brand_id: branding?.id,
      name: brandName,
      color1: primaryColor,
      country: country,
      color2: secondaryColor,
      email_signature: emailSignature,
      direct_booking_url:
        directBookingUrl.startsWith("https://") ||
        directBookingUrl.startsWith("http://")
          ? directBookingUrl
          : `https://${directBookingUrl}`,
      custom_keycard_url:
        customKeycardUrl.startsWith("https://") ||
        customKeycardUrl.startsWith("http://")
          ? customKeycardUrl
          : customKeycardUrl
            ? `https://${customKeycardUrl}`
            : "",
      social_profiles: profiles,
      logo: "",
    };
    if (!flag.customBoardingPassUrl) {
      delete params.custom_keycard_url;
    }
    if (branding?.id !== "brand#root" && branding?.id !== "root#brand") {
      params.listing_group_ids = selectedGroups || [];
    }

    if (!!brandingLogo) {
      if (brandingLogo.file) {
        let imageResponse = await Storage.put(
          `${user_profile.enso_key}/${newUuid()}`,
          brandingLogo.file,
          {contentType: brandingLogo.file.type},
        );
        params.logo = config.s3.URL + imageResponse.key;
      } else if (brandingLogo.picture) {
        params.logo = brandingLogo.picture;
      }
    }
    setLoading(true);
    dispatch(
      updateBranding(params, isOldRoot, function (resp) {
        if (onUpdateItemList) {
          onUpdateItemList(resp, "update");
        } else {
          onUpdateDefaultBrand(resp);
        }
        if (onClose) {
          onClose();
        }
        setLoading(false);
      }),
    );
  }

  const displayError = (picture, errorType, customMessage) => {
    let message = "There was an error, please try again again later.";
    switch (errorType) {
      case "info":
        message = `The file ${!!picture ? picture.name + " " : ""}could not be uploaded. Make sure to upload a valid image file and try again.`;
        break;
      case "s3":
        message = "An error occurred while uploading the file. Try again.";
        break;
      case "delete":
        message =
          "An error occurred while deleting this picture. Try again later.";
        break;
      case "multiple_uploads":
        message =
          "Some files could not be uploaded. Make sure to upload valid files and try again.";
        break;
      case "exceeded":
        message =
          "Some files were not uploaded because the maximum number of images allowed was exceeded.";
        break;

      case "custom":
        message = customMessage || message;
        break;

      default:
        message =
          "Something went wrong while uploading this file. There was an internal error, please try again later.";
        break;
    }
    setError({show: true, message});
  };

  const loadingModal = (
    <FullLoader
      open={loading}
      disableDismiss
      onClose={() => setLoading((prev) => false)}
      loadingText="Updating..."
    />
  );

  return (
    <div className={classes.container}>
      <CustomCardHeader
        title={t("branding-settings-section-title").replace(
          "{brand}",
          branding?.name || "New Branding",
        )}
        type="header"
        dividers
        className={classes.header}
        classes={{
          subheader: classes.subheader,
          action: classes.actions,
        }}
        action={
          <>
            {showCreateButton && (
              // I am sorry to anybody who has the displeasure of reading this code
              // on click, update the component (isCreatePage = true, showCreateButton = false) and reset branding
              <Button
                size="small"
                color="primary"
                variant="contained"
                style={{marginRight: 15}}
                onClick={() => {
                  if (onUpdateItemList) {
                    createBrand();
                  } else {
                    setBranding(null);
                    setBrandId(null);
                    setBrandName("");
                    setPrimaryColor("");
                    setSecondaryColor("");
                    setCountry("");
                    setEmailSignature("");
                    setDirectBookingUrl("");
                    setCustomKeycardUrl("");
                    setProfiles([]);
                    setSelectedGroups([]);

                    // setSelectedListingGroups([])
                    setBrandingLogo(null);
                    let list = [
                      branding,
                      {id: "brand#new_item", name: "New Branding"},
                    ];
                    setBranding(list);
                  }
                }}
              >
                {t("multi-branding-save-as-new")}
              </Button>
            )}
            {showSaveButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateBrand()}
                disabled={!saveButton.enabled}
              >
                {saveButton.label}
              </Button>
            )}
            {displayCloseButton && (
              <IconButton className={classes.closeBtn} onClick={onClose}>
                <CloseIcon className={classes.closeBtnIcon} />
              </IconButton>
            )}
          </>
        }
      />
      <ConfirmDialog
        disableDismiss
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        title={`Permanently delete branding?`}
        message={
          <span style={{whiteSpace: "pre-wrap"}}>
            {"This cannot be undone"}
          </span>
        }
        confirmLabel="Permanently delete"
        confirmAction={() => {
          setConfirmDeleteOpen(false);
          setLoading((prev) => true);
          dispatch(
            deleteBranding(
              branding?.id,
              (resp) => {
                setLoading((prev) => false);

                if (onUpdateItemList) {
                  onUpdateItemList(resp, "delete");
                }
                if (onClose) {
                  onClose();
                }
              },
              (branding) => {
                console.log("ERROR DELETING BRANDING", branding);
                setLoading((prev) => false);
              },
            ),
          );
        }}
        cancelLabel="Cancel"
        confirmDisabled={loading}
        cancelAction={() => setConfirmDeleteOpen(false)}
      />
      {loadingModal}
      <ScopedListingGroupsModal
        open={{isOpen: scopedGroupsModalOpen, typed: branding}}
        onClose={() => {
          setScopedGroupsModalOpen(false);
        }}
        onConfirm={() => {
          setScopedGroupsModalOpen(false);
        }}
        setSelectedListings={(e) => {
          setSelectedGroups(e);
          setSaveButton({enabled: true, label: "Save"});
        }}
        setScopedGroupsEnabled={(e) => {}}
        selectedListings={selectedGroups}
      />
      <div style={{padding: theme.spacing(4)}}>
        <Typography style={{marginBottom: THEME.spacing.sm}} variant="h1">
          Name *
        </Typography>
        <InputField
          id="brand-name"
          editOnly
          fullWidth
          required={true}
          disableEnterSave
          value={brandName}
          onChange={(e) => {
            setBrandName(e.target.value);
            setSaveButton({enabled: true, label: "Save"});
          }}
        />

        <Typography className={classes.subtitle} variant="h1">
          Logo
        </Typography>
        <Typography style={{marginBottom: THEME.spacing.md}} variant="body1">
          {
            "Customize your boarding pass & scheduled messages with your logo. Max 10 MB upload size."
          }
        </Typography>
        <Box
          sx={{
            marginTop: THEME.spacing.lg,
            display: "flex",
            alignItems: "flex-start",
            gap: THEME.spacing.lg,
          }}
        >
          <div>
            {brandingLogo ? (
              <div>
                <img
                  id="branding-logo"
                  ref={imageRef}
                  style={{
                    maxWidth: "100%",
                    minWidth: 200,
                    maxHeight: 200,
                    display: "block",
                    backgroundColor: "#" + secondaryColor,
                    borderRadius: 10,
                    marginBottom: THEME.spacing.md,
                  }}
                  src={brandingLogo?.picture}
                  alt="branding logo"
                />
                <Button
                  className={classes.mt_sm}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    setSaveButton({enabled: true, label: "Save"});
                    setBrandingLogo(null);
                  }}
                >
                  Remove Logo
                </Button>
              </div>
            ) : (
              <div style={{width: "300px"}}>
                <DragAndDropFile setFile={loadImages} />
              </div>
            )}
          </div>
          <Box minWidth={100}>
            <Box
              id="branding-colours"
              display="flex"
              flexDirection="column"
              width="fit-content"
              style={{gap: THEME.spacing.sm}}
            >
              <Box>
                <InputField
                  editOnly
                  inlineItem
                  disableEnterSave
                  disableMultiline
                  label="Font Color"
                  placeholder={"FFFFFF"}
                  isInvalid={primaryColorError}
                  value={primaryColor || ""}
                  helperText={
                    !!primaryColorError ? "Must be a valid hex code" : ""
                  }
                  onChange={(e) => setColor(e.target.value)}
                  inputProps={{
                    onClick: (e) => {
                      setAnchorEl(e.currentTarget);
                      setSelectedColor(1);
                    },
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                    endAdornment: (
                      <div
                        style={{
                          border: "1px solid black",
                          backgroundColor: "#" + primaryColor,
                          width: 18,
                          height: 18,
                        }}
                      />
                    ),
                  }}
                />
              </Box>
              <Box>
                <InputField
                  editOnly
                  inlineItem
                  disableEnterSave
                  disableMultiline
                  label="Background Color"
                  placeholder={"FFFFFF"}
                  isInvalid={secondaryColorError}
                  value={secondaryColor || ""}
                  helperText={
                    !!primaryColorError ? "Must be a valid hex code" : ""
                  }
                  onChange={(e) => setColor(e.target.value)}
                  inputProps={{
                    onClick: (e) => {
                      setAnchorEl(e.currentTarget);
                      setSelectedColor(2);
                    },
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                    endAdornment: (
                      <div
                        style={{
                          border: "1px solid black",
                          backgroundColor: "#" + secondaryColor,
                          width: 18,
                          height: 18,
                        }}
                      />
                    ),
                  }}
                />
              </Box>
            </Box>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <HexColorPicker
                style={{margin: THEME.spacing.md}}
                color={selectedColor === 1 ? primaryColor : secondaryColor}
                onChange={(v) => setColor(v.slice(1))}
              />
            </Popover>
          </Box>
        </Box>
        <Typography className={clsx(classes.subtitle)} variant="h1">
          Location *
        </Typography>
        <div id="business-country">
          <Country
            edit
            label="Business Country"
            value={country || ""}
            onValueChange={(c) => {
              setCountry(c);
              setSaveButton({enabled: true, label: "Save"});
            }}
          />
        </div>
        {user_profile.scopes?.messaging !== "hide" && (
          <Fragment>
            <Typography className={classes.subtitle} variant="h1">
              Email Signature
            </Typography>
            {(isOldRoot || isNewRoot) && (
              <Typography
                style={{marginBottom: THEME.spacing.xs}}
                variant="body1"
              >
                This will apply to all your users
              </Typography>
            )}
            <InputField
              editOnly
              fullWidth
              label="Signature"
              disableEnterSave
              value={emailSignature}
              onChange={(e) => {
                setEmailSignature(e.target.value);
                setSaveButton({enabled: true, label: "Save"});
              }}
            />
          </Fragment>
        )}
        <Typography className={clsx(classes.subtitle)} variant="h1">
          Direct Booking Website
        </Typography>
        <InputField
          id="direct-booking"
          editOnly
          fullWidth
          label="URL"
          disableEnterSave
          value={directBookingUrl}
          placeholder="https://www.mysite.com/booking"
          onChange={(e) => {
            setDirectBookingUrl(e.target.value);
            setSaveButton({enabled: true, label: "Save"});
          }}
        />
        {flag.customBoardingPassUrl && (
          <>
            <Typography className={clsx(classes.subtitle)} variant="h1">
              Custom Keycard URL
            </Typography>
            <InputField
              id="custom-keycard-url"
              editOnly
              fullWidth
              label="URL"
              disableEnterSave
              value={customKeycardUrl}
              placeholder="https://guest.ensoconnect.com"
              onChange={(e) => {
                setCustomKeycardUrl(e.target.value);
                setSaveButton({enabled: true, label: "Save"});
              }}
            />
          </>
        )}
        <Box sx={{marginTop: THEME.spacing.lg}}>
          <Divider
            className={classes.divider}
            style={{marginBottom: THEME.spacing.lg}}
          />
          <Box mb={2}>
            <Typography variant="h1">
              {t("branding-social-media-profiles-title")}
            </Typography>
          </Box>

          <SocialProfilesEditor
            profiles={profiles}
            setProfiles={(p) => {
              setProfiles(p);
              setSaveButton({enabled: true, label: "Save"});
            }}
          />
        </Box>
        {
          <Box sx={{marginTop: THEME.spacing.lg}}>
            <Divider
              className={classes.divider}
              style={{marginBottom: THEME.spacing.lg}}
            />
            <Box mb={2}>
              <Typography variant="h1">
                {t("branding-listing-groups-scope-title")}
              </Typography>
            </Box>
            {!isOldRoot && !isNewRoot ? (
              <>
                <Typography style={{}}>
                  {t("branding-listing-groups-count-subtitle").replace(
                    "{count}",
                    selectedGroups.length,
                  )}
                </Typography>
                <div
                  className={classes.chipRow}
                  style={{gap: "5px", marginBottom: 14}}
                >
                  {selectedGroups.slice(0, 5).map((group, index) => (
                    <Chip
                      key={index}
                      size="small"
                      label={
                        groups.filter((g) => g.group_id === group)[0]?.name ||
                        "ERROR"
                      }
                    />
                  ))}
                  {selectedGroups.length > 5 && (
                    <Chip size="small" label="..." />
                  )}
                </div>
                <div>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => setScopedGroupsModalOpen(true)}
                    style={{padding: "5px 10px"}}
                  >
                    {t("brand-edit-listing-groups-button")}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography style={{}}>
                  {t("branding-listing-groups-default-subtitle")}
                </Typography>
              </>
            )}
          </Box>
        }
        {showDeleteButton && !isOldRoot && !isNewRoot && !isNewBrand && (
          <Box sx={{marginTop: THEME.spacing.lg}}>
            <Divider
              className={classes.divider}
              style={{marginBottom: THEME.spacing.lg}}
            />
            <Box mb={2}>
              <Typography variant="h1">Delete Branding</Typography>
            </Box>
            <Typography style={{paddingBottom: "10px"}}>
              Permanently delete this branding.
            </Typography>
            <Chip
              onClick={() => setConfirmDeleteOpen(true)}
              style={{backgroundColor: "darkred", color: "white", marginTop: 5}}
              clickable={true}
              avatar={<DeleteIcon style={{color: "white"}} />}
              size="small"
              label={<Box sx={{marginTop: 4}}>Permanently delete branding</Box>}
            />
          </Box>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <HexColorPicker
            style={{margin: THEME.spacing.md}}
            color={selectedColor === 1 ? primaryColor : secondaryColor}
            onChange={(v) => setColor(v.slice(1))}
          />
        </Popover>
      </div>
    </div>
  );
}
