import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {messageTime} from "utilities/helperFunctions";
import _ from "lodash";

import {useTheme} from "../../../../connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {ReactComponent as CalendarIcon} from "connectui/icons/calendar.svg";
import {ReactComponent as MoreIcon} from "connectui/icons/more-vertical.svg";
import {otaIcons} from "../../../../configuration/constants";

import Container from "../../../../connectui/component/layout/Container";
import ProfilePicture from "../../../../connectui/component/display/ProfilePicture";
import Text from "../../../../connectui/component/display/Text";
import SvgIcon from "../../../../connectui/component/display/SvgIcon";
import Tooltip from "../../../../connectui/component/display/Tooltip";
import FlexContainer from "connectui/component/layout/FlexContainer";
import ChipText from "connectui/component/display/chip/ChipText";

const useStyles = (theme) =>
  buildStyles({
    cardContainer: {
      height: "100px",
      width: "100%",
      cursor: "pointer",
      borderBottom: "1px solid #EFEFEF",
      padding: theme.spacing(4, 2, 2.5, 5),
      "&.unread": {borderLeft: `3px solid ${theme.colors.primary600}`},
      "&.unanswered": {borderLeft: `3px solid ${theme.colors.gray400}`},
      "&:hover, .selected": {backgroundColor: theme.colors.gray50},
    },
    otaIconContainer: {
      position: "absolute",
      bottom: "-10px",
      right: "-7px",
      width: "22px",
      height: "22px",
      borderRadius: "50%",
      backgroundColor: theme.colors.white,
      border: `2px solid ${theme.colors.white}`,
      "& img": {
        width: "18px !important",
        height: "18px !important",
        borderRadius: "50%",
      },
    },
    badge: {
      position: "absolute",
      top: "-8px",
      right: "-4px",
      backgroundColor: theme.colors.primary800,
      color: theme.colors.white,
      padding: theme.spacing(0.5),
      width: "fit-content",
      height: "fit-content",
      borderRadius: "50%",
      fontSize: "11px",
      lineHeight: "8px",
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-word",
      WebkitLineClamp: 1,
    },
    moreIcon: {
      width: "16px",
      height: "16px",
    },
    menuIcon: {
      width: "12px",
      height: "12px",
      marginRight: theme.spacing(1),
    },
  });

const ThreadCardComponent = ({guest, selected, onClick = () => null}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const channels = _.uniq(guest?.channels ?? []).filter(
    (c) => !!otaIcons(guest, "1")[c],
  );

  return (
    <FlexContainer
      onClick={onClick}
      gap={theme.spacing(3)}
      className={`${classes.cardContainer} ${
        !!guest?.unread_messages
          ? "unread"
          : !!guest?.unanswered_messages
            ? "unanswered"
            : ""
      } ${selected ? "selected" : ""}`}
    >
      {/* Avatar */}
      <Container sx={{position: "relative"}}>
        <ProfilePicture name={guest?.name || "?"} />
        {!!channels.length && (
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            className={classes.otaIconContainer}
          >
            {otaIcons(guest, "1")[channels[0]]}
            {channels.length > 1 && (
              <FlexContainer
                className={classes.badge}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip
                  tooltipContent={
                    <FlexContainer gap={theme.spacing(1)}>
                      {channels.map((c, i) => {
                        if (!i) {
                          return null;
                        }
                        return otaIcons(guest, "1")[c];
                      })}
                    </FlexContainer>
                  }
                >
                  +
                </Tooltip>
              </FlexContainer>
            )}
          </FlexContainer>
        )}
      </Container>
      {/* Card content */}
      <FlexContainer flexDirection="column" sx={{flex: 1, height: "100%"}}>
        <FlexContainer flexDirection="column" sx={{flex: 1, width: "100%"}}>
          <FlexContainer
            sx={{width: "100%"}}
            alignItems="center"
            gap={theme.spacing(2)}
          >
            <Text weight="medium" sx={{flex: 1}} className={classes.text}>
              {guest?.name ?? ""}
            </Text>
            <Text variant="textXS" weight="medium" color="textSecondary">
              {messageTime(guest?.updated_at)}
            </Text>
            <SvgIcon
              component={MoreIcon}
              className={classes.moreIcon}
              sx={{color: theme.colors.textSecondary}}
            />
          </FlexContainer>
          <FlexContainer sx={{flex: 1}}>
            <Text variant="textXS" className={classes.text}>
              {guest?.message_preview ?? ""}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer sx={{width: "100%"}} alignItems="center">
          <FlexContainer sx={{flex: 1}} alignItems="center">
            {guest?.range_formatted && (
              <>
                <SvgIcon
                  component={CalendarIcon}
                  className={classes.menuIcon}
                  sx={{color: theme.colors.textSecondary}}
                />
                <Text variant="textXS" weight="medium" color="textSecondary">
                  {guest?.range_formatted?.replace("to", "→")}
                </Text>
              </>
            )}
          </FlexContainer>
          {guest?.booking_status && (
            <ChipText
              text={
                <Text
                  variant="textXS"
                  weight="medium"
                  color={`${theme.colors[guest.booking_status]}700`}
                >
                  {t(guest.booking_status)}
                </Text>
              }
              sx={{
                padding: theme.spacing(0.5, 2),
                backgroundColor:
                  theme.colors[`${theme.colors[guest.booking_status]}50`],
                "&:hover": {
                  backgroundColor:
                    theme.colors[`${theme.colors[guest.booking_status]}50`],
                },
              }}
            />
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

ThreadCardComponent.propTypes = {
  guest: PropTypes.shape({
    name: PropTypes.string,
    message_preview: PropTypes.string,
    range_formatted: PropTypes.string,
  }),
};

export default ThreadCardComponent;
