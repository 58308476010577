import React from "react";
import {
  Menu,
  Typography,
  Link,
  InputBase,
  SvgIcon,
  Box,
  IconButton,
  Button,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as AIIcon} from "assets/icons/ai.svg";
import {ReactComponent as PencilIcon} from "assets/icons/pencil.svg";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloseIcon from "@material-ui/icons/Close";
import {suggestedPrompts} from "configuration/specs";
import {THEME} from "configuration/settings";
import GoogleTopPlaces from "components/Panels/GoogleTopPlaces";
import CloseIconButton from "core/buttons/CloseIconButton";

const useEnsoAIMenuStyles = makeStyles((theme) => ({
  colored: {color: THEME.AI.color},
  titleSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  menuContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    borderRadius: 10,
    padding: theme.spacing(3),
    backgroundColor: THEME.AI.bg,
  },
  input: {
    border: "1px solid #DEDEDE",
    borderRadius: 10,
    marginTop: -10,
    marginBottom: 10,
    background: "#FFF",
    padding: theme.spacing(3),
    "& > input": {paddingRight: theme.spacing(3)},
  },
  inputError: {
    border: `1px solid ${THEME.error}`,
  },
  subheader: {color: THEME.AI.color + "CC"},
  promptButton: {
    padding: theme.spacing(1, 3),
    borderRadius: 20,
    width: "fit-content",
    backgroundColor: "#FFF",
    "&:hover": {backgroundColor: "#FFF"},
  },
  generateBtnContainer: {
    marginBottom: 2,
  },
  AIIcon: {
    margin: 0,
  },
  btnLabel: {
    gap: theme.spacing(1),
    color: THEME.AI.color,
    textAlign: "left",
  },
}));

export default function EnsoAIPanel({
  content,
  onClose,
  listing,
  onGenerate,
  onClearError,
  error,
  loading,
}) {
  const classes = useEnsoAIMenuStyles();
  let isPlace = content.guidebook_type === "place";
  const [text, setText] = React.useState(
    isPlace
      ? ""
      : "Fill in this guidebook template using my listing information.",
  );
  const [selectedPlaces, setSelectedPlaces] = React.useState([]);

  const handleInputChange = (e) => {
    setText(e.target.value);
    if (error && onClearError) {
      onClearError();
    }
  };

  const onSuggestedPromptClick = (prompt) => () => setText(prompt);

  const handleOnKeyDown = (e) => e.stopPropagation();

  {
    /*places.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
          <Typography>
            Here's what we found near your listing. Generate your guidebook now
            or make any edits below.
          </Typography>
          <PrimaryButton
            label="Generate Content"
            color="secondary"
            size="medium"
            className={classes.generateButton}
            onClick={() => submitPlaces(selectedPlaces)}
          />
          {selectedPlaces.length > 0 && (
            <Typography className={classes.selectedCount}>
              {selectedPlaces.length} place(s) selected
            </Typography>
          )}
        </div>
      )*/
  }

  let template_content = isPlace ? (
    <div style={{flex: 1, overflow: "hidden"}}>
      <GoogleTopPlaces
        location={listing.address}
        queries={
          content?.template_spec?.google_maps_queries || [
            {search_radius: 5, query: "Attractions"},
          ]
        }
        submitPlaces={(places) => onGenerate(null, places)}
        selectedPlaces={selectedPlaces}
        setSelectedPlaces={(newPlaces) => {
          setSelectedPlaces(newPlaces);
        }}
      />
    </div>
  ) : (
    <>
      {/*<Typography variant="subtitle2" className={classes.subheader}>
      {"Included Listing Information"}
    </Typography>*/}
      <div style={{display: "flex"}}>
        <Typography className={classes.colored}>
          {
            " EnsoAI will use the following sources to write your guidebook. Visit the "
          }
          <Link
            color="inherit"
            variant="inherit"
            href={`/admin/listings/${listing.listing_id}/knowledge`}
          >
            AI Knowledge Base <ExitToAppIcon style={{fontSize: 16}} />
          </Link>
          {" to update included listing information."}
        </Typography>
      </div>
      <ul className={classes.colored}>
        <li>
          <Typography>Knowledge Base Article: "Amenity information"</Typography>
        </li>
        <li>
          <Typography>Uploaded PDF Contents</Typography>
        </li>
        <li>
          <Typography>PMS Custom Fields</Typography>
        </li>
      </ul>
      <InputBase
        fullWidth
        autoFocus
        value={text}
        error={!!error}
        disabled={isPlace}
        multiline
        maxRows={10}
        className={classes.input}
        classes={{error: classes.inputError}}
        onKeyDown={handleOnKeyDown}
        onChange={handleInputChange}
      />
    </>
  );

  return (
    <div className={classes.menuContainer}>
      <div className={classes.titleSection}>
        <div className={classes.titleRow}>
          <div style={{flex: 1, display: "flex"}}>
            <SvgIcon
              component={AIIcon}
              style={{color: THEME.AI.color}}
              viewBox="0 0 18 18"
              className={classes.AIIcon}
            />
            <Typography variant="h1" className={classes.colored}>
              {"EnsoAI Guidebook Wizard"}
            </Typography>
          </div>
          <CloseIconButton disablePadding sm onClick={onClose} />
        </div>
      </div>
      <Typography className={classes.colored}>
        {isPlace
          ? "Write a customized guidebook in 30 seconds by selecting local recommendations."
          : "Write a customized guidebook in 30 seconds using your listing's specific content."}
      </Typography>
      {template_content}
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <div className={classes.generateBtnContainer}>
          <PrimaryButton
            color="secondary"
            variant="contained"
            label={
              <>
                <SvgIcon
                  component={AIIcon}
                  style={{color: "white"}}
                  viewBox="0 0 18 18"
                  className={classes.AIIcon}
                />
                <Typography>
                  {isPlace
                    ? selectedPlaces?.length === 0
                      ? "Build guidebook"
                      : `Build guidebook with ${selectedPlaces.length} places`
                    : "Write with EnsoAI"}
                </Typography>
              </>
            }
            disabled={
              (!text.trim() && selectedPlaces?.length === 0) || !!loading
            }
            onClick={() => onGenerate(text, selectedPlaces)}
          />
        </div>
      </div>
    </div>
  );
}
