import React from "react";
import {Storage} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";
import {THEME} from "configuration/settings.js";
import {isMobile, isSafari} from "react-device-detect";
import clsx from "clsx";
// UI
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {ReactComponent as JarvisIcon} from "assets/icons/Icon_Personal_Concierge.svg";
import {ReactComponent as BellIcon} from "assets/icons/bell.svg";
import ReplyIcon from "@material-ui/icons/Reply";
import SvgIcon from "@material-ui/core/SvgIcon";
import ChatBubbleIcon from "@material-ui/icons/Sms";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import ImageIcon from "@material-ui/icons/Image";
import useMessageStyles from "../../styles/useMessageStyles";
import Avatar from "@material-ui/core/Avatar";
// Custom
import PartialComponentLayer from "components/Helpers/PartialComponentLayer";
import FormattedMessage from "components/Helpers/FormattedMessage";
import PrimaryButton from "core/buttons/PrimaryButton";
import AutoMessage from "../Cards/AutoMessage";
import RateResponse from "../Misc/RateResponse";
//Actions
import {addHTMLContent, markResponse} from "redux/actions/messagesActions";
// Utils
import useComponentHeightObserver from "hooks/useComponentHeightObserver";
import {triggerLabels} from "configuration/specs";
import {format, getDayOfYear} from "date-fns";
import {Box} from "@material-ui/core";

export const getHTML = (html, id) => {
  return {
    __html: html
      ? `<div ${id ? `id="html-${id}"` : ""}class="dangerouslyHTML">${html}</div>`
      : "",
  };
};

export const formatHTML = (html) => {
  if (!!html) {
    let breakSpaces = false;
    if (
      !html.toLowerCase().startsWith("<!doctype html>") &&
      !html.includes("<body")
    ) {
      breakSpaces = true;
      html = `<body>${html}</body>`;
    }
    const result = html
      .replace(
        "<body",
        `<body style="overflow-wrap: anywhere; background-color: white; ${breakSpaces ? "white-space: break-spaces;" : ""}" `,
      )
      .replace(
        `{color:rgba(0,0,0,.3)}`,
        `{color:rgba(0,0,0,.3);
      background:#ffffff}`,
      );
    return result;
  } else {
    return html;
  }
};

export const renderAddresses = (who, field, label, initialValue) => {
  const validField = field && field.length;
  if (!validField) {
    return null;
  }
  return (
    <div style={{zIndex: 1}}>
      <span>
        <strong>{label}:</strong>{" "}
        {initialValue && (
          <Typography
            style={{
              display: "inline-block",
              marginRight: 5,
              color: who === "them" ? THEME.emailDarkAccent : undefined,
            }}
            color="primary"
          >
            {initialValue},
          </Typography>
        )}
      </span>
      {field.map((address, index) => {
        return (
          <Typography
            key={address}
            style={{
              display: index === 0 ? "inline-block" : undefined,
              width: "fit-content",
              overflowWrap: "break-word",
              maxWidth: "100%",
              color: who === "them" ? THEME.emailDarkAccent : undefined,
            }}
            color="primary"
          >
            {address}
            {field[index + 1] && ","}
          </Typography>
        );
      })}
    </div>
  );
};

export const getAutoMsg = (
  m,
  who,
  timeDivider,
  guest,
  sentByLabel,
  onEmailLoad = () => null,
) => {
  return (
    <React.Fragment key={m.id}>
      {timeDivider}
      <AutoMessage
        m={m.message}
        who={who}
        getHTML={getHTML}
        onEmailLoad={onEmailLoad}
        guest_phone={guest.phone}
        sentByLabel={sentByLabel}
      />
    </React.Fragment>
  );
};

export const GetEmail = ({
  m,
  who,
  replyEmail,
  timeDivider,
  sentByLabel,
  onEmailLoad,
}) => {
  const [showOriginal, setShowOriginal] = React.useState(false);

  return (
    <React.Fragment key={m.id}>
      {timeDivider}
      {(m?.message_translations?.length > 0 || !!m?.message?.body) &&
      !showOriginal ? (
        <Email
          m={m}
          who={who}
          replyEmail={replyEmail}
          sentByLabel={sentByLabel}
          showOriginal={() => setShowOriginal(true)}
        />
      ) : m.message.html_url ? (
        <HTMLEmail
          m={m}
          who={who}
          replyEmail={replyEmail}
          sentByLabel={sentByLabel}
          onEmailLoad={onEmailLoad}
        />
      ) : m.message.hasOwnProperty("html") ? (
        <HTMLEmail
          m={m}
          who={who}
          replyEmail={replyEmail}
          html={m.message.html}
          sentByLabel={sentByLabel}
          onEmailLoad={onEmailLoad}
        />
      ) : (
        <Email m={m} who={who} sentByLabel={sentByLabel} />
      )}
    </React.Fragment>
  );
};

const ContextBtn = ({who, m, show, setShow}) => {
  const classes = useMessageStyles();
  const dispatch = useDispatch();
  const [added, setAdded] = React.useState(m.tags?.saved_response ?? false);

  const handleSaveResponse = () => {
    const body = {
      guest_id: m.guest_id,
      sent: m.sent,
      save_response: true,
    };
    dispatch(markResponse(body, () => setAdded(true)));
  };

  if (who == "me" && show) {
    return (
      <Card className={classes.card}>
        <div className={classes.jarvisIcon}>
          <SvgIcon fontSize="large" component={JarvisIcon} color="primary" />
        </div>

        <div className={classes.contextTxtWrapper}>
          <Typography variant="h1" color="primary">
            Remember this answer?
          </Typography>
          <Typography color="primary" className={classes.contextTxt}>
            {
              "Jarvis, our automated bot will remember this response and \
            reply automatically in the future for you."
            }
          </Typography>
        </div>

        <div className={classes.btnsSection}>
          {!added && (
            <Button
              className={classes.saveBtn}
              variant="contained"
              color="primary"
              onClick={() => setShow(false)}
            >
              Save Response
            </Button>
          )}
          {added && (
            <Button disabled className={classes.saveBtn}>
              Response Saved
            </Button>
          )}
          <Button color="primary" onClick={() => setShow(false)}>
            Dismiss
          </Button>
        </div>
      </Card>
    );
  } else return null;
};

export const HTMLEmail = ({
  m,
  who,
  html,
  replyEmail,
  sentByLabel,
  preview,
  onEmailLoad = () => null,
}) => {
  const dispatch = useDispatch();
  const htmlContent = useSelector(
    (state) => state.messagesReducer.html_content,
  );
  const [html_content, setHtml] = React.useState(formatHTML(html));
  const [loadingIframe, setLoadingIframe] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [isLarge, setIsLarge] = React.useState(false);
  const textWrapperRef = React.useRef();
  const classes = useMessageStyles({isSafari});

  React.useEffect(() => {
    let isMounted = true;

    const selectFile = async () => {
      let signed = await Storage.get(m.message.html_url, {
        download: true,
        bucket: process.env.REACT_APP_S3_EMAIL_BUCKET,
      });
      if (!!isMounted) {
        signed.Body.text().then((result) => {
          const txt = formatHTML(result);
          if (!!isMounted) {
            setHtml((prev) => txt);
            dispatch(addHTMLContent(m.guest_id, txt, m.message.html_url));
          }
        });
      }
    };

    if (!html_content) {
      const guestHTMLContent = htmlContent[m.guest_id];
      const storedHTML = guestHTMLContent
        ? guestHTMLContent[m.message.html_url]
        : null;
      if (!storedHTML) selectFile();
      else setHtml(storedHTML);
    }
    return () => (isMounted = false);
  }, []);

  const oniframeLoad = (e) => {
    const el = e.currentTarget;
    const contentHeight = el.contentWindow.document.body.scrollHeight;
    if (contentHeight > 500) {
      setIsLarge((prev) => true);
    }
    el.style.height = contentHeight + 16 + "px";
    onEmailLoad();
    setLoadingIframe((prev) => false);
  };

  if (preview) {
    return (
      <div className={classes.previewEmailContainer}>
        <iframe
          onLoad={oniframeLoad}
          className={classes.iframe}
          srcDoc={`${html_content}`}
          width="100%"
          height="100%"
          scrolling="no"
        ></iframe>
      </div>
    );
  }
  return (
    <>
      <div className={classes.messageWrapper}>
        <div
          className={clsx(
            classes.channel,
            classes.email,
            classes[`${who}Channels`],
          )}
        >
          <EmailIcon className={classes.channelIcon} />
        </div>
        <div
          className={clsx(classes.bubblePeak, classes[`${who}BubblePeak`])}
        />
        <div
          className={clsx(classes.bubblePeak, classes[`${who}BubblePeakAfter`])}
        />
        <div
          ref={textWrapperRef}
          className={clsx(classes.textWrapper, classes[`${who}Text`], {
            [classes.fullMessage]: expanded,
            [classes.partialMessage]: !expanded && isLarge,
          })}
        >
          {!expanded && isLarge && (
            <PartialComponentLayer
              who={who}
              label="Show Full Message"
              onExpand={() => setExpanded(true)}
            />
          )}
          <div style={{display: "flex", zIndex: 1}}>
            <div style={{flexGrow: 1}}>
              {renderAddresses(who, m.message.to, who === "me" ? "To" : "From")}
            </div>
            {who === "them" && (
              <IconButton
                size="small"
                style={who === "them" && {color: "white", padding: 0}}
                onClick={() => replyEmail(m.message.subject)}
              >
                <ReplyIcon />
              </IconButton>
            )}
          </div>
          {renderAddresses(who, m.message.cc, "CC")}
          {renderAddresses(who, m.message.bcc, "BCC")}
          {!!m?.message?.subject && (
            <div style={{overflowWrap: "break-word", zIndex: 1}}>
              <strong>Subject:</strong> {m.message.subject}
            </div>
          )}
          <hr style={{marginTop: 5, marginBottom: 5}} />
          <iframe
            title="Email content"
            onLoad={oniframeLoad}
            className={clsx(classes.iframe, {"-hide": !!loadingIframe})}
            srcDoc={`${html_content}`}
            width="100%"
            height="100%"
            scrolling="no"
          ></iframe>
        </div>
      </div>
      <Typography variant="caption" className={classes.sentLabel}>
        {sentByLabel}
      </Typography>
    </>
  );
};

export const Email = ({m, who, sentByLabel, replyEmail, showOriginal}) => {
  const classes = useMessageStyles({isSafari, who});
  const [expanded, setExpanded] = React.useState(false);
  const textWrapperRef = React.useRef();
  const isLarge = useComponentHeightObserver({ref: textWrapperRef, expanded});
  let channelIcon = null;

  switch (m.channel) {
    case "whatsapp":
      channelIcon = <EmailIcon className={classes.channelIcon} />;
      break;
    case "vrbo":
      channelIcon = (
        <img
          src={require("assets/img/vrbo-V-logo.png")}
          className={classes.channelIcon}
        />
      );
      break;
    case "homeaway":
      channelIcon = (
        <img
          src={require("assets/img/homeaway_logo.svg")}
          className={classes.channelIcon}
        />
      );
      break;
    case "booking_com":
      channelIcon = (
        <img
          src={require("assets/img/bookingcom_logo.svg")}
          className={classes.channelIcon}
        />
      );
      break;
  }

  return (
    <>
      <div className={classes.messageWrapper}>
        <div
          className={clsx(
            classes.channel,
            classes[m.channel],
            classes[`${who}Channels`],
          )}
        >
          {channelIcon}
        </div>
        <div
          className={clsx(classes.bubblePeak, classes[`${who}BubblePeak`])}
        />
        <div
          className={clsx(classes.bubblePeak, classes[`${who}BubblePeakAfter`])}
        />
        <div
          ref={textWrapperRef}
          className={clsx(classes.textWrapper, classes[`${who}Text`], {
            [classes.fullMessage]: expanded,
            [classes.partialMessage]: !expanded && isLarge,
          })}
        >
          {!expanded && isLarge && (
            <PartialComponentLayer
              who={who}
              label="Show Full Message"
              onExpand={() => setExpanded(true)}
            />
          )}
          <div style={{display: "flex", zIndex: 1}}>
            <div style={{flexGrow: 1}}>
              {renderAddresses(who, m.message.to, "To")}
            </div>
            {who === "them" && !!replyEmail && (
              <IconButton
                size="small"
                style={who === "them" && {color: "white", padding: 0}}
                onClick={() => replyEmail(m?.message?.subject ?? "")}
              >
                <ReplyIcon />
              </IconButton>
            )}
          </div>
          {renderAddresses(who, m.message.cc, "CC")}
          {renderAddresses(who, m.message.bcc, "BCC")}
          {!!m?.message?.subject && (
            <Box style={{overflowWrap: "break-word", zIndex: 1}} mb={1}>
              Subject: {m.message.subject}
            </Box>
          )}
          <Typography style={{fontSize: 14}} component="span">
            <div className={classes.bodyMsg}>
              {m?.message_translations?.length > 0
                ? m.message_translations[0].value
                : typeof m?.message == "string"
                  ? m?.message
                  : m?.message.body}
            </div>
          </Typography>
          {/*m.sender_type === 'chatbot' && <RateResponse message={m} />*/}
        </div>
      </div>
      <Typography variant="caption" className={classes.sentLabel}>
        {!!m?.tags?.question_type && `${m.tags.question_type} • `}
        {m?.message_translations?.length > 0 && "Translated Message"}
        {(m?.message_translations?.length > 0 ||
          !!m?.message?.html_url ||
          !!m?.message?.html) && (
          <>
            <Link
              style={{marginRight: 5, marginLeft: 5, cursor: "pointer"}}
              onClick={() => showOriginal()}
            >
              View Original
            </Link>
            {" • "}
          </>
        )}
        {sentByLabel}
      </Typography>
    </>
  );
};

export const CommonChannelMsg = ({
  m,
  who,
  timeDivider,
  sentByLabel,
  categoryLabel,
  addKnowledge,
  flags,
  language = "en",
}) => {
  const classes = useMessageStyles({isSafari, who});
  const textWrapperRef = React.useRef();
  const isSendingMessage = useSelector(
    (state) => state.defaultReducer.loading,
  ).send_message;
  const [expanded, setExpanded] = React.useState(false);
  const [showContextCard, setShowContextCard] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [showOriginal, setShowOriginal] = React.useState(false);
  const [savedResponse, setSavedResponse] = React.useState(
    m?.tags?.saved_response && "Saved to knowledge base • ",
  );
  let channelIcon = null;
  const isLarge = useComponentHeightObserver({ref: textWrapperRef, expanded});
  const isPartialMessageActive = isLarge && !expanded;
  const messageNotSent = !isSendingMessage && !!m.not_sent;
  let available_translation = m?.message_translations?.find(
    (t) => t.language == language,
  );

  React.useEffect(() => {
    if (!m.attachments?.length) {
      return;
    }
    getAttachments();
  }, []);

  function isAttachmentAnImage(attachment) {
    let image = new Image();
    image.onload = function () {
      setAttachments((prev) => [...prev, {link: attachment, isImage: true}]);
    };
    image.onerror = function () {
      setAttachments((prev) => [...prev, {link: attachment, isImage: false}]);
    };
    image.src = attachment;
  }

  const getAttachments = () => {
    for (let index = 0; index < m.attachments.length; index++) {
      isAttachmentAnImage(m.attachments[index]);
    }
  };

  function handleAddKnowledge(content, sent) {
    setSavedResponse("Saving to knowledge base...");
    addKnowledge(
      content,
      sent,
      () => setSavedResponse("Saved to knowledge base • "),
      () => setSavedResponse(null),
    );
  }

  const openMobileLink = (url) => () => {
    window.open(url, "_system", "location=yes");
  };

  switch (m.channel) {
    case "whatsapp":
      channelIcon = <WhatsappIcon className={classes.channelIcon} />;
      break;
    case "SMS":
      channelIcon = <ChatBubbleIcon className={classes.channelIcon} />;
      break;
    case "airbnb":
      channelIcon = (
        <img
          src={require("assets/img/airbnb_white.png")}
          className={classes.channelIcon}
        />
      );
      break;
  }

  // we treat these channels as message even though they are in fact email
  // check if html is in m.message to avoid field not exists error (happen during re-render)
  if (
    (!m.message || typeof m.message !== "string" || !m.message.trim()) &&
    !m.attachments?.length
  )
    return null;

  return (
    <>
      <div className={classes.messageWrapper}>
        {timeDivider}
        <div
          className={clsx(
            classes.channel,
            classes[m.channel],
            classes[`${who}Channels`],
          )}
        >
          {channelIcon}
        </div>
        <div
          className={clsx(classes.bubblePeak, classes[`${who}BubblePeak`])}
        />
        <div
          className={clsx(classes.bubblePeak, classes[`${who}BubblePeakAfter`])}
        />
        <div
          ref={textWrapperRef}
          className={clsx(classes.textWrapper, classes[`${who}Text`], {
            [classes.fullMessage]: expanded,
            [classes.partialMessage]: isPartialMessageActive,
          })}
          onClick={() =>
            !isPartialMessageActive && setShowContextCard(!showContextCard)
          }
        >
          {isPartialMessageActive && (
            <PartialComponentLayer
              who={who}
              label="Show Full Message"
              onExpand={() => setExpanded(true)}
            />
          )}
          <Typography
            variant="body1"
            component="div"
            className={who === "them" ? classes.linkColor : ""}
          >
            {m.message?.toLowerCase()?.startsWith("<!doctype html") ? (
              <HTMLEmail
                preview={true}
                m={m}
                who={who}
                html={m.message}
                sentByLabel={sentByLabel}
              />
            ) : (
              <FormattedMessage
                message={
                  !showOriginal && !!available_translation
                    ? available_translation.value
                    : m.message
                }
              />
            )}
          </Typography>
          {!!attachments.length && (
            <div className={classes.attachments}>
              {attachments.map((att) => {
                return isMobile ? (
                  <a
                    href={"#"}
                    onClick={att.isImage ? openMobileLink(att.link) : undefined}
                    download={!att.isImage}
                    className={classes.link}
                  >
                    {att.isImage ? (
                      <Avatar
                        src={att.link}
                        variant="rounded"
                        className="attachment-img"
                      >
                        <ImageIcon fontSize="large" />
                      </Avatar>
                    ) : (
                      <FileIcon className="attachment-file" />
                    )}
                  </a>
                ) : (
                  <Link
                    key={att.link}
                    style={{
                      textDecoration: "none",
                      color:
                        who === "them" ? THEME.emailDarkAccent : THEME.primary,
                    }}
                    target="_blank"
                    rel="noopener"
                    href={att.link}
                    download={!att.isImage}
                  >
                    {att.isImage ? (
                      <Avatar
                        src={att.link}
                        variant="rounded"
                        className="attachment-img"
                      >
                        <ImageIcon fontSize="large" />
                      </Avatar>
                    ) : (
                      <FileIcon className="attachment-file" />
                    )}
                  </Link>
                );
              })}
            </div>
          )}
          {/*m.sender_type === 'chatbot' && <RateResponse message={m} />*/}
        </div>
      </div>
      <Typography variant="caption" className={classes.sentLabel}>
        {m.sender_type === "user" &&
          flags.advancedAi &&
          (messageNotSent ? (
            "Message not sent • "
          ) : savedResponse ? (
            savedResponse
          ) : (
            <>
              <Link
                className={clsx(classes.addToKnowledgeBaseLink, {
                  disabled: isSendingMessage,
                })}
                onClick={() =>
                  isSendingMessage ? {} : handleAddKnowledge(m.message, m.sent)
                }
              >
                {"Add to knowledge base"}
              </Link>
              {" • "}
            </>
          ))}
        {categoryLabel && `${categoryLabel} • `}
        {!!available_translation && !showOriginal && (
          <>
            <Link
              style={{marginRight: 5, marginLeft: 5, cursor: "pointer"}}
              onClick={() => setShowOriginal(true)}
            >
              View Original
            </Link>
            {" • "}
          </>
        )}
        {sentByLabel}
      </Typography>
      {/* {m.sender_type !== "chatbot" &&
      <ContextBtn who={who} m={m} show={showContextCard} setShow={setShowContextCard} />
    } */}
    </>
  );
};

export const TimeDivider = ({m, previousMsgTime, force}) => {
  const classes = useMessageStyles();
  const isDifferentDay = !!previousMsgTime
    ? getDayOfYear(previousMsgTime) !== getDayOfYear(m.sent)
    : false;

  if (force || isDifferentDay)
    return (
      <div className={classes.timeDivider}>
        <Typography variant="caption" className={classes.notificationTime}>
          {format(m.sent, "MMM d")}
        </Typography>
      </div>
    );
  else return null;
};

export const NotificationsGroup = ({notifications = [], timeDivider}) => {
  const classes = useMessageStyles({group: true});
  const [expanded, setExpanded] = React.useState(false);
  const notifTypeLabels = React.useMemo(() => {
    const notifTypesSet = new Set();
    notifications.forEach((n) => {
      const nLabel = triggerLabels[n.notification_type]?.name;
      if (!!nLabel) {
        if (n?.errors?.length > 0) {
          if (notifTypesSet.has(nLabel)) {
            notifTypesSet.delete(nLabel);
          }
          notifTypesSet.add(`err-${nLabel}`);
        } else {
          notifTypesSet.add(nLabel);
        }
      }
    });
    const typesArray = [...notifTypesSet];

    return typesArray.map((t, i) => {
      const bullet = i < typesArray.length - 1 ? " • " : "";
      if (t.startsWith("err-")) {
        return (
          <React.Fragment key={t}>
            <span className="error">{t.substring(4)}</span>
            {bullet}
          </React.Fragment>
        );
      } else {
        return <React.Fragment key={t}>{`${t}${bullet}`}</React.Fragment>;
      }
    });
  }, [notifications]);

  return (
    <div className={clsx(classes.notificationContainer, "column")}>
      {timeDivider}
      <div
        className={classes.notificationContainer}
        style={{paddingTop: !!timeDivider ? 0 : THEME.spacing.md}}
      >
        <Typography component="div" className={clsx(classes.notifText, "flex")}>
          <SvgIcon
            className={clsx(classes.bell, "group")}
            viewBox="0 0 11 10"
            component={BellIcon}
          />
          <Typography variant="body1" component="div" className={"type"}>
            {notifTypeLabels}
            <PrimaryButton
              label={expanded ? "View less" : "View more"}
              onClick={() => setExpanded((prev) => !prev)}
              color="secondary"
              variant="text"
              style={{marginBottom: 2, marginLeft: THEME.spacing.sm}}
              className={classes.viewMoreBtn}
            />
          </Typography>
        </Typography>
      </div>
      {!!expanded && (
        <div className={classes.expandedNotifView}>
          {notifications.map((n) => (
            <React.Fragment key={`${n.id}-expanded`}>
              <Notification m={n} expandedView isRendered={true} />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export const Notification = ({m, timeDivider, isRendered, expandedView}) => {
  const isError = m?.errors?.length > 0;
  const classes = useMessageStyles({
    hasErrors: isError,
    noPadding: !!expandedView,
  });
  const isExperienceNotif = m.notification_type.includes("exp_");

  if (!isRendered || (isError && m.viewed)) {
    return null;
  }

  function getNotifTypeLabel() {
    let notifType = triggerLabels[m.notification_type]?.name;
    if (!!notifType) {
      return (
        <Typography variant="body1" component="span" className="type">
          {notifType}
          <span className="bullet">{"•"}</span>
        </Typography>
      );
    } else {
      return null;
    }
  }

  function getTitle() {
    return (
      <>
        {!expandedView && getNotifTypeLabel()}
        {m.message}
      </>
    );
  }

  if (isExperienceNotif) {
    return null;
  }
  return (
    <div style={{marginBottom: !!timeDivider ? THEME.spacing.lg : 0}}>
      {timeDivider}
      <div className={classes.notificationContainer}>
        <Typography
          component="div"
          className={classes.notifText}
          variant="caption"
        >
          {!expandedView && (
            <SvgIcon
              className={clsx(classes.bell, "standalone")}
              viewBox="0 0 11 10"
              component={BellIcon}
            />
          )}
          {getTitle()}
        </Typography>
      </div>
    </div>
  );
};
