import React from "react";
import InputBase from "../InputBase";
import PropTypes from "prop-types";

/**
 * TextInput is a component that renders a text input field with optional icon support and custom styles.
 * It uses the provided InputBase component or defaults to the InputBase component and allows customization through the `sx` prop.
 *
 * @param {Object} props - The props for the TextInput component.
 * @param {Object} [props.inputProps={}] - The properties to pass to the `<input>` element.
 * @param {React.ReactNode} [props.startIcon=null] - The icon to display before the input field.
 * @param {React.ElementType} [props.inputBase=InputBase] - The component to render for the input base (defaults to InputBase).
 * @param {Object} [props.sx={}] - The style object that can be used to override default input styles.
 * @param {Object} props.rest - Any other props that should be passed to the InputBase component.
 *
 * @returns {JSX.Element} The rendered TextInput component.
 */
const TextInput = ({
  inputProps = {},
  startIcon = null,
  inputBase: InputBaseComponent = InputBase,
  sx = {},
  ...props
}) => {
  return (
    <InputBaseComponent
      inputProps={inputProps}
      startIcon={startIcon}
      sx={{
        ...sx,
      }}
      {...props}
    />
  );
};

TextInput.propTypes = {
  inputProps: PropTypes.object,
  startIcon: PropTypes.node,
  inputBase: PropTypes.any,
  sx: PropTypes.any,
};

export default TextInput;
