import React from "react";
import {useTheme} from "../../../../theme/ThemeProvider";
import {buildStyles} from "../../../buildStyles";
import Container from "../../../layout/Container";
import PropTypes from "prop-types";

/**
 * ButtonBase is a customizable button component that renders a button with default styles and support for additional styles via the `sx` prop.
 * It supports hover, focus, and disabled states, and can be customized with different components through the `as` prop.
 *
 * @param {Object} props - The props for the ButtonBase component.
 * @param {React.ElementType} [props.as="button"] - The component to render (defaults to "button").
 * @param {Object} [props.sx={}] - The style object that can be used to override default button styles.
 * @param {React.ReactNode} props.children - The content of the button, which can be any valid React node.
 * @param {Object} props.rest - Any other props that should be passed to the Container component.
 *
 * @returns {JSX.Element} The rendered ButtonBase component.
 */
const ButtonBase = ({
  as: Component = "button",
  sx = {},
  children,
  ...props
}) => {
  const {theme} = useTheme();

  const classes = buildStyles({
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2, 3),
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
      borderRadius: theme.spacing(2),
      border: "none",
      cursor: "pointer",
      transition: "all 0.2s ease",
      boxShadow: theme.shadows.default,
      "&:hover": {
        backgroundColor: theme.colors.primary600,
      },
      "&:focus": {
        outline: `2px solid ${theme.colors.primary200}`,
        outlineOffset: "2px",
      },
      "&:disabled": {
        backgroundColor: theme.colors.gray300,
        color: theme.colors.gray500,
        cursor: "not-allowed",
        opacity: 0.6,
      },
      ...sx,
    },
  });

  return (
    <Container as={Component} className={classes.btn} {...props}>
      {children}
    </Container>
  );
};

ButtonBase.propTypes = {
  as: PropTypes.any,
  sx: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default ButtonBase;
