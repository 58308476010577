import React from "react";
import {useTheme} from "../../../../theme/ThemeProvider";
import ChipBase from "./ChipBase";
import ButtonIcon from "connectui/component/input/button/ButtonIcon";
import SvgIcon from "../../SvgIcon";
import {ReactComponent as CloseIcon} from "connectui/icons/x.svg";
import PropTypes from "prop-types";

/**
 * XChipBase is a variant of the ChipBase component that renders a chip with a light style and an X button.
 *
 * @param {Object} props - The props for the XChipBase component.
 * @param {React.ReactNode} props.children - The content of the chip, which can be any valid React node.
 * @param {Object} [props.sx={}] - The style object that can be used to override default outlined styles.
 * @param {Function} [props.onXClick] - The click handler for the X button.
 * @param {string} [props.XColor=null] - A custom color for the X button.
 * @param {Object} props.rest - Any other props that should be passed to the ChipBase component.
 *
 * @returns {JSX.Element} The rendered XChipBase component.
 */
const XChipBase = ({
  children,
  sx = {},
  onXClick = () => null,
  XColor = null,
  ...props
}) => {
  const {theme} = useTheme();

  const outlinedStyles = {
    backgroundColor: "transparent",
    color: theme.colors.gray700,
    "&:hover": {
      backgroundColor: theme.colors.gray100,
    },
    ...sx,
  };

  return (
    <ChipBase sx={outlinedStyles} {...props}>
      {children}
      <ButtonIcon
        icon={
          <SvgIcon
            component={CloseIcon}
            sx={{
              color: XColor ?? outlinedStyles.color,
              width: "8px",
              height: "10px",
            }}
          />
        }
        sx={{
          padding: theme.spacing(0.5),
          marginLeft: theme.spacing(1),
          backgroundColor: "transparent",
          "&:hover": {backgroundColor: "transparent"},
        }}
        onClick={onXClick}
      />
    </ChipBase>
  );
};

XChipBase.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.any,
  onXClick: PropTypes.func,
  XColor: PropTypes.string,
};

export default XChipBase;
