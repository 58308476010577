import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";

const ThemeContext = createContext();

/**
 * ThemeProvider is a component that provides the theme context to its child components.
 * It dynamically loads a theme from a file or object and applies it to the application.
 *
 * @param {Object} props - The props for the ThemeProvider component.
 * @param {string | Object} props.initialThemeFile - The theme file or object to load initially.
 * @param {React.ReactNode} props.children - The child components that will have access to the theme context.
 *
 * @returns {JSX.Element} The rendered ThemeProvider component with the theme context.
 */
export const ThemeProvider = ({children, initialThemeFile}) => {
  const [theme, setTheme] = useState(null);

  // Function to load a theme dynamically from a file or object
  const loadTheme = async (themeFile) => {
    try {
      if (typeof themeFile === "string") {
        const loadedTheme = await import(`/${themeFile}`);
        setTheme(loadedTheme.default);
      } else if (typeof themeFile === "object") {
        setTheme(themeFile);
      } else {
        throw new Error(
          "Invalid theme type. Provide a file name or theme object.",
        );
      }
    } catch (error) {
      console.error("Failed to load theme:", error);
    }
  };

  useEffect(() => {
    if (initialThemeFile) {
      loadTheme(initialThemeFile);
    }
  }, [initialThemeFile]);

  return (
    <ThemeContext.Provider value={{theme, loadTheme}}>
      {theme ? children : <div></div>}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialThemeFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

/**
 * useTheme is a custom hook that provides access to the current theme and the `loadTheme` function.
 * It allows components to consume the theme context and dynamically load themes.
 *
 * @returns {Object} The theme context object containing the current `theme` and `loadTheme` function.
 */
export const useTheme = () => useContext(ThemeContext);
