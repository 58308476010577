import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {InboxContext} from "ui/page/Inbox";
import {editAccount, setUserProfile} from "redux/actions/accountsActions";
import {newUuid} from "utilities/helperFunctions";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import ICONS from "connectui/icons/iconMap";
// Components
import FlexContainer from "connectui/component/layout/FlexContainer";
import Text from "connectui/component/display/Text";
import ContextMenu from "connectui/component/input/context/ContextMenu";
import ContextMenuItem from "connectui/component/input/context/ContextMenuItem";
import FilterSetList from "../filterlist/FilterSetList";
import ConfirmModal from "connectui/component/modal/ConfirmModal";
import ButtonText from "connectui/component/input/button/ButtonText";
import ErrorButtonBase from "connectui/component/input/button/base/ErrorButtonBase";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import RoundIcon from "connectui/component/display/RoundIcon";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
  });

const FilterSetsPanel = ({expandArrow = null, editView = () => null}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const viewClicked = useRef(null);
  const {setAppliedFilters} = useContext(InboxContext);
  const searchParams = new URLSearchParams(window.location.search);
  const viewId = searchParams.get("view_id");
  const [selected, setSelected] = useState(viewId);
  const [menuVisible, setMenuVisible] = useState(false);
  const [views, setViews] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({top: 0, left: 0});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );

  useEffect(() => {
    if (!user_profile?.enso_key) {
      return;
    }
    setViews(user_profile.views ?? []);
  }, [user_profile]);

  useEffect(() => {
    if (!selected || !views) {
      return;
    }
    const selectedView = views.find((v) => v.id === selected);
    if (selectedView) {
      setAppliedFilters(selectedView.queries ?? []);
    } else {
      window.history.pushState({}, "", window.location.pathname);
      setSelected(null);
      setAppliedFilters([]);
    }
  }, [selected, views]);

  useEffect(() => {
    if (!viewId && !!selected) {
      setSelected(null);
    } else if (!!viewId && !selected) {
      setSelected(viewId);
    }
  }, [viewId]);

  const handleFilterSetClick = (filterSet) => () => {
    if (filterSet.sub_views?.length) {
      return;
    }
    setSelected(filterSet.id);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?view_id=${filterSet.id}`,
    );
  };

  const handleDelete = () => {
    setMenuVisible(false);
    setDeleteModalOpen(false);
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    let currentViews = user_profile.views ?? [];
    let newViews = currentViews.filter((v) => v.id !== viewClicked.current.id);

    // Update root object in redux store
    dispatch(setUserProfile({...user_profile, views: newViews}));

    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {...user, removed_view: {id: viewClicked.current.id}},
    };
    dispatch(
      editAccount({
        body: {body},
        disableUpdate: true,
        successMsg: "View deleted correctly",
        errorMsg: "Failed to delete view",
        onsuccess: () => {
          if (viewId === viewClicked.current.id) {
            window.history.pushState({}, "", window.location.pathname);
          }
        },
        onError: () => {
          dispatch(setUserProfile({...user_profile, views: currentViews}));
        },
      }),
    );
  };

  const handleDuplicate = () => {
    setMenuVisible(false);
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    let currentViews = user_profile.views ?? [];
    let duplicatedView = {
      ...viewClicked.current,
      id: newUuid(),
      name: `${viewClicked.current.name} (Duplicated)`,
    };

    // Update root object in redux store
    dispatch(
      setUserProfile({
        ...user_profile,
        views: [...currentViews, duplicatedView],
      }),
    );

    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {...user, added_view: duplicatedView},
    };
    dispatch(
      editAccount({
        body: {body},
        successMsg: "View added correctly",
        errorMsg: "Failed to create view",
        onSuccess: (response) => {
          // TODO: return new view in the response and update ID in redux
        },
        onError: () => {
          dispatch(setUserProfile({...user_profile, views: currentViews}));
        },
      }),
    );
  };

  const openMenu = (selectedView) => (event) => {
    event.stopPropagation();
    viewClicked.current = selectedView;
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setAnchorPosition({
      top: buttonRect.bottom + window.scrollY, // Position menu below the button
      left: buttonRect.left + window.scrollX - 80, // Subtract the navbar width
    });
    setMenuVisible(true);
  };

  return (
    <>
      <ConfirmModal
        isOpen={deleteModalOpen}
        title={`Delete "${viewClicked.current?.name ?? ""}" view ?`}
        description={
          "Are you sure you want to delete this view? This action cannot be undone."
        }
        actions={
          <FlexContainer gap={theme.spacing(3)} sx={{flex: 1}}>
            <ButtonText
              buttonBase={OutlinedButtonBase}
              weight="semibold"
              color="gray700"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={"Cancel"}
              onClick={() => setDeleteModalOpen(false)}
            />
            <ButtonText
              buttonBase={ErrorButtonBase}
              weight="semibold"
              color="white"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={"Delete"}
              onClick={handleDelete}
            />
          </FlexContainer>
        }
        icon={<RoundIcon iconComponent="alert-triangle" baseColor="warning" />}
      />
      <FlexContainer
        className={classes.container}
        flexDirection="column"
        gap={theme.spacing(6)}
      >
        {menuVisible && (
          <ContextMenu
            menuItems={[
              {
                label: "Edit View",
                icon: ICONS["settings-tune"],
                iconProps: {color: theme.colors.gray500},
                onClick: () => editView(viewClicked.current),
              },
              {
                label: "Duplicate",
                icon: ICONS["copy"],
                iconProps: {color: theme.colors.gray500},
                onClick: handleDuplicate,
              },
              {
                Component: ({label}) => (
                  <ContextMenuItem
                    label={
                      <Text weight="medium" color="error500">
                        {label}
                      </Text>
                    }
                    icon={ICONS["trash"]}
                    iconProps={{color: theme.colors.error500}}
                    onClick={() => setDeleteModalOpen(true)}
                  />
                ),
                label: "Delete",
              },
            ]}
            onClose={() => setMenuVisible(false)}
            anchorPosition={anchorPosition}
          />
        )}
        <FlexContainer
          className={classes.header}
          gap={theme.spacing(3)}
          alignItems="center"
        >
          {expandArrow}
          <Text variant="displayXS" weight="semibold">
            {t("filter-sets-panel-header")}
          </Text>
        </FlexContainer>

        <FilterSetList
          views={views}
          selected={selected}
          openMenu={openMenu}
          onFilterClick={handleFilterSetClick}
        />
      </FlexContainer>
    </>
  );
};

export default FilterSetsPanel;
