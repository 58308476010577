import React, {useEffect} from "react";
import {createPortal} from "react-dom";
import {useTheme} from "../../theme/ThemeProvider";
import {buildStyles} from "../buildStyles";
import Container from "../layout/Container";
import PropTypes from "prop-types";

const useStyles = (theme) =>
  buildStyles({
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(16, 24, 40, 0.70)",
      backdropFilter: "blur(8px)",
      zIndex: theme.zIndex.modal || 1000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      backgroundColor: theme.colors.white,
      borderRadius: `${theme.radius.lg}px`,
      boxShadow: theme.shadows.default,
      padding: theme.spacing(4),
      maxWidth: "90%",
      maxHeight: "90%",
      overflowY: "auto",
    },
  });

/**
 * ModalBase is a component that renders a modal with a background and overlay content.
 * It supports opening and closing via the `isOpen` prop and handles closing on Escape key press.
 *
 * @param {Object} props - The props for the ModalBase component.
 * @param {boolean} props.isOpen - Whether the modal is open or not.
 * @param {Function} props.onClose - The function to call when the modal is closed.
 * @param {React.ReactNode} props.children - The content to render inside the modal.
 * @param {Object} props.rest - Any other props to be passed to the modal content container.
 *
 * @returns {JSX.Element} The rendered ModalBase component, or `null` if the modal is not open.
 */
const ModalBase = ({isOpen, onClose, children, ...props}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  // Close modal on Escape key press
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isOpen) {
        onClose && onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <Container className={classes.modalOverlay} onClick={onClose}>
      <Container
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        {children}
      </Container>
    </Container>,
    document.body,
  );
};

ModalBase.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default ModalBase;
