import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {generateStyles} from "connectui/theme/styleUtilities";
import ButtonBase from "./ButtonBase";
import PropTypes from "prop-types";

/**
 * ErrorButtonBase is a variant of the ButtonBase component that renders a button with an error color style.
 * It allows customization through the `sx` prop.
 *
 * @param {Object} props - The props for the ErrorButtonBase component.
 * @param {React.ReactNode} props.children - The content of the button, which can be any valid React node.
 * @param {Function} [props.onClick] - The click handler for the button.
 * @param {Object} [props.sx={}] - The style object that can be used to override default outlined button styles.
 * @param {Object} props.rest - Any other props that should be passed to the ButtonBase component.
 *
 * @returns {JSX.Element} The rendered ErrorButtonBase component.
 */
const ErrorButtonBase = ({children, onClick, sx = {}, ...props}) => {
  const {theme} = useTheme();
  const defaultStyles = {
    backgroundColor: theme.colors.error600,
    "&:hover": {backgroundColor: theme.colors.error700},
    "&:disabled": {
      backgroundColor: theme.colors.error200,
      cursor: "not-allowed",
      opacity: 0.6,
    },
  };
  const styles = generateStyles(
    {sx: {...defaultStyles, ...sx}, ...props},
    theme,
  );

  return (
    <ButtonBase onClick={onClick} sx={styles} {...props}>
      {children}
    </ButtonBase>
  );
};

ErrorButtonBase.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.any,
};

export default ErrorButtonBase;
