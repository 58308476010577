import React from "react";
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "connectui/component/buildStyles";
import FlexContainer from "connectui/component/layout/FlexContainer";
import FilterListChip from "./FilterListChip";

const useStyles = (theme) =>
  buildStyles({
    selectedFiltersContainer: {
      width: "100%",
      height: "fit-content",
      maxHeight: "200px",
      flexFlow: "wrap-reverse",
      marginTop: theme.spacing(-4),
      padding: theme.spacing(5),
      overflow: "auto",
      flexGrow: 1,
      flexShrink: 0,
    },
  });

const SelectedFiltersOverview = ({filters = [], setFilters}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);

  if (!filters.length) {
    return null;
  }
  return (
    <FlexContainer
      className={classes.selectedFiltersContainer}
      gap={theme.spacing(1)}
    >
      {filters.map((s_f) => (
        <FilterListChip
          key={`${s_f.path}-${s_f.value}`}
          selected
          filter={s_f.value}
          XColor={theme.colors.blue500}
          onXClick={() =>
            setFilters(
              filters.filter(
                (f) => !(f.path === s_f.path && f.value === s_f.value),
              ),
            )
          }
        />
      ))}
    </FlexContainer>
  );
};

export default SelectedFiltersOverview;
