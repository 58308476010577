import qs from "qs";
import * as types from "../actionTypes";
import * as API from "../api/settingsAPI";

// -------- CONFIG ------------------------
export function resetStore() {
  return {type: types.RESET_STORE};
}
export function setDeviceType(deviceType) {
  return {type: types.SET_DEVICE_TYPE, deviceType};
}
export function setNavbarVisibility(show) {
  return {type: types.SET_NAVBAR_VISIBILITY, show};
}
export function setErrorPathBlocked(blocked) {
  return {type: types.SET_ERROR_PATH_BLOCKED, blocked};
}

// -------- START KEYS & TOTALS ------------------------
export function setStartKeys(key, val) {
  return {type: types.SET_START_KEYS, key: key, val: val};
}
export function setTotals(totalType, total) {
  return {type: types.SET_TOTALS, totalType, total};
}

// ------- ALERTS ----------------------------
export function openAlert(alert_type, message, id) {
  return {
    type: types.OPEN_ALERT,
    alert_type: alert_type,
    message: message,
    id: id,
  };
}
export function closeAlert() {
  return {type: types.CLOSE_ALERT};
}
export function openGeneralError(errorProps) {
  return {type: types.OPEN_GENERAL_ERROR, errorProps};
}
export function closeGeneralError() {
  return {type: types.CLOSE_GENERAL_ERROR};
}
export function openGeneralSuccessAlert(successProps) {
  return {type: types.OPEN_GENERAL_ALERT_SUCCESS, successProps};
}
export function closeGeneralSuccessAlert() {
  return {type: types.CLOSE_GENERAL_ALERT_SUCCESS};
}

// ------- GENERAL API CALL STATE -------------------
export function setApiStart(objType) {
  return {type: types.SET_API_START, objType};
}
export function setApiSuccess(objType) {
  return {type: types.SET_API_SUCCESS, objType};
}
export function setApiError(objType, message) {
  return {type: types.SET_API_ERROR, objType, message};
}
export function handleAPIErrorMessage(error, customOptions) {
  return (dispatch) => {
    let errorParams = {
      open: true,
      message: error.title ?? error.message,
      disableSeverity: true,
      subtitle: !!error.title ? error.message : null,
      onClose: () => dispatch(closeGeneralError()),
    };
    if (!!customOptions) {
      errorParams = {...errorParams, ...customOptions};
    }
    dispatch(openGeneralError(errorParams));
  };
}

// ---------- LANGUAGE ----------
export function setLanguage(language) {
  return {type: types.SET_LANGUAGE, language};
}

// ----------- INCOMING GENERIC OBJECT ---------------
export function addAttachedObject(object, mapperObject) {
  return {type: types.ADD_ATTACHED_OBJECT, object, mapperObject};
}

export function updateAttachedObject(object, mapperObject, nestedField) {
  return {
    type: types.UPDATE_ATTACHED_OBJECT,
    object,
    mapperObject,
    nestedField,
  };
}

// General
export function setLoaded(data, data_type) {
  return {type: types.SET_LOADED, data: data, data_type: data_type};
}
export function addLoadedEvents(data, listing_id) {
  return {type: types.ADD_LOADED_EVENTS, data: data};
}

export function validateText({
  guest_id = null,
  replace_text = false,
  text,
  format = "text",
  onSuccess = () => null,
  onError = () => null,
}) {
  return async (dispatch) => {
    if (!text) {
      return;
    }
    try {
      const response = await API.postValidate({
        body: {guest_id, replace_text, text, format},
      });
      onSuccess(response);
    } catch (err) {
      onError();
    }
  };
}

export function getCustomFields({
  objects = [],
  onSuccess = () => null,
  onError = () => null,
}) {
  const defaultObjects = new Set([
    "listing",
    "guest",
    "booking",
    "enso_user",
    ...objects,
  ]);
  return async (dispatch) => {
    try {
      const response = await API.getVariables(
        qs.stringify(
          {objects: Array.from(defaultObjects)},
          {arrayFormat: "comma"},
        ),
      );
      onSuccess(response);
    } catch (err) {
      onError();
    }
  };
}

function handleCloseError(dispatch) {
  dispatch(closeGeneralError());
}

export function errorResponseAlert(
  dispatch,
  message = "Error",
  subtitle = null,
  error = null,
) {
  let ensoError = error?.response?.data?.error?.[0];
  dispatch(
    openGeneralError({
      message: ensoError?.title || message,
      subtitle: ensoError?.message || subtitle,
      open: true,
      disableSeverity: true,
      onClose: () => handleCloseError(dispatch),
    }),
  );
}
