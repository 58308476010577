import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {editAccount, setUserProfile} from "redux/actions/accountsActions";
import {InboxContext} from "ui/page/Inbox";
// Styles & icons
import {useTheme} from "connectui/theme/ThemeProvider";
import {buildStyles} from "../../../../connectui/component/buildStyles";
import {ReactComponent as ArrowLeftIcon} from "connectui/icons/arrow-left.svg";
// Components
import FlexContainer from "connectui/component/layout/FlexContainer";
import Container from "connectui/component/layout/Container";
import Divider from "connectui/component/display/Divider";
import LightButtonBase from "connectui/component/input/button/base/LightButtonBase";
import ButtonText from "connectui/component/input/button/ButtonText";
import SvgIcon from "connectui/component/display/SvgIcon";
import Text from "connectui/component/display/Text";
import ViewDetailsPanel from "./ViewDetailsPanel";
import Tabs from "connectui/component/input/Tabs";
import FilterListPanel from "./FilterListPanel";
import SelectedFiltersOverview from "../filterlist/SelectedFiltersOverview";
import ConfirmModal from "connectui/component/modal/ConfirmModal";
import OutlinedButtonBase from "connectui/component/input/button/base/OutlinedButtonBase";
import ButtonBase from "connectui/component/input/button/base/ButtonBase";
import ErrorButtonBase from "connectui/component/input/button/base/ErrorButtonBase";
import RoundIcon from "connectui/component/display/RoundIcon";

const useStyles = (theme) =>
  buildStyles({
    container: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    header: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
    },
    actionsRow: {
      width: "100%",
      marginTop: theme.spacing(-4),
      padding: theme.spacing(0, 4, 5),
    },
    icon: {
      width: "15px",
      height: "15px",
      cursor: "pointer",
    },
    tabsContainer: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      padding: theme.spacing(0, 4),
    },
  });

const EditViewPanel = ({onClose = () => null}) => {
  const {theme} = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {appliedFilters} = useContext(InboxContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [content, setContent] = useState({
    name: "",
    icon: "",
    color: "",
    description: "",
    queries: appliedFilters,
  });
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    type: "",
  });
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const searchParams = new URLSearchParams(window.location.search);
  const viewId = searchParams.get("view_id");

  useEffect(() => {
    if (viewId) {
      setContent({...user_profile.views.find((v) => v.id === viewId)});
    }
  }, []);

  const onSave = () => {
    const user = user_profile.enso_users.find(
      (u) => u.enso_user_id === user_profile.enso_user_id,
    );
    let currentViews = user_profile.views ?? [];
    let newViews = currentViews.map((v) =>
      v.id === viewId ? {...v, ...content} : v,
    );

    // Update root object in redux store
    dispatch(setUserProfile({...user_profile, views: newViews}));

    const body = {
      enso_user_id: user_profile.enso_user_id,
      data: {...user, edited_view: content},
    };
    dispatch(
      editAccount({
        body: {body},
        disableUpdate: true,
        successMsg: "View updated correctly",
        errorMsg: "Failed to save changes",
        onSuccess: (response) => {
          window.history.pushState(
            {},
            "",
            `${window.location.pathname}?view_id=${viewId}`,
          );
        },
        onError: () => {
          dispatch(setUserProfile({...user_profile, views: currentViews}));
        },
      }),
    );
    onClose(true);
  };

  return (
    <>
      <ConfirmModal
        isOpen={confirmModal.open}
        title={confirmModal.type === "save" ? "Save View?" : "Cancel Saving?"}
        description={
          confirmModal.type === "save"
            ? "Are you sure you want to save your changes? This view will be updated with your changes."
            : "Are you sure you want to cancel? Your changes will not be saved."
        }
        actions={
          <FlexContainer gap={theme.spacing(3)} sx={{flex: 1}}>
            <ButtonText
              buttonBase={OutlinedButtonBase}
              weight="semibold"
              color="gray700"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={confirmModal.type === "save" ? "Cancel" : "Go Back"}
              onClick={() => setConfirmModal({open: false, type: ""})}
            />
            <ButtonText
              buttonBase={
                confirmModal.type === "save" ? ButtonBase : ErrorButtonBase
              }
              weight="semibold"
              color="white"
              sx={{flex: 1, padding: theme.spacing(2.5, 4.5)}}
              label={confirmModal.type === "save" ? "Save" : "Discard Changes"}
              onClick={confirmModal.type === "save" ? onSave : onClose}
            />
          </FlexContainer>
        }
        icon={
          <RoundIcon
            iconComponent={
              confirmModal.type === "save" ? "check-circle" : "alert-triangle"
            }
            baseColor={confirmModal.type === "save" ? "success" : "warning"}
          />
        }
      />
      <FlexContainer
        className={classes.container}
        flexDirection="column"
        gap={theme.spacing(4)}
      >
        <FlexContainer
          className={classes.header}
          gap={theme.spacing(3)}
          alignItems="center"
        >
          <SvgIcon
            component={ArrowLeftIcon}
            className={classes.icon}
            onClick={() => onClose(true)}
          />
          <Text variant="displayXS" weight="semibold" sx={{flex: 1}}>
            {t("filter-set-settings-panel-edit-header")}
          </Text>
        </FlexContainer>

        <FlexContainer className={classes.tabsContainer}>
          <Tabs labels={["Filters", "View Details"]}>
            <FilterListPanel
              filters={content.queries}
              setFilters={(newFilters) => {
                if (!hasChanges) {
                  setHasChanges(true);
                }
                setContent((prev) => ({...prev, queries: newFilters}));
              }}
            />
            <ViewDetailsPanel
              content={content}
              setContent={(field, value) => {
                if (!hasChanges) {
                  setHasChanges(true);
                }
                setContent((prev) => ({...prev, [field]: value}));
              }}
            />
          </Tabs>
        </FlexContainer>

        <SelectedFiltersOverview
          filters={content.queries}
          setFilters={(newFilters) =>
            setContent((prev) => ({...prev, queries: newFilters}))
          }
        />

        <Container className={classes.actionsRow}>
          <Divider sx={{marginTop: "0px"}} />
          <FlexContainer gap={theme.spacing(3)}>
            <ButtonText
              sx={{flex: 1}}
              typographyVariant="textSM"
              weight="semibold"
              color="white"
              disabled={
                !content.name || !content.color || !content.icon || !hasChanges
              }
              label="Save"
              onClick={() => setConfirmModal({open: true, type: "save"})}
            />
            <ButtonText
              sx={{flex: 1}}
              typographyVariant="textSM"
              weight="semibold"
              color="primary"
              buttonBase={LightButtonBase}
              label="Close"
              onClick={() => setConfirmModal({open: true, type: "cancel"})}
            />
          </FlexContainer>
        </Container>
      </FlexContainer>
    </>
  );
};

export default EditViewPanel;
